import { useState } from "react";

import { useForm } from "react-hook-form";
import { Trans, useTranslation } from "react-i18next";
import styled from "styled-components";

import { parseApiError } from "@vapaus/utils";

import { Dialog } from "../../ui";
import { CheckboxInput, PasswordInput, TextInput, colors } from "../../uikit";
import { useCurrentUserTerms } from "../../user-utils";
import { useAuthContext } from "../context/authContext";
import { useSignUp } from "../hooks/auth";
import {
  AuthLayout,
  AuthLayoutButton,
  AuthLayoutFooterLink,
} from "./AuthLayout";

export const SignUpPage = () => {
  const {
    register,
    handleSubmit,
    formState: { isValid },
  } = useForm();

  const [error, setError] = useState<string | null>(null);
  const { setToken } = useAuthContext();
  const { t, i18n } = useTranslation();
  const signUp = useSignUp();
  const { data: userTerms } = useCurrentUserTerms();

  // Handle form submit
  const handleRegister = async (data: any): Promise<void> => {
    if (signUp.isLoading) return;
    try {
      const res = await signUp.mutateAsync({
        ...data,
        accepted_user_terms_id: data?.terms ? userTerms?.id : null,
        language: i18n.resolvedLanguage?.toUpperCase(),
      });
      setToken(res.access_token);
    } catch (error: any) {
      setError(parseApiError(error, t));
    }
  };

  return (
    <AuthLayout
      title={t("auth:signUp.title")}
      description={t("auth:signUp.text")}
    >
      {error && (
        <Dialog
          type="alert"
          title={t("common:dialog.error")}
          subTitle={t("auth:signUp.error")}
          description={error}
          animation="error"
          onClose={() => setError(null)}
        />
      )}
      <form onSubmit={handleSubmit(handleRegister)}>
        <TextInput
          {...register("email", { required: true })}
          autoFocus
          type="text"
          label={t("auth:signUp.email")}
          placeholder={t("auth:signUp.emailPlaceholder")}
        />
        <PasswordInput
          {...register("password", { required: true })}
          required
          label={t("auth:signUp.password")}
          placeholder={t("auth:signUp.password")}
        />
        <TermsContainer>
          <CheckboxInput
            {...register("terms", { required: true })}
            type="checkbox"
            text={
              <TermsAndConditionContainer>
                <Trans t={t} i18nKey={"auth:signUp.termsAndConditions"}>
                  I have read and agree to Vapaus.io’s
                  <TermsLink href="/terms-of-service" target="_blank">
                    Terms of Service
                  </TermsLink>
                  and
                  <TermsLink
                    href={t("auth:signUp.privacyPolicyUrl")}
                    target="_blank"
                  >
                    Privacy Policy
                  </TermsLink>
                  .
                </Trans>
              </TermsAndConditionContainer>
            }
          />
        </TermsContainer>

        <AuthLayoutButton
          isLoading={signUp.isLoading}
          disabled={!isValid}
          type="submit"
        >
          {t("auth:signUp.submit")}
        </AuthLayoutButton>
      </form>
      <AuthLayoutFooterLink to="/sign-in">
        {t("auth:link.signIn")}
      </AuthLayoutFooterLink>
      <AuthLayoutFooterLink to="/forgot-password">
        {t("auth:link.forgotPassword")}
      </AuthLayoutFooterLink>
    </AuthLayout>
  );
};

const TermsAndConditionContainer = styled.div`
  text-align: left;
  color: ${colors.textColor};
  font-size: 16px;
  line-height: 1.5;
`;

const TermsLink = styled.a`
  display: unset !important;
  margin: 0;
  font-size: 16px !important;
  font-weight: 700;
`;

const TermsContainer = styled.div`
  margin-top: 2em;
  margin-bottom: 2em;
`;
