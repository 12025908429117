import { FC, ReactElement, RefObject, useEffect, useState } from "react";

import { DateTime } from "luxon";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";

import {
  PaymentConsent,
  PaymentConsentStatus,
  PaymentServiceProvider,
} from "@vapaus/generated";
import { parseApiError } from "@vapaus/utils";

import { DetailType } from "../../../../layouts";
import { AnimatedIcon } from "../../../../ui";
import { useToast } from "../../../../uikit";
import {
  useCancelPaymentConsent,
  useCreatePaymentConsent,
  usePaymentConsentList,
  useVismaPayCallback,
} from "../../../../user-utils";
import VerificationCard from "../../VerificationCard";

type PaymentConsentListDetailProps = {
  sectionRef: RefObject<Element>;
};

export const PaymentConsentListDetail: FC<PaymentConsentListDetailProps> = ({
  sectionRef,
}) => {
  const { t } = useTranslation();
  const { data: paymentConsents, refetch } = usePaymentConsentList({
    status: [
      PaymentConsentStatus.CONFIRMED,
      PaymentConsentStatus.PENDING,
      PaymentConsentStatus.FAILED,
    ],
  });
  const cancelPaymentConsent = useCancelPaymentConsent();
  const [searchParams, setSearchParams] = useSearchParams();
  const createPaymentConsent = useCreatePaymentConsent();
  const toast = useToast();

  // vismaPayCallback mutation stays loading for some reason, so we're using manual state tracking here
  const [isVismaPayCallbackLoading, setIsVismaPayCallbackLoading] =
    useState(false);
  const vismaPayCallback = useVismaPayCallback(
    searchParams.get("ORDER_NUMBER") || "",
    {
      onMutate: () => setIsVismaPayCallbackLoading(true),
      onSuccess: (data, variables) => {
        const replaceConsentKey = `replacePaymentConsent-${variables.orderNumber}`;
        const replaceConsentId = localStorage.getItem(replaceConsentKey);
        if (replaceConsentId) {
          localStorage.removeItem(replaceConsentKey);
          handleCancelPaymentConsent(replaceConsentId);
        }
      },
      onSettled: () => {
        refetch();
        setIsVismaPayCallbackLoading(false);
      },
    },
  );
  useEffect(() => {
    if (searchParams.get("ORDER_NUMBER")) {
      sectionRef?.current?.scrollIntoView();
      vismaPayCallback.mutate({
        returnCode: searchParams.get("RETURN_CODE") || "",
        orderNumber: searchParams.get("ORDER_NUMBER") || "",
        settled: searchParams.get("SETTLED") || "",
        incidentId: searchParams.get("INCIDENT_ID") || "",
        authcode: searchParams.get("AUTHCODE") || "",
      });
      searchParams.delete("ORDER_NUMBER");
      setSearchParams(searchParams);
    }
  }, [searchParams]);

  const handleCancelPaymentConsent = (consentId?: string) => {
    if (!consentId) return;
    return cancelPaymentConsent.mutate(consentId, {
      onSuccess: async () => await refetch(),
      onError: (error: any) => toast.error(parseApiError(error, t)),
    });
  };

  const handleReplacePaymentConsent = (consent: PaymentConsent) => {
    if (!consent.id) return;
    createPaymentConsent.mutate(
      {
        payment_service_provider: PaymentServiceProvider.VISMA_PAY,
        currency: consent.currency,
        return_url: window.location.origin + window.location.pathname,
      },
      {
        onSuccess: ({ id, link }) => {
          if (id && consent.id)
            localStorage.setItem(`replacePaymentConsent-${id}`, consent.id);
          if (link) {
            window.location.replace(link);
          }
        },
        onError: (error: any) => toast.error(parseApiError(error, t)),
      },
    );
  };

  const getExpirationDate = (consent: PaymentConsent): DateTime | null => {
    if (
      consent.source_card_expiration_year &&
      consent.source_card_expiration_month
    ) {
      return DateTime.local(
        consent.source_card_expiration_year,
        consent.source_card_expiration_month,
      ).plus({ months: 1 }); // card expires after the expiration month
    }
    return null;
  };

  const isVerified = (consent: PaymentConsent): boolean | undefined => {
    switch (consent.status) {
      case PaymentConsentStatus.CONFIRMED:
        const expirationDate = getExpirationDate(consent);
        if (expirationDate) {
          return DateTime.now().plus({ months: 3 }) < expirationDate;
        }
        return true;
      case PaymentConsentStatus.FAILED:
        return false;
      default:
        return undefined;
    }
  };

  const getDescription = (consent: PaymentConsent): string | undefined => {
    if (
      consent.status == PaymentConsentStatus.PENDING ||
      !consent.source_brand ||
      !consent.source_label
    )
      return t("settings:buttons.paymentConsent.pendingConsentDescription");
    if (consent.status == PaymentConsentStatus.FAILED)
      return (
        consent.failure_reason ||
        t("settings:buttons.paymentConsent.failedConsentDescription")
      );
    const expirationDate = getExpirationDate(consent);
    if (expirationDate) {
      if (DateTime.now() >= expirationDate)
        return `${t("settings:buttons.paymentConsent.expired")} ${
          consent.source_card_expiration_month
        }/${consent.source_card_expiration_year}`;
      return `${t("settings:buttons.paymentConsent.expires")} ${
        consent.source_card_expiration_month
      }/${consent.source_card_expiration_year}`;
    }
    return undefined;
  };

  const getBody = (
    consent: PaymentConsent,
  ): string | ReactElement | undefined => {
    if (
      consent.status == PaymentConsentStatus.PENDING ||
      !consent.source_brand ||
      !consent.source_label
    )
      return <AnimatedIcon type="spinner5" />;
  };

  return (
    <>
      {(paymentConsents?.items || []).map((consent) => (
        <VerificationCard
          type={DetailType.Payment}
          title={
            consent.status == PaymentConsentStatus.PENDING ||
            !consent.source_brand ||
            !consent.source_label
              ? undefined
              : `${consent.source_brand} ${consent.source_label}`
          }
          description={getDescription(consent)}
          body={getBody(consent)}
          key={consent.id}
          isVerified={isVerified(consent)}
          onVerify={() => handleReplacePaymentConsent(consent)}
          verifyButtonLabel={t("settings:buttons.paymentConsent.replace")}
          verifyButtonDisabled={
            createPaymentConsent.isLoading ||
            cancelPaymentConsent.isLoading ||
            isVismaPayCallbackLoading
          }
          canDelete={[
            PaymentConsentStatus.CONFIRMED,
            PaymentConsentStatus.PENDING,
            PaymentConsentStatus.FAILED,
          ].includes(consent.status)}
          onDelete={() => handleCancelPaymentConsent(consent.id)}
        />
      ))}
    </>
  );
};
