/* generated using openapi-typescript-codegen -- do no edit */

/* istanbul ignore file */

/* tslint:disable */

/* eslint-disable */
import type { CancelablePromise } from "../core/CancelablePromise";
import { OpenAPI } from "../core/OpenAPI";
import { request as __request } from "../core/request";
import type { BenefitCalculation } from "../models/BenefitCalculation";
import type { Body_calculations_get_fixed_monthly_payment_calculation } from "../models/Body_calculations_get_fixed_monthly_payment_calculation";
import type { Body_calculations_get_fixed_monthly_payment_with_tax_calculation } from "../models/Body_calculations_get_fixed_monthly_payment_with_tax_calculation";
import type { Body_calculations_get_fixed_period_calculation } from "../models/Body_calculations_get_fixed_period_calculation";
import type { Body_calculations_get_fixed_period_sweden_pilot_calculation } from "../models/Body_calculations_get_fixed_period_sweden_pilot_calculation";
import type { Body_calculations_get_public_fixed_monthly_payment_with_tax_calculation } from "../models/Body_calculations_get_public_fixed_monthly_payment_with_tax_calculation";
import type { Body_calculations_get_shortest_period_calculation } from "../models/Body_calculations_get_shortest_period_calculation";
import type { BookingCalculation } from "../models/BookingCalculation";
import type { BookingUseType } from "../models/BookingUseType";
import type { SwedenSuggestedBenefitValuesCalculation } from "../models/SwedenSuggestedBenefitValuesCalculation";
import type { WithTaxDetailsBenefitCalculation } from "../models/WithTaxDetailsBenefitCalculation";

export class CalculationsService {
  /**
   * Get Fixed Period Calculation
   * @param requestBody
   * @returns BenefitCalculation Successful Response
   * @throws ApiError
   */
  public static getFixedPeriodCalculation(
    requestBody: Body_calculations_get_fixed_period_calculation,
  ): CancelablePromise<BenefitCalculation> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/api/calculations/bike-benefit-fixed-period",
      body: requestBody,
      mediaType: "application/json",
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Get Shortest Period Calculation
   * @param requestBody
   * @returns BenefitCalculation Successful Response
   * @throws ApiError
   */
  public static getShortestPeriodCalculation(
    requestBody: Body_calculations_get_shortest_period_calculation,
  ): CancelablePromise<BenefitCalculation> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/api/calculations/bike-benefit-shortest-period",
      body: requestBody,
      mediaType: "application/json",
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Get Fixed Monthly Payment Calculation
   * @param requestBody
   * @returns BenefitCalculation Successful Response
   * @throws ApiError
   */
  public static getFixedMonthlyPaymentCalculation(
    requestBody: Body_calculations_get_fixed_monthly_payment_calculation,
  ): CancelablePromise<BenefitCalculation> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/api/calculations/bike-benefit-fixed-monthly-payment",
      body: requestBody,
      mediaType: "application/json",
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Get Fixed Monthly Payment With Tax Calculation
   * @param requestBody
   * @returns WithTaxDetailsBenefitCalculation Successful Response
   * @throws ApiError
   */
  public static getFixedMonthlyPaymentWithTaxCalculation(
    requestBody: Body_calculations_get_fixed_monthly_payment_with_tax_calculation,
  ): CancelablePromise<WithTaxDetailsBenefitCalculation> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/api/calculations/bike-benefit-fixed-monthly-payment-with-tax",
      body: requestBody,
      mediaType: "application/json",
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Get Booking Calculation
   * @param fleetId
   * @param useType
   * @param startAt
   * @param endAt
   * @returns BookingCalculation Successful Response
   * @throws ApiError
   */
  public static getBookingCalculation(
    fleetId: string,
    useType: BookingUseType,
    startAt: string,
    endAt: string,
  ): CancelablePromise<BookingCalculation> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/api/calculations/booking",
      query: {
        fleet_id: fleetId,
        use_type: useType,
        start_at: startAt,
        end_at: endAt,
      },
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Get Fixed Period Sweden Pilot Calculation
   * @param requestBody
   * @returns BenefitCalculation Successful Response
   * @throws ApiError
   */
  public static getFixedPeriodSwedenPilotCalculation(
    requestBody: Body_calculations_get_fixed_period_sweden_pilot_calculation,
  ): CancelablePromise<BenefitCalculation> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/api/calculations/bike-benefit-fixed-period-sweden-pilot",
      body: requestBody,
      mediaType: "application/json",
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Get Sweden Suggested Benefit Values Calculation For Contract
   * @param bikeBenefitContractId
   * @returns SwedenSuggestedBenefitValuesCalculation Successful Response
   * @throws ApiError
   */
  public static getSwedenSuggestedBenefitValuesCalculationForContract(
    bikeBenefitContractId: string,
  ): CancelablePromise<SwedenSuggestedBenefitValuesCalculation> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/api/calculations/sweden-suggested-benefit-values-for-contract/{bike_benefit_contract_id}",
      path: {
        bike_benefit_contract_id: bikeBenefitContractId,
      },
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Get Sweden Suggested Benefit Values Calculation For Order
   * @param bikeBenefitOrderId
   * @returns SwedenSuggestedBenefitValuesCalculation Successful Response
   * @throws ApiError
   */
  public static getSwedenSuggestedBenefitValuesCalculationForOrder(
    bikeBenefitOrderId: string,
  ): CancelablePromise<SwedenSuggestedBenefitValuesCalculation> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/api/calculations/sweden-suggested-benefit-values-for-order/{bike_benefit_order_id}",
      path: {
        bike_benefit_order_id: bikeBenefitOrderId,
      },
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Get Public Fixed Monthly Payment With Tax Calculation
   * @param requestBody
   * @returns WithTaxDetailsBenefitCalculation Successful Response
   * @throws ApiError
   */
  public static getPublicFixedMonthlyPaymentWithTaxCalculation(
    requestBody: Body_calculations_get_public_fixed_monthly_payment_with_tax_calculation,
  ): CancelablePromise<WithTaxDetailsBenefitCalculation> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/api/calculations/bike-benefit-public-fixed-monthly-payment-with-tax",
      body: requestBody,
      mediaType: "application/json",
      errors: {
        400: `Bad Request`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }
}
