export interface PageLayoutProps {
  children: React.ReactNode;
  pageTitle: string;
}

export interface NavMenuItemProps {
  title: string;
  pathname: string;
  icon: string;
}

export enum MenuType {
  fleet = "fleet",
  benefit = "benefit",
}
