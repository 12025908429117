import React, { useCallback, useEffect, useState } from "react";

import { useQueryClient } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import styled from "styled-components";

import { ApiError } from "@vapaus/generated";
import { parseApiError } from "@vapaus/utils";

import { Dialog, DialogProps, Loading } from "../../ui";
import { useVerifyUserEmail } from "../../user-utils";

const VerifyEmailPage = () => {
  const params = useSearchParams();
  const token = params?.length > 0 ? params[0].get("token") : null;
  const resumePath = params?.length > 0 ? params[0].get("resume_path") : null;

  const navigate = useNavigate();
  const [dialog, setDialog] = useState<DialogProps | undefined>();
  const { t } = useTranslation();

  const verifyEmail = useVerifyUserEmail();
  const queryClient = useQueryClient();

  const verifyToken = useCallback(
    (token: string) => {
      verifyEmail.mutate(
        { token },
        {
          onSuccess: () => {
            queryClient.invalidateQueries(["currentUserEmails"]);
            setDialog({
              type: "alert",
              title: t("common:verifiedEmail.title"),
              subTitle: "",
              animation: "success",
              description: t("common:verifiedEmail.message"),
              onClose: () => {
                setDialog(undefined);
                navigate(resumePath ?? "/");
              },
            });
          },
          onError: (error) =>
            setDialog({
              type: "alert",
              title: t("common:verifiedEmail.errorVerifying"),
              subTitle: "",
              description: parseApiError(error as ApiError, t),
              animation: "error",
              onClose: () => {
                setDialog(undefined);
                navigate("/");
              },
            }),
        },
      );
    },
    [verifyEmail, queryClient, t, navigate, resumePath],
  );

  useEffect(() => {
    try {
      verifyToken(token as string);
    } catch (error) {
      setDialog({
        type: "alert",
        title: t("common:verifiedEmail.errorVerifying"),
        subTitle: t("common:verifiedEmail.tokenNotFound"),
        animation: "error",
        onClose: () => setDialog(undefined),
      });
    }
  }, [token]);

  return (
    <Wrapper>
      <Loading />
      <h2>{t("common:verifiedEmail.verifying")}</h2>
      {dialog && <Dialog close={t("common:dialog.close")} {...dialog} />}
    </Wrapper>
  );
};

export default VerifyEmailPage;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  position: absolute;
  top: 0;
  left: 0;
`;
