import { FC, useEffect, useMemo, useState } from "react";

import { isEqual } from "lodash-es";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { LanguageSelect } from "../../i18n";
import { Button, Card, Dialog, DialogProps, Form, InputProps } from "../../ui";
import { useCurrentUser, useUpdateCurrentUser } from "../../user-utils";

type Props = {
  personalInfoForm: InputProps[];
  cardTitle?: string;
};

const UpdatePersonalInfo: FC<Props> = ({ personalInfoForm, cardTitle }) => {
  const { t } = useTranslation();
  const updateProfile = useUpdateCurrentUser();
  const { refetch } = useCurrentUser();

  const defaultValues = useMemo(
    () =>
      personalInfoForm.reduce<{ [key: string]: string | undefined }>(
        (accumulator, obj) => {
          if (obj.name) {
            accumulator[obj.name] = obj.value;
          }
          return accumulator;
        },
        {},
      ),
    [personalInfoForm],
  );

  const {
    handleSubmit,
    control,
    formState: { isValid, isDirty },
    reset,
    watch,
  } = useForm();
  const watchedValues = watch();

  useEffect(() => {
    if (isEqual({}, watchedValues)) {
      reset(defaultValues);
    }
  }, [defaultValues]);

  const [dialog, setDialog] = useState<DialogProps | undefined>();

  const handleSaveProfile = (data: any) => {
    updateProfile.mutate(data as any, {
      onSuccess: () => {
        refetch?.();
        setDialog({
          type: "alert",
          title: t("common:dialog.allDone"),
          subTitle: t("settings:updateProfile.title"),
          description: t("settings:updateProfile.success"),
          animation: "success",
          onClose: () => setDialog(undefined),
        });
      },
      onError: (error: any) => {
        setDialog({
          type: "alert",
          title: t("common:dialog.error"),
          subTitle: t("settings:updateProfile.title"),
          description: error,
          animation: "error",
          onClose: () => setDialog(undefined),
        });
      },
    });
  };

  return (
    <Card
      title={cardTitle || t("settings:updateProfile.title")}
      margin="normal"
    >
      {dialog && <Dialog {...dialog} />}
      <form onSubmit={handleSubmit(handleSaveProfile)}>
        <LanguageSelectWrapper>
          <LanguageSelect />
        </LanguageSelectWrapper>
        {personalInfoForm.map((input, i) => (
          <Controller
            key={`personal-info-form-${i}`}
            name={input.name as string}
            control={control}
            rules={{ required: input.required }}
            render={({ field }) => (
              <Form.Input
                {...input}
                onChange={(value: any) => {
                  field.onChange(value);
                }}
              />
            )}
          />
        ))}
        <Form.Submit>
          <Button
            secondary
            text={t("settings:updateProfile.submit")}
            disabled={!(isValid && isDirty)}
          />
        </Form.Submit>
      </form>
    </Card>
  );
};

export default UpdatePersonalInfo;

const LanguageSelectWrapper = styled.div`
  margin-bottom: 1rem;
  .LanguageSelect {
    margin-top: 0;
  }
`;
