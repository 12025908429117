import { useTranslation } from "react-i18next";
import styled, { AnyStyledComponent } from "styled-components";

import { Loading, logo } from "../../ui";
import { BaseButton, Heading1, Markdown, colors } from "../../uikit";
import { useCurrentUserTerms } from "../../user-utils";

const TermsOfServicePage = () => {
  const { t } = useTranslation();

  const { data, isLoading } = useCurrentUserTerms();

  return (
    <Root>
      <Container>
        <Logo src={logo} alt={t("common:vapaus")} />
        <Heading1 color="primary" spacing="md" fontWeight="bold">
          {t("auth:terms.termsOfService")}
        </Heading1>
        <MarkdownWrapper>
          {isLoading ? (
            <StyledLoading />
          ) : (
            <Markdown content={data?.content || ""} />
          )}
        </MarkdownWrapper>
      </Container>
      <StyledButton icon="arrow_back" onClick={() => window.close()}>
        {t("common:back")}
      </StyledButton>
    </Root>
  );
};

export default TermsOfServicePage;

const Logo = styled.img`
  width: 190px;
`;

const StyledButton = styled(BaseButton as AnyStyledComponent)`
  margin: 0 auto;
`;

const StyledLoading = styled(Loading)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const Container = styled.div`
  padding: 50px;
  border-radius: 8px;
  background: ${colors.white};
  width: 768px;
  margin: 0 auto 20px auto;
  flex: 1;

  @media (max-width: 768px) {
    width: 100%;
    padding: 50px 20px;
  }
`;

const Root = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: calc(100vh - 60px);
`;

const MarkdownWrapper = styled.div`
  margin-top: 20px;
  text-align: justify;
  overflow-y: auto;
  @media (max-width: 768px) {
    width: 100%;
  }
`;
