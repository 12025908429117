import { forwardRef, useId, useState } from "react";

import styled from "styled-components";

import { BaseInput, BaseInputProps, Label } from "../Form";
import { Icon } from "../Icon";

interface Props extends BaseInputProps {
  label?: string;
}

export const PasswordInput = forwardRef<HTMLInputElement, Props>(
  (props, ref) => {
    const uniqueId = useId();
    const id = props.id || uniqueId;
    const [showPassword, setShowPassword] = useState(false);
    return (
      <Root>
        {props.label && <Label htmlFor={id}>{props.label}</Label>}
        <InputContainer>
          <BaseInput
            {...props}
            id={id}
            type={showPassword ? "text" : "password"}
            ref={ref}
          />
          <IconButton onClick={() => setShowPassword(!showPassword)}>
            <Icon
              name={
                showPassword ? "visibility_off_outline" : "visibility_outline"
              }
            />
          </IconButton>
        </InputContainer>
      </Root>
    );
  },
);

const Root = styled.div`
  width: 100%;
  margin-bottom: 1em;
`;

const IconButton = styled.div`
  cursor: pointer;
  position: absolute;
  right: 10px;
`;

const InputContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
`;
