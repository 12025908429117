import { css } from "styled-components";

const sizes = {
  mobile: "576px",
  tablet: "768px",
  laptop: "992px",
  desktop: "1200px",
};

export const devices = {
  mobileDown: `(max-width: ${sizes.mobile})`,
  tabletDown: `(max-width: ${sizes.tablet})`,
  laptopDown: `(max-width: ${sizes.laptop})`,
  desktopDown: `(max-width: ${sizes.desktop})`,
};

export const navBarSizes = {
  mobile: "56px",
  default: "80px",
};

export const spacings = {
  sm: "8px",
  md: "16px",
  lg: "24px",
  xl: "32px",
};

export type Spacings = keyof typeof spacings;

export const makeSpacing = (spacing?: Spacings) => {
  if (!spacing) return null;
  return css`
    margin-bottom: ${spacings[spacing]};
  `;
};
