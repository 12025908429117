import {
  FC,
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";

import { ToastMessage, ToastType } from "./ToastMessage";

type ToastContextType = {
  error: (message: string) => void;
  warning: (message: string) => void;
  success: (message: string) => void;
  info: (message: string) => void;
};

const ToastContext = createContext<ToastContextType>({
  error: (message: string) => {},
  warning: (message: string) => {},
  success: (message: string) => {},
  info: (message: string) => {},
});

type ToastProviderProps = {
  children: ReactNode;
  timeout?: number;
};

const DEFAULT_TIMEOUT = 5000;

export const ToastProvider: FC<ToastProviderProps> = ({
  children,
  timeout = DEFAULT_TIMEOUT,
}) => {
  const [toastQueue, setToastQueue] = useState<
    Array<{ type: ToastType; message: string }>
  >([]);
  const [visibleToast, setVisibleToast] = useState<{
    type: ToastType;
    message: string;
  } | null>(null);

  const showToast = useCallback(
    (type: ToastType, message: string) => {
      setToastQueue((prevQueue) => [...prevQueue, { type, message }]);
    },
    [setToastQueue],
  );

  const success = useCallback(
    (message: string) => {
      showToast("success", message);
    },
    [showToast],
  );

  const error = useCallback(
    (message: string) => {
      showToast("error", message);
    },
    [showToast],
  );

  const warning = useCallback(
    (message: string) => {
      showToast("warning", message);
    },
    [showToast],
  );

  const info = useCallback(
    (message: string) => {
      showToast("info", message);
    },
    [showToast],
  );

  useEffect(() => {
    if (toastQueue.length > 0 && visibleToast === null) {
      setVisibleToast(toastQueue[0]);
      setToastQueue((prevQueue) => prevQueue.slice(1));
    }
  }, [toastQueue, visibleToast]);

  useEffect(() => {
    if (visibleToast !== null) {
      const timerId = setTimeout(() => {
        setVisibleToast(null);
      }, timeout);
      return () => clearTimeout(timerId);
    }
  }, [visibleToast, timeout]);

  const value = useMemo(
    () => ({ success, error, warning, info }),
    [success, error, warning, info],
  );

  return (
    <ToastContext.Provider value={value}>
      {children}
      {visibleToast && (
        <ToastMessage
          type={visibleToast.type}
          message={visibleToast.message}
          onClearMessage={() => setVisibleToast(null)}
        />
      )}
    </ToastContext.Provider>
  );
};

export const useToast = () => useContext(ToastContext);
