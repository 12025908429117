import { UseQueryOptions, useMutation, useQuery } from "@tanstack/react-query";

import {
  ApiError,
  BookingRead,
  BookingsService,
  OrderDirection,
  PaginatedBookingOut,
} from "@vapaus/generated";

export const useCreateBooking = () =>
  useMutation(BookingsService.createBooking);

type BookingSearchParams = {
  q?: string;
  ids?: Array<string>;
  skip?: number;
  limit: number;
  sortField?: string;
  sortDirection?: OrderDirection;
  xFilterIds?: string;
};

export const useSearchBookings = (
  params: BookingSearchParams,
  options?: Omit<
    UseQueryOptions<PaginatedBookingOut, ApiError>,
    "queryKey" | "queryFn"
  >,
) =>
  useQuery<PaginatedBookingOut, ApiError>(
    ["bookings", params],
    () =>
      BookingsService.searchBookings(
        params?.q,
        params?.ids,
        params?.skip,
        params.limit,
        params?.sortField,
        params?.sortDirection,
        params?.xFilterIds,
      ),
    options,
  );

export const useCancelBooking = () =>
  useMutation((id: string) => BookingsService.cancelBooking(id));

export const useGetBooking = (
  id: string,
  options?: Omit<
    UseQueryOptions<BookingRead, ApiError>,
    "queryKey" | "queryFn"
  >,
) =>
  useQuery<BookingRead, ApiError>(
    ["bookings", id],
    () => BookingsService.getBooking(id),
    options,
  );
