/* generated using openapi-typescript-codegen -- do no edit */

/* istanbul ignore file */

/* tslint:disable */

/* eslint-disable */
import type { CancelablePromise } from "../core/CancelablePromise";
import { OpenAPI } from "../core/OpenAPI";
import { request as __request } from "../core/request";
import type { BookingTermsAndConditionsRead } from "../models/BookingTermsAndConditionsRead";
import type { FleetRead } from "../models/FleetRead";
import type { OrderDirection } from "../models/OrderDirection";
import type { PaginatedFleetOut } from "../models/PaginatedFleetOut";
import type { PaginatedVehicleOut } from "../models/PaginatedVehicleOut";
import type { UsageSettingsRead } from "../models/UsageSettingsRead";

export class FleetsService {
  /**
   * Get Fleets
   * @param isActive
   * @param q
   * @param ids
   * @param skip
   * @param limit
   * @param sortField
   * @param sortDirection
   * @param xFilterIds
   * @returns PaginatedFleetOut Successful Response
   * @throws ApiError
   */
  public static getFleets(
    isActive?: boolean,
    q?: string,
    ids?: Array<string>,
    skip?: number,
    limit: number = 20,
    sortField?: string,
    sortDirection?: OrderDirection,
    xFilterIds?: string,
  ): CancelablePromise<PaginatedFleetOut> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/api/fleets",
      headers: {
        "X-Filter-Ids": xFilterIds,
      },
      query: {
        is_active: isActive,
        q: q,
        ids: ids,
        skip: skip,
        limit: limit,
        sort_field: sortField,
        sort_direction: sortDirection,
      },
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Get Fleet
   * @param fleetId
   * @returns FleetRead Successful Response
   * @throws ApiError
   */
  public static getFleet(fleetId: string): CancelablePromise<FleetRead> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/api/fleets/{fleet_id}",
      path: {
        fleet_id: fleetId,
      },
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Get User Fleet Vehicles
   * @param fleetId
   * @param startDatetime
   * @param endDatetime
   * @param startLocationId
   * @param endLocationId
   * @returns PaginatedVehicleOut Successful Response
   * @throws ApiError
   */
  public static getUserFleetVehicles(
    fleetId: string,
    startDatetime?: string,
    endDatetime?: string,
    startLocationId?: string,
    endLocationId?: string,
  ): CancelablePromise<PaginatedVehicleOut> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/api/fleets/{fleet_id}/vehicles",
      path: {
        fleet_id: fleetId,
      },
      query: {
        start_datetime: startDatetime,
        end_datetime: endDatetime,
        start_location_id: startLocationId,
        end_location_id: endLocationId,
      },
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Get Fleet Usage Settings
   * @param fleetId
   * @returns UsageSettingsRead Successful Response
   * @throws ApiError
   */
  public static getFleetUsageSettings(
    fleetId: string,
  ): CancelablePromise<UsageSettingsRead> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/api/fleets/{fleet_id}/usage-settings",
      path: {
        fleet_id: fleetId,
      },
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Get Fleet Booking Terms And Conditions
   * @param fleetId
   * @returns BookingTermsAndConditionsRead Successful Response
   * @throws ApiError
   */
  public static getFleetBookingTermsAndConditions(
    fleetId: string,
  ): CancelablePromise<BookingTermsAndConditionsRead> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/api/fleets/{fleet_id}/booking-terms-and-conditions",
      path: {
        fleet_id: fleetId,
      },
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }
}
