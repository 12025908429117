import { ButtonHTMLAttributes, FC } from "react";

import styled, { css } from "styled-components";

import { Icon } from "../Icon";
import { colors } from "../constants";

const Spaces = {
  s: "8px",
  md: "16px",
  lg: "24px",
  xl: "32px",
};

export interface BaseButtonProps
  extends ButtonHTMLAttributes<HTMLButtonElement> {
  icon?: string;
  isLoading?: boolean;
  loadingText?: string;
  spacing?: keyof typeof Spaces;
  variant?: "outlined";
  color?: "white" | "gray" | "primary";
  textTransform?: "capitalize" | "uppercase" | "lowercase" | "full-width";
}

export const BaseButton: FC<BaseButtonProps> = (props) => {
  return (
    <Button {...props} disabled={props.isLoading || props.disabled}>
      {props.isLoading ? (
        <>
          <Spinner />
          <LoadingText>{props.loadingText}</LoadingText>
        </>
      ) : (
        <>
          {props.icon && <ButtonIcon name={props.icon} />}
          <span>{props.children}</span>
        </>
      )}
    </Button>
  );
};

const ButtonIcon = styled(Icon)`
  margin-right: 0.5em;
  opacity: 0.5;
  opacity: 1;
`;

const makeColor = (
  bg: string,
  bgDisabled: string,
  bgHover: string,
  font: string,
  variant?: string,
) => {
  if (variant === "outlined") {
    return css`
      border-width: 2px;
      background-color: ${font};
      border-color: ${bg};
      color: ${bg};
      ${ButtonIcon} {
        color: ${bg};
      }
      &:hover {
        background-color: ${bg};
        border-color: ${bg};
        color: ${font};
        i {
          color: ${font};
        }
      }
      &:disabled {
        background-color: ${bgDisabled};
        border-color: ${bgDisabled};
        color: ${font};
      }
    `;
  }
  return css`
    background-color: ${bg};
    border-color: ${bg};
    color: ${font};
    ${ButtonIcon} {
      color: ${font};
    }
    &:hover {
      background-color: ${bgHover};
      border-color: ${bgHover};
    }
    &:disabled {
      background-color: ${bgDisabled};
      border-color: ${bgDisabled};
    }
  `;
};

const Button = styled.button<BaseButtonProps>`
  height: 54px;
  border-radius: 5px;
  padding: 0 1.5em;
  font-size: 16px;
  font-family: "AvenirLTPro-Roman";
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: ${({ textTransform }) => textTransform || "uppercase"};
  border: 2px solid;
  min-width: max-content;
  font-weight: 700;

  margin-bottom: ${(props: any) => {
    if (props.spacing) {
      return Spaces[props.spacing as keyof typeof Spaces];
    }
    return "0px";
  }};

  ${(props) => {
    switch (props.color) {
      case "white":
        return makeColor(
          colors.white,
          colors.grayLight,
          colors.grayLight,
          colors.darkGray,
          props.variant,
        );
      case "gray":
        return makeColor(
          colors.darkGray,
          colors.gray,
          colors.gray,
          colors.white,
          props.variant,
        );
      case "primary":
      default:
        return makeColor(
          colors.primary,
          colors.primaryLight,
          colors.primaryDark,
          colors.white,
          props.variant,
        );
    }
  }}
`;

const Spinner = styled.div`
  border: 3px solid ${colors.white};
  border-top: 3px solid ${colors.primary};
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 0.5s linear infinite;
  margin-left: 0.5em;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

const LoadingText = styled.span`
  margin-left: 0.5em;
`;
