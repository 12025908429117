import { FC, useEffect, useMemo, useState } from "react";

import { DateTime } from "luxon";
import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";
import styled from "styled-components";

import { BookingUseType } from "@vapaus/generated";
import { useEnumEntries } from "@vapaus/utils";

import {
  Button,
  Card,
  Form,
  InfoCard,
  Loading,
  PageLoading,
  Text,
  bunnyImg,
  media,
} from "../../../packages/ui";
import {
  useGetFleet,
  useGetFleetLocations,
  useGetFleetUsageSettings,
  useGetFleetVehicles,
} from "../../hooks/fleet";
import { VehicleItemType } from "../../hooks/vehicle";
import { getDurationString } from "../../utils/getDurationString";
import BookingConfirmation from "./BookingConfirmation";
import VehicleItem from "./VehicleItem";

const FleetList: FC = () => {
  const { t } = useTranslation();
  const { fleetId } = useParams<{ fleetId: string }>();
  const { data: fleet, isLoading: isLoadingFleet } = useGetFleet(
    fleetId || "",
    {
      enabled: !!fleetId,
    },
  );
  const { data: vehicles, isLoading: isLoadingVehiclesData } =
    useGetFleetVehicles(fleetId || "", {
      enabled: !!fleetId,
    });

  const { data: usageSettings } = useGetFleetUsageSettings(fleetId || "", {
    enabled: !!fleetId,
  });

  const { getEnumLabel } = useEnumEntries("BookingUseType");

  const isLoading = isLoadingFleet || isLoadingVehiclesData;

  const [selectedVehicle, setSelectedVehicle] = useState<VehicleItemType>();

  const [rideDetails, setRideDetails] = useState({
    pickupLocation: undefined as any,
    pickupDate: DateTime.now().toFormat("yyyy-MM-dd"),
    pickupTime: DateTime.now().toFormat("HH:mm"),
    returnDate: DateTime.now().toFormat("yyyy-MM-dd"),
    returnTime: DateTime.now().plus({ hours: 1 }).toFormat("HH:mm"),
    useType: undefined as any,
  });

  const { data: locationData } = useGetFleetLocations(
    {
      fleetId: fleetId || "",
    },
    {
      enabled: !!fleetId,
      onSuccess: (data) => {
        // Set the first location as default
        if (data.items.length > 0)
          setRideDetails({
            ...rideDetails,
            pickupLocation: data.items[0],
          });
      },
    },
  );

  const duration = useMemo<string>(() => {
    const durationString = getDurationString(
      `${rideDetails.pickupDate}T${rideDetails.pickupTime}`,
      `${rideDetails.returnDate}T${rideDetails.returnTime}`,
      t,
    );
    return durationString || t("fleet:booking.invalidTime");
  }, [rideDetails]);

  const locations = (locationData?.items || []).map((item) => ({
    id: item.id,
    name: item.name,
  }));

  const useTypes = useMemo(() => {
    const useTypes = [];
    if (usageSettings?.private_use)
      useTypes.push({
        id: BookingUseType.PERSONAL,
        name: getEnumLabel(BookingUseType.PERSONAL) || "",
      });
    if (usageSettings?.business_use)
      useTypes.push({
        id: BookingUseType.BUSINESS,
        name: getEnumLabel(BookingUseType.BUSINESS) || "",
      });
    return useTypes;
  }, [usageSettings]);

  const validationMessage = useMemo(() => {
    const max_future_start = usageSettings?.max_future_start || 0;
    const min_booking_time = usageSettings?.min_booking_time || 0;
    const max_booking_time = usageSettings?.max_booking_time || 0;

    const pickupTime = DateTime.fromFormat(
      `${rideDetails.pickupDate}T${rideDetails.pickupTime}`,
      "yyyy-MM-dd'T'HH:mm",
    );
    const returnTime = DateTime.fromFormat(
      `${rideDetails.returnDate}T${rideDetails.returnTime}`,
      "yyyy-MM-dd'T'HH:mm",
    );

    const diff = pickupTime.diff(DateTime.now(), "minutes").minutes;
    if (diff > max_future_start) {
      return t("fleet:booking.validationMessage.maxFutureStart", {
        max: Math.ceil(max_future_start / 60),
      });
    }

    const duration = returnTime.diff(pickupTime, "minutes").minutes;
    if (duration < min_booking_time || duration > max_booking_time) {
      return t("fleet:booking.validationMessage.minMaxDuration", {
        min: Math.ceil(min_booking_time / 60),
        max: Math.ceil(max_booking_time / 60),
      });
    }

    return undefined;
  }, [usageSettings, rideDetails]);

  useEffect(() => {
    setRideDetails({
      ...rideDetails,
      useType: useTypes[0]?.id,
    });
  }, [useTypes]);

  if (isLoading) return <PageLoading />;

  return (
    <div>
      <PageHeader>
        <Link to="/book-a-ride">
          <Button icon="arrow_back" text="Back" />
        </Link>
        <Text type="title" text={t("fleet:fleet.serviceProviders")} />
      </PageHeader>
      <Card>
        <FleetInfo>
          <img src={fleet?.logo_url || bunnyImg} alt="logo" />
          <div>
            <Text type="subTitle" text={fleet?.organisation?.name || ""} />
            <Text type="title" text={fleet?.name || ""} />
          </div>
        </FleetInfo>
        <hr />
        <Text text={t("fleet:booking.formInstruction")} />
        <Form>
          <PickupLocation>
            <InputWrapper>
              <Label text={t("fleet:booking.pickupLocation")} />
              <FormPart>
                <Form.Input
                  type="select"
                  data={locations}
                  onChange={(e) =>
                    setRideDetails({
                      ...rideDetails,
                      pickupLocation: locationData?.items.find(
                        (item) => item.id === e,
                      ),
                    })
                  }
                />
              </FormPart>
            </InputWrapper>
            <InputWrapper>
              <Label text={t("fleet:booking.useType")} />
              <FormPart>
                <Form.Input
                  type="select"
                  data={useTypes}
                  onChange={(e) =>
                    setRideDetails({
                      ...rideDetails,
                      useType: e as BookingUseType,
                    })
                  }
                />
              </FormPart>
            </InputWrapper>
            <InputWrapper>
              <Label text={t("fleet:booking.pickupTime")} />
              <FormPart>
                <Form.Input
                  type="date"
                  value={rideDetails.pickupDate}
                  onChange={(e) =>
                    setRideDetails({ ...rideDetails, pickupDate: e })
                  }
                />
                <Text text="at" />
                <Form.Input
                  type="time"
                  value={rideDetails.pickupTime}
                  onChange={(e) =>
                    setRideDetails({ ...rideDetails, pickupTime: e })
                  }
                />
              </FormPart>
            </InputWrapper>

            <InputWrapper>
              <Label text={t("fleet:booking.returnTime")} />
              <FormPart>
                <Form.Input
                  type="date"
                  value={rideDetails.returnDate}
                  onChange={(e) =>
                    setRideDetails({ ...rideDetails, returnDate: e })
                  }
                />
                <Text text="at" />
                <Form.Input
                  type="time"
                  value={rideDetails.returnTime}
                  onChange={(e) =>
                    setRideDetails({ ...rideDetails, returnTime: e })
                  }
                />
              </FormPart>
            </InputWrapper>
            <InputWrapper>
              <Label text={t("fleet:booking.tripDuration")} />
              <FormPart>
                <Text text={duration} />
              </FormPart>
            </InputWrapper>
          </PickupLocation>
        </Form>
      </Card>
      {validationMessage && (
        <StyledInfoCard type="alert" icon="info">
          {validationMessage}
        </StyledInfoCard>
      )}
      <VehicleList>
        {isLoading ? (
          <Loading />
        ) : (
          <>
            {vehicles?.items?.length ? (
              <>
                <Text
                  type="title"
                  text={t("fleet:booking.availableVehicles")}
                />
                <ItemWrapper>
                  {vehicles.items.map((item) => (
                    <VehicleItem
                      key={item.id}
                      selectedVehicle={item}
                      onClick={() => setSelectedVehicle(item)}
                      disableButton={validationMessage !== undefined}
                    />
                  ))}
                </ItemWrapper>
              </>
            ) : (
              <InfoCard type="info" icon="info">
                {t("fleet:booking.noAvailableVehicles")}
              </InfoCard>
            )}
          </>
        )}
      </VehicleList>
      {selectedVehicle && (
        <BookingConfirmation
          vehicle={selectedVehicle}
          rideDetails={rideDetails}
          durationString={duration}
          onClose={() => setSelectedVehicle(undefined)}
        />
      )}
    </div>
  );
};

export default FleetList;

const StyledInfoCard = styled(InfoCard)`
  margin: 1rem 0;
`;

const FleetInfo = styled.div`
  display: flex;
  align-items: center;
  img {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    margin-right: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f5f5f5;
    object-fit: contain;
    padding: 0.5em;
  }
`;

const PickupLocation = styled.div`
  margin: 1rem 0;
`;

const FormPart = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  > * {
    margin: 0 0.5rem;
  }
  fieldset {
    flex: 1;
  }
  ${media.atMobile} {
    > *:first-child {
      margin-left: 0;
    }
  }
`;

const Label = styled(Text)`
  flex: 0.2;
  ${media.atMobile} {
    flex: 1;
  }
`;

const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 1rem 0;
  > * {
    margin: 0 0.5rem;
  }
  width: 100%;
  ${media.atMobile} {
    flex-direction: column;
    align-items: flex-start;
    > * {
      margin: 0.25rem 0;
      width: 100%;
    }
  }
`;

const VehicleList = styled.div`
  margin-top: 3rem;
`;

const ItemWrapper = styled.div`
  margin-top: 20px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 20px;
  margin-bottom: 20px;
  ${media.atTablet} {
    grid-template-columns: repeat(2, 1fr);
  }
  ${media.atMobile} {
    grid-template-columns: repeat(2, 1fr);
  }
`;

const PageHeader = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  *:first-child {
    margin-right: 1rem;
  }
`;
