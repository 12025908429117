import {
  UseQueryOptions,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";

import {
  ApiError,
  BenefitDefinitionAccessRequestsService,
  BenefitDefinitionTermsRead,
  BenefitDefinitionTermsService,
  BenefitDefinitionUserRead,
  BenefitDefinitionsService,
  BenefitService,
  BikeBenefitContractRead,
  BikeBenefitOrderRead,
  BikeBenefitOrderState,
  MaintenanceEventsService,
  OrderDirection,
  PaginatedBikeBenefitContractOut,
  PaginatedBikeBenefitOrderOut,
  PaginatedMaintenanceEventOut,
  PaginatedUserBenefitActivationOut,
  UserBenefitActivationRead,
  UserBenefitDefinitionAccessRequestCreate,
  UserBenefitDefinitionAccessRequestRead,
} from "@vapaus/generated";

type UseBenefitOrdersParams = {
  states?: BikeBenefitOrderState[];
  limit?: number;
};

export const useBenefitOrders = (
  params?: UseBenefitOrdersParams,
  options?: Omit<
    UseQueryOptions<PaginatedBikeBenefitOrderOut, ApiError>,
    "queryKey" | "queryFn"
  >,
) =>
  useQuery<PaginatedBikeBenefitOrderOut, ApiError>(
    ["orders", params],
    () =>
      BenefitService.searchBikeBenefitOrders(
        params?.states,
        undefined,
        undefined,
        undefined,
        params?.limit || 50,
        "updated_at",
        OrderDirection.DESC,
      ),
    options,
  );

export const useBenefitOrder = (
  vapausCode: string,
  options?: Omit<
    UseQueryOptions<BikeBenefitOrderRead, ApiError>,
    "queryKey" | "queryFn"
  >,
) =>
  useQuery<BikeBenefitOrderRead, ApiError>(
    ["orders", vapausCode],
    () => BenefitService.getBikeBenefitOrder(vapausCode),
    options,
  );

export const useGetBenefitContract = (
  contractId: string,
  options?: Omit<
    UseQueryOptions<BikeBenefitContractRead, ApiError>,
    "queryKey" | "queryFn"
  >,
) =>
  useQuery<BikeBenefitContractRead, ApiError>(
    ["contracts", contractId],
    () => BenefitService.getBikeBenefitContract(contractId),
    options,
  );

export const useSearchBenefitContracts = (
  params?: {
    benefitDefinitionId?: string;
    q?: string;
    ids?: Array<string>;
    skip?: number;
    limit?: number;
    sortField?: string;
    sortDirection?: OrderDirection;
  },
  options?: Omit<
    UseQueryOptions<PaginatedBikeBenefitContractOut, ApiError>,
    "queryKey" | "queryFn"
  >,
) =>
  useQuery<PaginatedBikeBenefitContractOut, ApiError>(
    ["contracts", params],
    () =>
      BenefitService.searchBikeBenefitContracts(
        params?.benefitDefinitionId,
        params?.q,
        params?.ids,
        params?.skip,
        params?.limit,
        params?.sortField,
        params?.sortDirection,
      ),
    options,
  );

export const useAcceptBikeBenefitOrder = (
  vapausCode: string,
  accepted_version: string,
  optional_cancellation_coverage: boolean,
) =>
  useMutation(() =>
    BenefitService.acceptBikeBenefitOrder(vapausCode, {
      accepted_version,
      optional_cancellation_coverage,
    }),
  );

export const useBenefitDefinitionTerms = (
  benefitDefinitionTermId: string,
  options?: Omit<
    UseQueryOptions<BenefitDefinitionTermsRead, ApiError>,
    "queryKey" | "queryFn"
  >,
) =>
  useQuery<BenefitDefinitionTermsRead, ApiError>(
    ["benefitDefinitionTerms", benefitDefinitionTermId],
    () =>
      BenefitDefinitionTermsService.getBenefitDefinitionTerms(
        benefitDefinitionTermId,
      ),
    options,
  );

export const useGetBenefitDefinition = (
  benefitDefinitionId: string,
  onlyActive: boolean,
  options?: Omit<
    UseQueryOptions<BenefitDefinitionUserRead, ApiError>,
    "queryKey" | "queryFn"
  >,
) =>
  useQuery<BenefitDefinitionUserRead, ApiError>(
    ["benefitDefinitions", benefitDefinitionId],
    () =>
      BenefitDefinitionsService.getBenefitDefinition(
        benefitDefinitionId,
        onlyActive,
      ),
    options,
  );

export const useGetMaintenanceEvents = (
  vapausCode?: string,
  options?: Omit<
    UseQueryOptions<PaginatedMaintenanceEventOut, ApiError>,
    "queryKey" | "queryFn"
  >,
) =>
  useQuery<PaginatedMaintenanceEventOut, ApiError>(
    ["maintenanceEvents"],
    () => MaintenanceEventsService.searchUsersMaintenanceEvent(vapausCode),
    options,
  );

export const useAcceptBenefitTerms = () => {
  const queryClient = useQueryClient();
  return useMutation(
    ({
      benefitDefinitionTermsId,
      orderOrContractId,
    }: {
      benefitDefinitionTermsId: string;
      orderOrContractId: string;
    }) =>
      BenefitDefinitionTermsService.acceptBenefitDefinitionTerms(
        benefitDefinitionTermsId,
        orderOrContractId,
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["contracts"]);
        queryClient.invalidateQueries(["orders"]);
      },
    },
  );
};

export const useSendBenefitDefinitionAccessRequest = () =>
  useMutation<
    UserBenefitDefinitionAccessRequestRead,
    ApiError,
    UserBenefitDefinitionAccessRequestCreate
  >(
    BenefitDefinitionAccessRequestsService.createBenefitDefinitionAccessRequest,
  );

export const useActivateBenefitDefinition = () =>
  useMutation<UserBenefitActivationRead, ApiError, string>(
    (benefitId: string) =>
      BenefitDefinitionsService.activateBenefitDefinition(benefitId),
  );

export const useGetUserBenefitActivations = (
  params?: {
    activeOnly?: boolean;
    q?: string;
    ids?: Array<string>;
    skip?: number;
    limit?: number;
    sortField?: string;
    sortDirection?: OrderDirection;
  },
  options?: Omit<
    UseQueryOptions<PaginatedUserBenefitActivationOut, ApiError>,
    "queryKey" | "queryFn"
  >,
) =>
  useQuery<PaginatedUserBenefitActivationOut, ApiError>(
    ["userBenefitActivations", params],
    () =>
      BenefitDefinitionsService.getBenefitDefinitionsActivations(
        params?.activeOnly,
        params?.q,
        params?.ids,
        params?.skip,
        params?.limit,
        params?.sortField,
        params?.sortDirection,
      ),
    { retry: 0, ...options },
  );
