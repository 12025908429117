import { FC } from "react";

import TableComponent from "react-data-table-component";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { Card } from "../Card";
import { Loading } from "../Loading";
import { fontTypes } from "../Typography";
import { colors } from "../colors";

type Props = {
  columns: any[];
  data: any[];
  title?: string;
  isLoading?: boolean;
  onClickRow?: (row: any) => void;
  className?: string;
};

export const DataTable: FC<Props> = ({
  columns = [],
  data = [],
  title,
  isLoading,
  onClickRow,
  className,
}) => {
  const { t } = useTranslation();
  const itemPerPage = 20;
  const pagination = data && data.length > itemPerPage;
  return (
    <Wrapper>
      <Card title={title} className={className}>
        {(isLoading && <Loading />) || (
          <TableComponent
            columns={columns}
            data={data}
            pagination={pagination}
            paginationPerPage={itemPerPage}
            onRowClicked={onClickRow}
            noDataComponent={<NoData>{t("common:noTableData")}</NoData>}
            responsive
          />
        )}
      </Card>
    </Wrapper>
  );
};

const NoData = styled.div`
  font-family: ${fontTypes.default};
  font-size: 1em;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 20em;
`;

const Wrapper = styled.div`
  margin-bottom: 1.5em;

  & .CardContent {
    margin-left: -1.5em;
    width: calc(100% + 3em);
  }

  & .rdt_TableRow {
    cursor: pointer;
    font-size: 1rem;
    font-family: ${fontTypes.default};
    color: ${colors.primaryColor};

    &:hover {
      background-color: ${colors.primaryColor20RGB} !important;
    }
  }

  & .rdt_TableHeadRow {
    font-size: 1rem;
    font-family: ${fontTypes.default};
    color: ${colors.primaryColor60RGB};
  }

  & .rdt_TableFooter {
    font-size: 1rem;
    font-family: ${fontTypes.default};
    color: ${colors.primaryColor};
  }
`;
