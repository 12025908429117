export const fontSizes = {
  heading1: "32px",
  heading2: "28px",
  heading3: "22px",
  heading4: "18px",
  heading5: "16px",
  heading6: "14px",
  subtitle1: "16px",
  subtitle2: "14px",
  body1: "16px",
  body2: "14px",
};

export const fontFamily = "AvenirLTPro-Roman, sans-serif";
