import { FC, useState } from "react";

import { useTranslation } from "react-i18next";

import { ApiError } from "@vapaus/generated";
import { parseApiError } from "@vapaus/utils";

import { Button, Dialog, DialogProps } from "../../../../ui";
import { useCurrentUser, useUpdateCurrentUser } from "../../../../user-utils";

const AddFleetAccessCode: FC = () => {
  const { t } = useTranslation();

  const [dialog, setDialog] = useState<DialogProps | undefined>();

  const { data: user, refetch } = useCurrentUser();

  const updateUser = useUpdateCurrentUser();

  const handleSubmit = async (fleet_access_code: string) => {
    if (!fleet_access_code || !user) return;
    updateUser.mutate(
      {
        fleet_access_codes: [
          ...(user.fleet_access_codes || []),
          fleet_access_code,
        ],
      },
      {
        onSuccess: () => refetch(),
        onError: (error: any) =>
          setDialog({
            type: "alert",
            title: t("settings:fleetAccessCode.add.error"),
            animation: "error",
            description: parseApiError(error as ApiError, t),
            onClose: () => setDialog(undefined),
          }),
      },
    );
  };

  const showEnterPhoneDialog = () => {
    setDialog({
      type: "prompt-text",
      title: t("settings:fleetAccessCode.add.title"),
      mandatory: true,
      onClose: () => setDialog(undefined),
      onSubmit: handleSubmit,
      submit: t("settings:fleetAccessCode.add.submit"),
    });
  };

  return (
    <>
      <Button
        secondary
        text={t("settings:fleetAccessCode.add.action")}
        icon="bike_scooter"
        onClick={showEnterPhoneDialog}
      />
      {dialog && (
        <Dialog
          cancel={t("common:dialog.cancel")}
          close={t("common:dialog.close")}
          {...dialog}
        />
      )}
    </>
  );
};

export default AddFleetAccessCode;
