import { FC, useState } from "react";

import { useTranslation } from "react-i18next";

import { parseApiError } from "@vapaus/utils";

import { Button, Dialog, DialogProps } from "../../../../ui";
import {
  useCurrentUser,
  useSendPhoneNumberVerificationCode,
  useUpdateCurrentUser,
  useVerifyPhoneNumber,
} from "../../../../user-utils";
import VerificationCard from "../../VerificationCard";
import { DetailType } from "../../types";

const PhoneDetail: FC = () => {
  const { t } = useTranslation();

  const [dialog, setDialog] = useState<DialogProps | undefined>();

  const { data: user, refetch } = useCurrentUser();

  const updateUser = useUpdateCurrentUser();

  const verifyCode = useVerifyPhoneNumber();

  const sendVerificationCode = useSendPhoneNumberVerificationCode();

  const handleDeletePhone = () => {
    updateUser.mutate(
      { phone_number: "" },
      {
        onSuccess: () => refetch(),
        onError: (error: any) =>
          setDialog({
            type: "alert",
            title: t("settings:cardDetails.phone.errorDeleting"),
            animation: "error",
            description: parseApiError(error, t),
            onClose: () => setDialog(undefined),
          }),
      },
    );
  };

  const handleSendVerification = () => {
    sendVerificationCode.mutate(undefined, {
      onSuccess: () =>
        setDialog({
          type: "alert",
          title: t("settings:cardDetails.phone.verifyPhone"),
          subTitle: t("settings:cardDetails.phone.verificationCodeSent"),
          animation: "success",
          description: t(
            "settings:cardDetails.phone.verificationCodeSentDescription",
          ),
          onClose: () => setDialog(undefined),
        }),
      onError: (error: any) =>
        setDialog({
          type: "alert",
          title: t("settings:cardDetails.phone.errorSendingVerificationCode"),
          animation: "error",
          description: parseApiError(error, t),
          onClose: () => setDialog(undefined),
        }),
    });
  };

  const handleVerifyCode = (code: number) => {
    verifyCode.mutate(
      { code },
      {
        onSuccess: () => {
          setDialog({
            type: "confirm",
            title: t("settings:cardDetails.phone.phoneVerification"),
            subTitle: t("settings:cardDetails.phone.phoneVerified"),
            animation: "success",
            description: t("settings:cardDetails.phone.phoneVerified"),
            onClose: () => setDialog(undefined),
          });
          refetch();
        },
        onError: (error: any) =>
          setDialog({
            type: "alert",
            title: t("settings:cardDetails.phone.errorSendingVerification"),
            animation: "error",
            description: parseApiError(error, t),
            onClose: () => setDialog(undefined),
          }),
      },
    );
  };

  const showVerifyPhoneDialog = () => {
    setDialog({
      type: "prompt-number",
      title: t("settings:cardDetails.phone.enterVerificationCode"),
      subTitle: t("settings:cardDetails.phone.verificationCodeSent"),
      animation: "warning",
      content: (
        <p style={{ marginBottom: 20 }}>
          {t("settings:cardDetails.phone.verificationCodeSentDescription")}
          <br />
          <Button
            secondary
            onClick={handleSendVerification}
            text={t("settings:cardDetails.phone.sendAgain")}
          />
        </p>
      ),
      onSubmit: handleVerifyCode,
      onClose: () => setDialog(undefined),
    });
  };

  if (!user?.phone_number) return null;

  return (
    <>
      {dialog && (
        <Dialog
          cancel={t("common:dialog.cancel")}
          close={t("common:dialog.close")}
          {...dialog}
        />
      )}
      <VerificationCard
        type={DetailType.Phone}
        title={user?.phone_number}
        description={
          user?.phone_number_verified
            ? t("settings:verificationCard.verifiedPhone")
            : t("settings:verificationCard.unverifiedPhone")
        }
        isVerified={user?.phone_number_verified === true}
        canDelete
        onVerify={showVerifyPhoneDialog}
        onDelete={handleDeletePhone}
      />
    </>
  );
};

export default PhoneDetail;
