import React from "react";

import { Text } from "../Text";
import "./Pair.scss";

type PairProps = {
  show?: boolean;
  hideEmpty?: boolean;
  text?: string | number | JSX.Element;
  title?: string;
  className?: string;
  margin?: "normal" | "small";
};

export const Pair = ({
  show,
  hideEmpty,
  text,
  title,
  className,
  margin,
}: PairProps) => {
  if (show === false) {
    return null;
  }

  if (hideEmpty && !text) {
    return null;
  }

  let _className = "Pair";
  if (className) {
    _className += " " + className;
  }

  if (margin) {
    _className += ` margin-${margin}`;
  }

  const _title = title && (
    <Text verySmall type="title" className="PairTitle" text={title} />
  );
  const _text = text && (
    <Text verySmall type="subTitle" className="PairText" text={text} />
  );

  return (
    <div className={_className}>
      {_title}
      {_text}
    </div>
  );
};
