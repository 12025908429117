import React, { useEffect } from "react";

import { useTranslation } from "react-i18next";

import { AnimatedIcon, AnimatedIconType } from "../AnimatedIcon";
import { Button } from "../Button";
import { Card } from "../Card";
import { CircularProgress } from "../CircularProgress";
import { Form } from "../Form";
import { Icon } from "../Icon";
import { Modal } from "../Modal";
import { portalTo } from "../Portal";
import "./Dialog.scss";

export type DialogProps = {
  mandatory?: boolean;
  onClose: Function;
  onSubmit?: Function;
  onConfirm?: Function;
  onCancel?: Function;
  autoDoneTimeout?: number;
  type:
    | "alert"
    | "confirm"
    | "prompt-text"
    | "prompt-email"
    | "prompt-number"
    | "prompt-select"
    | "progress";
  title?: string;
  subTitle?: string;
  closeIcon?: string;
  close?: string;
  submitIcon?: string;
  submit?: string;
  confirmIcon?: string;
  confirm?: string;
  cancelIcon?: string;
  cancel?: string;
  animation?: AnimatedIconType;
  progress?: number;
  icon?: string;
  iconClassName?: string;
  descriptionAlign?: string;
  description?: Array<string> | string | JSX.Element;
  value?: string;
  placeholder?: string;
  content?: any;
  choose?: string;
  options?: Array<{ [key: string]: string }>;
  align?: "left" | "justify";
  noCancel?: boolean;
};

const Dialog_ = ({
  type,
  title,
  subTitle,
  closeIcon,
  close,
  submitIcon,
  submit,
  confirmIcon,
  confirm,
  cancelIcon,
  cancel,
  animation,
  progress,
  icon,
  iconClassName,
  descriptionAlign,
  description: content,
  value,
  placeholder,
  content: phoneNumberContent,
  choose,
  options,
  mandatory,
  onClose,
  onSubmit,
  onConfirm,
  onCancel,
  autoDoneTimeout,
  align,
  noCancel,
}: DialogProps) => {
  const { t } = useTranslation();

  useEffect(() => {
    if (!autoDoneTimeout || autoDoneTimeout <= 0) return;
    const timerId = setTimeout(onClose, autoDoneTimeout);
    return () => clearTimeout(timerId);
  }, [autoDoneTimeout]);

  const handleSubmit = (data: any) => {
    if (mandatory && data.prompt === "") {
      return false;
    }

    onClose();
    onSubmit?.(data.prompt);
  };

  const handleConfirm = () => {
    onConfirm?.();
    onClose();
  };

  const handleCancel = () => {
    onCancel?.();
    onClose();
  };

  const closeButton = autoDoneTimeout === undefined && (
    <Button
      secondary
      onClick={() => onClose()}
      icon={closeIcon ? closeIcon : "check"}
      text={close || t("common:dialog.close")}
    />
  );
  const submitButton = (
    <Form.Submit>
      <Button
        secondary
        icon={submitIcon ? submitIcon : "check"}
        text={submit || t("common:dialog.submit")}
      />
    </Form.Submit>
  );
  const confirmButton = (
    <Button
      secondary
      onClick={handleConfirm}
      icon={confirmIcon ? confirmIcon : "check"}
      text={confirm || t("common:dialog.confirm")}
    />
  );
  const cancelButton = noCancel ? null : (
    <Button
      secondary
      onClick={handleCancel}
      icon={cancelIcon ? cancelIcon : "cancel"}
      text={cancel || t("common:dialog.cancel")}
    />
  );

  let description: Array<JSX.Element> = [];

  if (animation) {
    description.push(<AnimatedIcon key="animation" type={animation} />);
  }

  if (icon) {
    description.push(
      <Icon name={icon} className={"descriptionIcon " + iconClassName} />,
    );
  }

  if (progress) {
    description.push(
      <CircularProgress
        key="progress"
        radius={60}
        stroke={4}
        progress={progress}
      />,
    );
  }

  let descriptionClassName = "description";
  if (descriptionAlign) {
    descriptionClassName += " align-" + descriptionAlign;
  }

  if (content instanceof Array) {
    content.forEach((desc: string, index: number) => {
      description.push(
        <div className={descriptionClassName} key={index}>
          {desc}
        </div>,
      );
    });
  } else if (content) {
    description.push(
      <div key="description" className={descriptionClassName}>
        {content}
      </div>,
    );
  }

  let input;
  let footer;
  let className = "Dialog type-" + type;

  if (align) {
    className += ` align-${align}`;
  }

  switch (type) {
    case "alert":
      footer = <div className="buttons">{closeButton}</div>;
      break;

    case "confirm":
      footer = (
        <div className="buttons">
          {confirmButton}
          {cancelButton}
        </div>
      );
      break;

    case "prompt-text":
      footer = (
        <div className="buttons">
          {submitButton}
          {cancelButton}
        </div>
      );
      input = (
        <Form.Input
          autoFocus
          type="text"
          name="prompt"
          value={value}
          placeholder={placeholder}
        />
      );
      description = [input];
      break;

    case "prompt-email":
      footer = (
        <div className="buttons">
          {submitButton}
          {cancelButton}
        </div>
      );
      input = (
        <Form.Input
          autoFocus
          type="email"
          name="prompt"
          value={value}
          placeholder={placeholder}
        />
      );
      description = [input];
      break;

    case "prompt-number":
      footer = (
        <div className="buttons">
          {submitButton}
          {cancelButton}
        </div>
      );
      input = (
        <>
          {phoneNumberContent}
          <Form.Input
            autoFocus
            type="number"
            name="prompt"
            value={value}
            placeholder={placeholder}
          />
        </>
      );
      description = [input];
      break;

    case "prompt-select":
      footer = (
        <div className="buttons">
          {submitButton}
          {cancelButton}
        </div>
      );
      input = (
        <Form.Input
          autoFocus
          type="select"
          choose={choose || (t("common:dialog.choose") as string | undefined)}
          name="prompt"
          value={value}
          placeholder={placeholder}
          data={options}
        />
      );
      description = [input];
      break;

    case "progress":
      footer = <div className="buttons">{cancelButton}</div>;
      break;

    default:
      break;
  }

  return (
    <Modal>
      <Form onSubmit={handleSubmit}>
        <Card
          className={className}
          title={title}
          subTitle={subTitle}
          description={description}
          footer={footer}
        />
      </Form>

      <p>&nbsp;</p>
    </Modal>
  );
};

export const Dialog = portalTo<DialogProps>("App")(Dialog_);
