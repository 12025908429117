import { UseQueryOptions, useMutation, useQuery } from "@tanstack/react-query";

import {
  Body_user_invoices_pay_sale_invoice,
  OrderDirection,
  PaginatedPaymentOut,
  PaginatedSaleInvoiceOut,
  PaymentsService,
  SaleInvoiceRead,
  SaleInvoiceStatus,
  UserInvoicesService,
} from "@vapaus/generated";
import type { ApiError } from "@vapaus/generated";

type UseInvoiceListParams = {
  status?: SaleInvoiceStatus[];
  q?: string;
  ids?: string[];
  skip?: number;
  limit?: number;
};

export const useInvoiceList = (
  params?: UseInvoiceListParams,
  options?: Omit<
    UseQueryOptions<PaginatedSaleInvoiceOut, ApiError>,
    "queryKey" | "queryFn"
  >,
) =>
  useQuery<PaginatedSaleInvoiceOut, ApiError>(
    ["invoices", params],
    () =>
      UserInvoicesService.searchSaleInvoices(
        params?.status,
        undefined,
        undefined,
        params?.q,
        params?.ids,
        params?.skip,
        params?.limit,
      ),
    options,
  );

export const usePaynow = () =>
  useMutation(
    ({ id, data }: { id: string; data: Body_user_invoices_pay_sale_invoice }) =>
      UserInvoicesService.paySaleInvoice(id, data),
  );

export const useGetInvoice = (
  id: string,
  options?: Omit<
    UseQueryOptions<SaleInvoiceRead, ApiError>,
    "queryKey" | "queryFn"
  >,
) =>
  useQuery<SaleInvoiceRead, ApiError>(
    ["invoices", id],
    () => UserInvoicesService.getSaleInvoice(id),
    options,
  );

export const useSearchPayments = (
  saleInvoiceId: string,
  options?: Omit<
    UseQueryOptions<PaginatedPaymentOut, ApiError>,
    "queryKey" | "queryFn"
  >,
) =>
  useQuery<PaginatedPaymentOut, ApiError>(
    ["payments", saleInvoiceId],
    () =>
      PaymentsService.searchPayments(
        saleInvoiceId,
        undefined,
        undefined,
        undefined,
        20,
        "created_at",
        OrderDirection.DESC,
      ),
    options,
  );
