import { ReactElement } from "react";

import { UseMutationOptions } from "@tanstack/react-query";

import {
  ProviderNotificationCategory,
  ProviderNotificationPreference,
} from "@vapaus/generated";

import { InputProps } from "../../ui";

export interface NotificationCategoryEntry<NotificationCategory> {
  id: NotificationCategory;
  label: string;
  description: string;
}

export interface NotificationPreferencesProps<NotificationCategory> {
  categories: NotificationCategoryEntry<NotificationCategory>[];
  selectedCategories?: NotificationCategory[];
  refetchSelectedCategories: () => void;
  saveOptedOutCategories: (
    optedOutCategories: NotificationCategory[],
    {
      onSuccess,
      onError,
    }: Pick<
      UseMutationOptions<
        ProviderNotificationPreference[],
        unknown,
        ProviderNotificationCategory[]
      >,
      "onSuccess" | "onError"
    >,
  ) => void;
}

export interface LayoutProps<NotificationCategory> {
  personalInfoForm: InputProps[];
  personalDetails: DetailType[];
  changePassword?: boolean;
  children?: React.ReactNode;
  title?: string;
  validate?: (values: Record<string, string>) => boolean;
  notificationPreferences?: NotificationPreferencesProps<NotificationCategory>;
}

export enum DetailType {
  Email = "email",
  Phone = "phone",
  SSN = "ssn",
  FleetAccessCode = "fleet-access-code",
  Access = "access",
  License = "license",
  Payment = "payment",
}

export interface UserDetailItem {
  type: DetailType;
  title?: string;
  description?: string;
  body?: string | ReactElement;
  isVerified?: boolean;
  verifyButtonLabel?: string;
  verifyButtonDisabled?: boolean;
  canDelete?: boolean;
  onVerify?: () => void;
  onDelete?: () => void;
  onMarkAsPrimary?: () => void;
  data?: {
    [key: string]: any;
  };
}
