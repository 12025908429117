import React from "react";

import clsx from "clsx";

import "./Content.scss";

type ContentProps = {
  id?: string;
  className?: string;
  withHeader?: boolean;
  withFooter?: boolean;
  children: React.ReactNode;
};

export const Content = ({
  id,
  className,
  children,
  withHeader,
  withFooter,
}: ContentProps) => {
  return (
    <div
      id={id}
      className={clsx("Content", className, { withHeader, withFooter })}
    >
      <div className="main">{children}</div>
    </div>
  );
};
