import { FC } from "react";

import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { Card, Text, bunnyImg } from "../../../packages/ui";

const EmptyFleetList: FC = () => {
  const { t } = useTranslation();
  return (
    <Wrapper>
      <Text text={t("fleet:fleet.emptyFleetList")} />
      <div>
        <img src={bunnyImg} alt="provider" />
      </div>
    </Wrapper>
  );
};

export default EmptyFleetList;

const Wrapper = styled(Card)`
  .CardContent {
    display: flex;
    width: 100% !important;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    padding: 20px;
    align-items: center;
    min-height: 500px;
    p {
      margin-bottom: 50px;
      max-width: 200px;
      text-align: center;
    }
    img {
      border-radius: 10px;
      max-width: 150px;
    }
  }
  margin-bottom: 20px;
  border-radius: 20px;
`;
