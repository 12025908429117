/* generated using openapi-typescript-codegen -- do no edit */

/* istanbul ignore file */

/* tslint:disable */

/* eslint-disable */
import type { CancelablePromise } from "../core/CancelablePromise";
import { OpenAPI } from "../core/OpenAPI";
import { request as __request } from "../core/request";
import type { PaginatedBookingOut } from "../models/PaginatedBookingOut";
import type { VehicleBike } from "../models/VehicleBike";
import type { VehicleCar } from "../models/VehicleCar";
import type { VehicleScooter } from "../models/VehicleScooter";

export class VehiclesService {
  /**
   * Get Vehicle Bookings
   * @param vehicleId
   * @returns PaginatedBookingOut Successful Response
   * @throws ApiError
   */
  public static getVehicleBookings(
    vehicleId: string,
  ): CancelablePromise<PaginatedBookingOut> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/api/vehicles/{vehicle_id}/bookings",
      path: {
        vehicle_id: vehicleId,
      },
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Get Vehicle
   * @param vehicleId
   * @returns any Successful Response
   * @throws ApiError
   */
  public static getVehicle(
    vehicleId: string,
  ): CancelablePromise<VehicleBike | VehicleCar | VehicleScooter> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/api/vehicles/{vehicle_id}",
      path: {
        vehicle_id: vehicleId,
      },
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Unlock Vehicle
   * @param vehicleId
   * @returns void
   * @throws ApiError
   */
  public static unlockVehicle(vehicleId: string): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/api/vehicles/{vehicle_id}/unlock",
      path: {
        vehicle_id: vehicleId,
      },
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Lock Vehicle
   * @param vehicleId
   * @returns void
   * @throws ApiError
   */
  public static lockVehicle(vehicleId: string): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/api/vehicles/{vehicle_id}/lock",
      path: {
        vehicle_id: vehicleId,
      },
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }
}
