import { FC } from "react";

import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { InfoCard, Text, colors, media } from "../../../packages/ui";
import { BenefitTermsChecker } from "../BenefitTermsChecker";
import { LayoutContextProvider } from "./LayoutContext";
import { MobileNav, TopNav } from "./Navigation";
import { PageLayoutProps } from "./types";

const PageLayout: FC<PageLayoutProps> = ({ children, pageTitle }) => {
  const { t } = useTranslation();
  const announcements = [t("fleet:announcement.announcement2")].filter(Boolean);

  return (
    <LayoutContextProvider>
      <TopNav pageTitle={pageTitle} />
      <ContentWrapper>
        {announcements.map((announcement, index) => (
          <Banner key={index}>
            <Text text={announcement} />
          </Banner>
        ))}
        <BenefitTermsChecker />
        <ContentBody>{children}</ContentBody>
      </ContentWrapper>
      <MobileNav />
    </LayoutContextProvider>
  );
};

export default PageLayout;

const Banner = styled(InfoCard)`
  margin: 0 2em 2em;
  width: auto;
  box-shadow: none;
  border-left: 0.5em solid #f9696b;
`;

const ContentBody = styled.div`
  max-width: 60em;
  margin: 0 auto;
`;

const ContentWrapper = styled.div`
  position: absolute;
  top: 4em;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
  overflow-y: auto;
  padding: 1.5em;
  z-index: 0;
  width: 100%;
  scroll-behavior: smooth;
  ::-webkit-scrollbar {
    width: 5px;
  }
  ::-webkit-scrollbar-thumb {
    background: ${colors.secondaryColor20RGB};
    border-radius: 10px;
    max-height: 200px;
    opacity: 0.25;
    scroll-behavior: smooth;
    border: 1px solid #ffffffc9;
  }

  ${media.atMobile} {
    bottom: 3em;
    top: 4em;
  }
`;
