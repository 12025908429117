import React, { useState } from "react";

import { useQueryClient } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";

import { useAuthContext } from "../../auth";
import { Flex, Icon } from "../../ui";
import { useUpdateCurrentUser } from "../../user-utils";
import "./LanguageSelect.css";
import enFlag from "./en.png";
import fiFlag from "./fi.png";
import seFlag from "./se.png";

export const LanguageSelect = () => {
  const [open, setOpen] = useState(false);
  const { t, i18n } = useTranslation();

  const updateProfile = useUpdateCurrentUser();
  const { isLoggedIn } = useAuthContext();
  const queryClient = useQueryClient();

  const languages = [
    {
      code: "fi",
      image: fiFlag,
      text: t("language.fi"),
    },
    {
      code: "en",
      image: enFlag,
      text: t("language.en"),
    },
    {
      code: "sv",
      image: seFlag,
      text: t("language.sv"),
    },
  ];

  const currentLanguage = languages.find(
    (language) => language.code === i18n.resolvedLanguage,
  );

  const handleToggle = () => {
    setOpen(!open);
  };

  const handleChoose = (code: string) => {
    i18n.changeLanguage(code);
    handleToggle();
    if (isLoggedIn) {
      updateProfile.mutate(
        { language: code.toUpperCase() as any },
        {
          onSuccess: () => queryClient.invalidateQueries(["currentUser"]),
        },
      );
    }
  };

  if (!currentLanguage) {
    return null;
  }

  return (
    <div className="LanguageSelect">
      <Flex
        align="center"
        noWrap
        onClick={handleToggle}
        className={open === true && "disabled"}
      >
        <Flex.Column size="min">
          <img src={currentLanguage["image"]} alt={currentLanguage["code"]} />
        </Flex.Column>
        <Flex.Column size="min" className="text">
          {currentLanguage["text"]}
        </Flex.Column>
        <Flex.Column size="min">
          <Icon name="arrow_drop_down" />
        </Flex.Column>
      </Flex>
      {open === true && (
        <div className="option">
          {languages
            .filter((language) => language !== currentLanguage)
            .map((language) => {
              return (
                <Flex
                  align="center"
                  className="option"
                  noWrap
                  onClick={() => handleChoose(language.code)}
                  key={language.code}
                >
                  <Flex.Column size="min">
                    <img src={language.image} alt={language.code} />
                  </Flex.Column>
                  <Flex.Column size="min" className="text">
                    {language.text}
                  </Flex.Column>
                </Flex>
              );
            })}
        </div>
      )}
    </div>
  );
};
