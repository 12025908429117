/* generated using openapi-typescript-codegen -- do no edit */

/* istanbul ignore file */

/* tslint:disable */

/* eslint-disable */
import type { CancelablePromise } from "../core/CancelablePromise";
import { OpenAPI } from "../core/OpenAPI";
import { request as __request } from "../core/request";
import type { BikeBenefitContractRead } from "../models/BikeBenefitContractRead";
import type { BikeBenefitOrderRead } from "../models/BikeBenefitOrderRead";
import type { BikeBenefitOrderState } from "../models/BikeBenefitOrderState";
import type { Body_benefit_accept_bike_benefit_order } from "../models/Body_benefit_accept_bike_benefit_order";
import type { OrderDirection } from "../models/OrderDirection";
import type { PaginatedBikeBenefitContractOut } from "../models/PaginatedBikeBenefitContractOut";
import type { PaginatedBikeBenefitOrderOut } from "../models/PaginatedBikeBenefitOrderOut";

export class BenefitService {
  /**
   * Search Bike Benefit Orders
   * @param states
   * @param q
   * @param ids
   * @param skip
   * @param limit
   * @param sortField
   * @param sortDirection
   * @param xFilterIds
   * @returns PaginatedBikeBenefitOrderOut Successful Response
   * @throws ApiError
   */
  public static searchBikeBenefitOrders(
    states?: Array<BikeBenefitOrderState>,
    q?: string,
    ids?: Array<string>,
    skip?: number,
    limit: number = 20,
    sortField?: string,
    sortDirection?: OrderDirection,
    xFilterIds?: string,
  ): CancelablePromise<PaginatedBikeBenefitOrderOut> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/api/bike-benefit-orders",
      headers: {
        "X-Filter-Ids": xFilterIds,
      },
      query: {
        states: states,
        q: q,
        ids: ids,
        skip: skip,
        limit: limit,
        sort_field: sortField,
        sort_direction: sortDirection,
      },
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Get Bike Benefit Order
   * @param vapausCode
   * @returns BikeBenefitOrderRead Successful Response
   * @throws ApiError
   */
  public static getBikeBenefitOrder(
    vapausCode: string,
  ): CancelablePromise<BikeBenefitOrderRead> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/api/bike-benefit-orders/{vapaus_code}",
      path: {
        vapaus_code: vapausCode,
      },
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Accept Bike Benefit Order
   * @param vapausCode
   * @param requestBody
   * @returns BikeBenefitOrderRead Successful Response
   * @throws ApiError
   */
  public static acceptBikeBenefitOrder(
    vapausCode: string,
    requestBody: Body_benefit_accept_bike_benefit_order,
  ): CancelablePromise<BikeBenefitOrderRead> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/api/bike-benefit-orders/{vapaus_code}/accept",
      path: {
        vapaus_code: vapausCode,
      },
      body: requestBody,
      mediaType: "application/json",
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Search Bike Benefit Contracts
   * @param benefitDefinitionId
   * @param q
   * @param ids
   * @param skip
   * @param limit
   * @param sortField
   * @param sortDirection
   * @param xFilterIds
   * @returns PaginatedBikeBenefitContractOut Successful Response
   * @throws ApiError
   */
  public static searchBikeBenefitContracts(
    benefitDefinitionId?: string,
    q?: string,
    ids?: Array<string>,
    skip?: number,
    limit: number = 20,
    sortField?: string,
    sortDirection?: OrderDirection,
    xFilterIds?: string,
  ): CancelablePromise<PaginatedBikeBenefitContractOut> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/api/bike-benefit-contracts",
      headers: {
        "X-Filter-Ids": xFilterIds,
      },
      query: {
        benefit_definition_id: benefitDefinitionId,
        q: q,
        ids: ids,
        skip: skip,
        limit: limit,
        sort_field: sortField,
        sort_direction: sortDirection,
      },
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Get Bike Benefit Contract
   * @param contractId
   * @returns BikeBenefitContractRead Successful Response
   * @throws ApiError
   */
  public static getBikeBenefitContract(
    contractId: string,
  ): CancelablePromise<BikeBenefitContractRead> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/api/bike-benefit-contracts/{contract_id}",
      path: {
        contract_id: contractId,
      },
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }
}
