import { FC } from "react";

import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import styled from "styled-components";

import { FleetRead } from "@vapaus/generated";

import { Button, Card, Text, bunnyImg } from "../../../packages/ui";

type Props = {
  fleet: FleetRead;
};
const FleetItem: FC<Props> = ({ fleet }) => {
  const { t } = useTranslation();

  const image =
    fleet.logo_url || fleet?.organisation?.logo_object?.public_url || bunnyImg;

  return (
    <Wrapper>
      <img src={image} alt="Fleet" />
      <Text className="title" text={fleet?.organisation?.name} />
      <Text className="sub-title" text={fleet.name} />
      <Link to={`/book-a-ride/${fleet.id}`}>
        <Button secondary text={t("fleet:booking.bookNow")} />
      </Link>
    </Wrapper>
  );
};

export default FleetItem;

const Wrapper = styled(Card)`
  border-radius: 10px;

  .CardContent {
    img {
      width: 100px;
      height: 100px;
      border-radius: 10px;
      margin-bottom: 10px;
      object-fit: contain;
    }

    width: 100%;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center !important;

    .title {
      font-size: 16px;
      font-weight: bold;
      margin: 10px 0;
      text-align: center;
    }

    .sub-title {
      font-size: 14px;
      font-weight: 400;
      margin-bottom: 10px;
      text-align: center;
    }

    .Button {
      height: 40px;
    }
  }
`;
