/* generated using openapi-typescript-codegen -- do no edit */

/* istanbul ignore file */

/* tslint:disable */

/* eslint-disable */
import type { CancelablePromise } from "../core/CancelablePromise";
import { OpenAPI } from "../core/OpenAPI";
import { request as __request } from "../core/request";
import type { Body_payments_create_payment_consent } from "../models/Body_payments_create_payment_consent";
import type { Msg } from "../models/Msg";
import type { OrderDirection } from "../models/OrderDirection";
import type { PaginatedPaymentConsentOut } from "../models/PaginatedPaymentConsentOut";
import type { PaginatedPaymentOut } from "../models/PaginatedPaymentOut";
import type { PaymentConsentDetailsRead } from "../models/PaymentConsentDetailsRead";
import type { PaymentConsentStatus } from "../models/PaymentConsentStatus";

export class PaymentsService {
  /**
   * Search Payments
   * @param saleInvoiceId
   * @param q
   * @param ids
   * @param skip
   * @param limit
   * @param sortField
   * @param sortDirection
   * @param xFilterIds
   * @returns PaginatedPaymentOut Successful Response
   * @throws ApiError
   */
  public static searchPayments(
    saleInvoiceId?: string,
    q?: string,
    ids?: Array<string>,
    skip?: number,
    limit: number = 20,
    sortField?: string,
    sortDirection?: OrderDirection,
    xFilterIds?: string,
  ): CancelablePromise<PaginatedPaymentOut> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/api/payments",
      headers: {
        "X-Filter-Ids": xFilterIds,
      },
      query: {
        sale_invoice_id: saleInvoiceId,
        q: q,
        ids: ids,
        skip: skip,
        limit: limit,
        sort_field: sortField,
        sort_direction: sortDirection,
      },
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Search Payment Consents
   * @param status
   * @param q
   * @param ids
   * @param skip
   * @param limit
   * @param sortField
   * @param sortDirection
   * @param xFilterIds
   * @returns PaginatedPaymentConsentOut Successful Response
   * @throws ApiError
   */
  public static searchPaymentConsents(
    status?: Array<PaymentConsentStatus>,
    q?: string,
    ids?: Array<string>,
    skip?: number,
    limit: number = 20,
    sortField?: string,
    sortDirection?: OrderDirection,
    xFilterIds?: string,
  ): CancelablePromise<PaginatedPaymentConsentOut> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/api/payments/consents",
      headers: {
        "X-Filter-Ids": xFilterIds,
      },
      query: {
        status: status,
        q: q,
        ids: ids,
        skip: skip,
        limit: limit,
        sort_field: sortField,
        sort_direction: sortDirection,
      },
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Create Payment Consent
   * @param requestBody
   * @returns PaymentConsentDetailsRead Successful Response
   * @throws ApiError
   */
  public static createPaymentConsent(
    requestBody: Body_payments_create_payment_consent,
  ): CancelablePromise<PaymentConsentDetailsRead> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/api/payments/consents",
      body: requestBody,
      mediaType: "application/json",
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Get Payment Consent
   * @param paymentConsentId
   * @returns PaymentConsentDetailsRead Successful Response
   * @throws ApiError
   */
  public static getPaymentConsent(
    paymentConsentId: string,
  ): CancelablePromise<PaymentConsentDetailsRead> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/api/payments/consents/{payment_consent_id}",
      path: {
        payment_consent_id: paymentConsentId,
      },
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Cancel Payment Consent
   * @param paymentConsentId
   * @returns PaymentConsentDetailsRead Successful Response
   * @throws ApiError
   */
  public static cancelPaymentConsent(
    paymentConsentId: string,
  ): CancelablePromise<PaymentConsentDetailsRead> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/api/payments/consents/{payment_consent_id}/cancel",
      path: {
        payment_consent_id: paymentConsentId,
      },
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Visma Pay Callback
   * @param returnCode
   * @param orderNumber
   * @param settled
   * @param incidentId
   * @param authcode
   * @returns Msg Successful Response
   * @throws ApiError
   */
  public static vismaPayCallback(
    returnCode?: string,
    orderNumber?: string,
    settled?: string,
    incidentId?: string,
    authcode?: string,
  ): CancelablePromise<Msg> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/api/payment-services/visma-pay-callback",
      query: {
        RETURN_CODE: returnCode,
        ORDER_NUMBER: orderNumber,
        SETTLED: settled,
        INCIDENT_ID: incidentId,
        AUTHCODE: authcode,
      },
      errors: {
        400: `Bad Request`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Search Payment Consents
   * @param userId
   * @param q
   * @param ids
   * @param skip
   * @param limit
   * @param sortField
   * @param sortDirection
   * @param xFilterIds
   * @returns PaginatedPaymentConsentOut Successful Response
   * @throws ApiError
   */
  public static searchPaymentConsents1(
    userId?: string,
    q?: string,
    ids?: Array<string>,
    skip?: number,
    limit: number = 20,
    sortField?: string,
    sortDirection?: OrderDirection,
    xFilterIds?: string,
  ): CancelablePromise<PaginatedPaymentConsentOut> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/admin-api/payments/consents",
      headers: {
        "X-Filter-Ids": xFilterIds,
      },
      query: {
        user_id: userId,
        q: q,
        ids: ids,
        skip: skip,
        limit: limit,
        sort_field: sortField,
        sort_direction: sortDirection,
      },
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }
}
