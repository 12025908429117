import React from "react";

import "./Icon.scss";

type IconProps = {
  className?: string;
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
  name: string;
};

export const Icon = ({ className, onClick, name }: IconProps) => {
  let _className = "Icon material-icons";

  if (className) {
    _className += " " + className;
  }

  return (
    <i onClick={onClick} className={_className}>
      {name}
    </i>
  );
};
