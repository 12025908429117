import { UseQueryOptions, useQuery } from "@tanstack/react-query";

import {
  BookingCalculation,
  BookingTermsAndConditionsRead,
  BookingUseType,
  CalculationsService,
  FleetRead,
  FleetsService,
  LocationsService,
  OrderDirection,
  PaginatedFleetOut,
  PaginatedLocationOut,
  PaginatedVehicleOut,
  UsageSettingsRead,
} from "@vapaus/generated";
import type { ApiError } from "@vapaus/generated";

export const useGetFleets = (
  args?: {
    isActive?: boolean;
    q?: string;
    ids?: Array<string>;
    skip?: number;
    limit?: number;
    sortField?: string;
    sortDirection?: OrderDirection;
  },
  options?: Omit<
    UseQueryOptions<PaginatedFleetOut, ApiError>,
    "queryKey" | "queryFn"
  >,
) =>
  useQuery<PaginatedFleetOut, ApiError>(
    ["fleets", args],
    () =>
      FleetsService.getFleets(
        args?.isActive,
        args?.q,
        args?.ids,
        args?.skip,
        args?.limit,
        args?.sortField,
        args?.sortDirection,
      ),
    options,
  );

export const useGetFleetVehicles = (
  id: string,
  options?: Omit<
    UseQueryOptions<PaginatedVehicleOut, ApiError>,
    "queryKey" | "queryFn"
  >,
) =>
  useQuery<PaginatedVehicleOut, ApiError>(
    ["fleets", id, "vehicles"],
    () => FleetsService.getUserFleetVehicles(id),
    options,
  );

export const useGetFleet = (
  id: string,
  options?: Omit<UseQueryOptions<FleetRead, ApiError>, "queryKey" | "queryFn">,
) =>
  useQuery<FleetRead, ApiError>(
    ["fleets", id],
    () => FleetsService.getFleet(id),
    options,
  );

export const useGetFleetLocations = (
  args: {
    fleetId: string;
    q?: string;
    ids?: Array<string>;
    skip?: number;
    limit?: number;
    sortField?: string;
    sortDirection?: OrderDirection;
  },
  options?: Omit<
    UseQueryOptions<PaginatedLocationOut, ApiError>,
    "queryKey" | "queryFn"
  >,
) =>
  useQuery<PaginatedLocationOut, ApiError>(
    ["fleets", args, "locations"],
    () =>
      LocationsService.searchLocation(
        args.fleetId,
        args?.q,
        args?.ids,
        args?.skip,
        args?.limit,
        args?.sortField,
        args?.sortDirection,
      ),
    options,
  );

export const useGetBookingPrice = (
  fleetId: string,
  useType: BookingUseType,
  start_at: string,
  end_at: string,
  options?: Omit<
    UseQueryOptions<BookingCalculation, ApiError>,
    "queryKey" | "queryFn"
  >,
) =>
  useQuery<BookingCalculation, ApiError>(
    ["fleets", fleetId, "price", useType, start_at, end_at],
    () =>
      CalculationsService.getBookingCalculation(
        fleetId,
        useType,
        start_at,
        end_at,
      ),
    options,
  );

export const useGetFleetUsageSettings = (
  fleetId: string,
  options?: Omit<
    UseQueryOptions<UsageSettingsRead, ApiError>,
    "queryKey" | "queryFn"
  >,
) =>
  useQuery<UsageSettingsRead, ApiError>(
    ["fleets", fleetId, "usageSettings"],
    () => FleetsService.getFleetUsageSettings(fleetId),
    options,
  );

export const useGetFleetBookingTermsAndConditions = (
  fleetId: string,
  options?: Omit<
    UseQueryOptions<BookingTermsAndConditionsRead, ApiError>,
    "queryKey" | "queryFn"
  >,
) =>
  useQuery<BookingTermsAndConditionsRead, ApiError>(
    ["fleets", fleetId, "bookingTermsAndConditions"],
    () => FleetsService.getFleetBookingTermsAndConditions(fleetId),
    options,
  );
