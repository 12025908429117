import { Suspense } from "react";

import { Route } from "react-router-dom";

import { Auth } from "../../../packages/auth";
import { ToastProvider } from "../../../packages/uikit";
import LoggedIn from "../LoggedIn/LoggedIn";
import UserTermsPage from "../UserTermsPage";
import "./App.scss";

const App = () => {
  return (
    <div id="App" className="App">
      <Suspense fallback="loading">
        <ToastProvider>
          <Auth
            termsPage={UserTermsPage}
            acceptedTermsKey="accepted_user_terms_id"
            currentTermsKey="current_user_terms_id"
            allowSignUp
          >
            <Route path="*" element={<LoggedIn />} />
          </Auth>
        </ToastProvider>
      </Suspense>
    </div>
  );
};

export default App;
