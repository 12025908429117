import { FC, useState } from "react";

import { useTranslation } from "react-i18next";

import { ApiError } from "@vapaus/generated";
import { parseApiError } from "@vapaus/utils";

import { Button, Dialog, DialogProps } from "../../../../ui";
import { useCurrentUser, useUpdateCurrentUser } from "../../../../user-utils";

const AddPhoneNumber: FC = () => {
  const { t } = useTranslation();

  const [dialog, setDialog] = useState<DialogProps | undefined>();

  const { data: user, refetch } = useCurrentUser();

  const updateUser = useUpdateCurrentUser();

  const handleSubmit = async (phone_number: string) => {
    if (!phone_number) return;
    updateUser.mutate(
      { phone_number },
      {
        onSuccess: () => refetch(),
        onError: (error: any) =>
          setDialog({
            type: "alert",
            title: t("settings:buttons.phone.error"),
            animation: "error",
            description: parseApiError(error as ApiError, t),
            onClose: () => setDialog(undefined),
          }),
      },
    );
  };

  const showEnterPhoneDialog = () => {
    setDialog({
      type: "prompt-text",
      title: t("settings:buttons.phone.newPhone"),
      mandatory: true,
      onClose: () => setDialog(undefined),
      onSubmit: handleSubmit,
      submit: t("settings:buttons.phone.submit"),
    });
  };

  if (user?.phone_number) return null;

  return (
    <>
      <Button
        secondary
        text={t("settings:buttons.phone.addPhone")}
        icon="phone"
        onClick={showEnterPhoneDialog}
      />
      {dialog && (
        <Dialog
          cancel={t("common:dialog.cancel")}
          close={t("common:dialog.close")}
          {...dialog}
        />
      )}
    </>
  );
};

export default AddPhoneNumber;
