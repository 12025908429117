import { UseQueryOptions, useMutation, useQuery } from "@tanstack/react-query";

import {
  ApiError,
  VehicleBike,
  VehicleCar,
  VehicleScooter,
  VehiclesService,
} from "@vapaus/generated";

export type VehicleItemType = VehicleBike | VehicleCar | VehicleScooter | any;

export const useGetVehicle = (
  id: string,
  options?: Omit<
    UseQueryOptions<VehicleItemType | any, ApiError>,
    "queryKey" | "queryFn"
  >,
) =>
  useQuery<VehicleItemType | any, ApiError>(
    ["vehicles", id],
    () => VehiclesService.getVehicle(id),
    options,
  );

export const useLockVehicle = () =>
  useMutation({ mutationFn: VehiclesService.lockVehicle });
export const useUnlockVehicle = () =>
  useMutation({ mutationFn: VehiclesService.unlockVehicle });
