import { useState } from "react";

import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { parseApiError } from "@vapaus/utils";

import {
  AnimatedIcon,
  Button,
  Card,
  Dialog,
  Form,
  Text,
  logo,
} from "../../../packages/ui";
import { Markdown } from "../../../packages/uikit";
import {
  useAcceptUserTerms,
  useCurrentUser,
  useCurrentUserTerms,
} from "../../../packages/user-utils";
import "./UserTermsPage.scss";

export const UserTermsPage = () => {
  const { data: userTerms } = useCurrentUserTerms({
    refetchOnWindowFocus: false,
    cacheTime: 0,
  });
  const acceptUserTerms = useAcceptUserTerms();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [error, setError] = useState<string | null>(null);
  const { error: currentUserError } = useCurrentUser({
    enabled: acceptUserTerms.status === "success",
  });

  const submit = async () => {
    if (acceptUserTerms.isLoading) return;
    try {
      await acceptUserTerms.mutateAsync(userTerms!.id);
    } catch (error: any) {
      setError(parseApiError(error, t));
    }
  };

  if (!userTerms) {
    return (
      <>
        {currentUserError && (
          <Dialog
            type="alert"
            title={t("common:dialog.error")}
            subTitle={t("auth:terms.loadingError")}
            description={parseApiError(currentUserError, t)}
            animation="error"
            onClose={() => navigate("/sign-in")}
          />
        )}
        <Card className="UserTerms">
          <img className="logo" src={logo} alt={t("common:vapaus")} />
          <AnimatedIcon type="spinner1" />
        </Card>
      </>
    );
  }

  return (
    <>
      {error && (
        <Dialog
          type="alert"
          title={t("common:dialog.error")}
          subTitle={t("auth:terms.error")}
          description={error}
          animation="error"
          onClose={() => setError(null)}
        />
      )}
      <div className="UserTerms">
        <Card>
          <img className="logo" src={logo} alt={t("common:vapaus")} />

          <Text type="title" secondary text={t("auth:terms.title")} />
          <Text type="text" tertiary text={t("auth:terms.text")} />

          <Form onSubmit={submit}>
            <div className="terms">
              <Markdown content={userTerms.content} />
            </div>
            <Form.Submit>
              <Button secondary icon="check" text={t("auth:terms.accept")} />
            </Form.Submit>
          </Form>
        </Card>
      </div>
    </>
  );
};
