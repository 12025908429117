/* generated using openapi-typescript-codegen -- do no edit */

/* istanbul ignore file */

/* tslint:disable */

/* eslint-disable */
import type { CancelablePromise } from "../core/CancelablePromise";
import { OpenAPI } from "../core/OpenAPI";
import { request as __request } from "../core/request";
import type { Body_user_invoices_pay_sale_invoice } from "../models/Body_user_invoices_pay_sale_invoice";
import type { OrderDirection } from "../models/OrderDirection";
import type { PaginatedSaleInvoiceOut } from "../models/PaginatedSaleInvoiceOut";
import type { PaymentDetailsRead } from "../models/PaymentDetailsRead";
import type { SaleInvoiceRead } from "../models/SaleInvoiceRead";
import type { SaleInvoiceStatus } from "../models/SaleInvoiceStatus";

export class UserInvoicesService {
  /**
   * Search Sale Invoices
   * @param status
   * @param bikeBenefitOrderId
   * @param bikeBenefitContractId
   * @param q
   * @param ids
   * @param skip
   * @param limit
   * @param sortField
   * @param sortDirection
   * @param xFilterIds
   * @returns PaginatedSaleInvoiceOut Successful Response
   * @throws ApiError
   */
  public static searchSaleInvoices(
    status?: Array<SaleInvoiceStatus>,
    bikeBenefitOrderId?: string,
    bikeBenefitContractId?: string,
    q?: string,
    ids?: Array<string>,
    skip?: number,
    limit: number = 20,
    sortField?: string,
    sortDirection?: OrderDirection,
    xFilterIds?: string,
  ): CancelablePromise<PaginatedSaleInvoiceOut> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/api/sale-invoices",
      headers: {
        "X-Filter-Ids": xFilterIds,
      },
      query: {
        status: status,
        bike_benefit_order_id: bikeBenefitOrderId,
        bike_benefit_contract_id: bikeBenefitContractId,
        q: q,
        ids: ids,
        skip: skip,
        limit: limit,
        sort_field: sortField,
        sort_direction: sortDirection,
      },
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Get Sale Invoice
   * @param saleInvoiceId
   * @returns SaleInvoiceRead Successful Response
   * @throws ApiError
   */
  public static getSaleInvoice(
    saleInvoiceId: string,
  ): CancelablePromise<SaleInvoiceRead> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/api/sale-invoices/{sale_invoice_id}",
      path: {
        sale_invoice_id: saleInvoiceId,
      },
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Pay Sale Invoice
   * @param saleInvoiceId
   * @param requestBody
   * @returns PaymentDetailsRead Successful Response
   * @throws ApiError
   */
  public static paySaleInvoice(
    saleInvoiceId: string,
    requestBody: Body_user_invoices_pay_sale_invoice,
  ): CancelablePromise<PaymentDetailsRead> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/api/sale-invoices/{sale_invoice_id}/pay",
      path: {
        sale_invoice_id: saleInvoiceId,
      },
      body: requestBody,
      mediaType: "application/json",
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }
}
