import clsx from "clsx";

type Props = {
  name: string;
  className?: string;
};

export const Icon = (props: Props) => {
  return (
    <i className={clsx("Icon material-icons", props.className)}>{props.name}</i>
  );
};
