import React, { FC } from "react";

import styled from "styled-components";

import { colors } from "../constants";

interface Props {
  type: "info" | "warning" | "error";
  children: React.ReactNode;
}

export const InfoBox: FC<Props> = ({ type, children }) => {
  const color =
    type === "info"
      ? colors.info
      : type === "warning"
      ? colors.warning
      : colors.danger;
  return (
    <Root color={color}>
      <Content color={color}>{children}</Content>
    </Root>
  );
};

const Root = styled.div<{
  color: string;
}>`
  border-left: 5px solid ${(props) => props.color};
  background-color: ${(props) => props.color};
  border-radius: 4px;
  overflow: hidden;
`;

const Content = styled.div<{
  color: string;
}>`
  padding: 24px;
  background-color: rgba(255, 255, 255, 0.85);
`;
