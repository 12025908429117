/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum BikeBenefitContractState {
  SCHEDULED = "scheduled",
  ACTIVE = "active",
  ACTIVE_PENDING_REVISION = "active_pending_revision",
  ACTIVE_PENDING_CORRECTION = "active_pending_correction",
  ACTIVE_PENDING_REDEMPTION = "active_pending_redemption",
  ACTIVE_PENDING_RETURN = "active_pending_return",
  ENDED = "ended",
  ENDED_PENDING_PAYMENT = "ended_pending_payment",
  CANCELLED = "cancelled",
  CANCELLED_PENDING_PAYMENT = "cancelled_pending_payment",
  INVALIDATED = "invalidated",
}
