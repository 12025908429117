import { FC } from "react";

import { useTranslation } from "react-i18next";

import {
  BikeBenefitContractState,
  BikeBenefitOrderState,
  SaleInvoiceStatus,
  SupportedCountriesEnum,
} from "@vapaus/generated";

import { useAuthContext } from "../../../packages/auth";
import { DetailType, SettingsLayout } from "../../../packages/layouts";
import {
  Button,
  ButtonGroup,
  DownloadUserTermPDF,
  InputProps,
} from "../../../packages/ui";
import { useCurrentUser } from "../../../packages/user-utils";
import { useLayoutContext } from "../../components/PageLayout";
import { MenuType } from "../../components/PageLayout/types";
import {
  useBenefitOrders,
  useSearchBenefitContracts,
} from "../../hooks/benefit";
import { useInvoiceList } from "../../hooks/invoice";
import "./SettingsPage.scss";

const SettingsPage: FC = () => {
  const { setToken } = useAuthContext();
  const { data: user } = useCurrentUser();
  const { currentView } = useLayoutContext();
  const { t } = useTranslation();
  const { data: unpaidSaleInvoices } = useInvoiceList({
    status: [
      SaleInvoiceStatus.OPEN,
      SaleInvoiceStatus.DUE,
      SaleInvoiceStatus.OVERDUE,
    ],
    limit: 1,
  });
  const { data: openOrders } = useBenefitOrders({
    states: [
      BikeBenefitOrderState.DRAFT,
      BikeBenefitOrderState.WAITING_FOR_PAYMENT,
      BikeBenefitOrderState.READY_FOR_DELIVERY,
    ],
    limit: 1,
  });
  const { data: contracts } = useSearchBenefitContracts();
  const openContracts = (contracts?.items || []).filter((contract) =>
    [
      BikeBenefitContractState.SCHEDULED,
      BikeBenefitContractState.ACTIVE,
      BikeBenefitContractState.ACTIVE_PENDING_REVISION,
      BikeBenefitContractState.ACTIVE_PENDING_CORRECTION,
    ].includes(contract.state),
  );
  const isAddressRequired =
    !!unpaidSaleInvoices?.total ||
    !!openOrders?.total ||
    !!openContracts.length;

  const personalInfoForm: InputProps[] = [
    {
      label: t("settings:updateProfile.firstName"),
      name: "first_name",
      type: "text",
      value: user?.first_name,
    },
    {
      label: t("settings:updateProfile.lastName"),
      name: "last_name",
      type: "text",
      value: user?.last_name,
    },
    {
      label: t("settings:updateProfile.employeeNumber"),
      name: "employee_number",
      type: "text",
      value: user?.employee_number,
    },
    {
      label: t("settings:updateProfile.company"),
      name: "company",
      type: "text",
      value: user?.company,
    },
    {
      label: t("settings:updateProfile.costCenter"),
      name: "cost_center",
      type: "text",
      value: user?.cost_center,
    },
    {
      label: t("settings:updateProfile.address"),
      name: "address",
      type: "text",
      value: user?.address,
      required: isAddressRequired,
    },
    {
      label: t("settings:updateProfile.postCode"),
      name: "post_code",
      type: "text",
      value: user?.post_code,
      required: isAddressRequired,
    },
    {
      label: t("settings:updateProfile.city"),
      name: "city",
      type: "text",
      value: user?.city,
      required: isAddressRequired,
    },
    {
      label: t("settings:updateProfile.country"),
      name: "country",
      type: "select",
      value: user?.country,
      data: [
        {
          id: null,
          name: "",
        },
        {
          id: SupportedCountriesEnum.FI,
          name: t("common:country.finland"),
        },
        {
          id: SupportedCountriesEnum.SE,
          name: t("common:country.sweden"),
        },
      ],
      disabled: true,
    },
  ];
  // TODO: Inquire about the allow other user to see this info field,
  // as it is not in the response from the backend

  return (
    <>
      <SettingsLayout
        title={t("common:settings")}
        personalInfoForm={personalInfoForm}
        personalDetails={[
          DetailType.Email,
          DetailType.Phone,
          DetailType.SSN,
          DetailType.Payment,
          ...(currentView === MenuType.fleet
            ? [DetailType.FleetAccessCode]
            : []),
        ]}
      />
      <ButtonGroup className="bottomButtons">
        <Button
          secondary
          icon="logout"
          onClick={() => setToken("")}
          text={t("common:logout", { name: user?.full_name })}
        />
        <DownloadUserTermPDF />
      </ButtonGroup>
    </>
  );
};

export default SettingsPage;
