import { FC } from "react";

import { useGetFleetLocations } from "../../../hooks/fleet";
import { useGetVehicle } from "../../../hooks/vehicle";

type Props = {
  locationId: string;
  vehicleId: string;
};

const LocationName: FC<Props> = ({ locationId, vehicleId }) => {
  // TODO: In the future, we should either have endpoint to get
  // location by id or we should include fleet_id in booking response.
  // When that happens, we can remove this hook and get location by ID instead.
  const { data: vehicleData } = useGetVehicle(vehicleId);
  const { data } = useGetFleetLocations(
    {
      fleetId: vehicleData?.fleet_id || "",
      ids: [locationId],
    },
    {
      enabled: !!vehicleData?.fleet_id,
    },
  );
  const location = data?.items?.[0] || null;
  return <>{location?.name || "-"}</>;
};

export default LocationName;
