import { FC, useState } from "react";

import { useTranslation } from "react-i18next";

import { PasswordChange } from "@vapaus/generated";
import { parseApiError } from "@vapaus/utils";

import { useAuthContext } from "../../auth";
import { Button, Card, Dialog, DialogProps, Form } from "../../ui";
import { useChangeUserPassword } from "../../user-utils";

const ChangePassword: FC = () => {
  const { t } = useTranslation();
  const [dialog, setDialog] = useState<DialogProps | undefined>();
  const changePassword = useChangeUserPassword();
  const { setToken } = useAuthContext();

  const handleSubmit = async (data: PasswordChange) => {
    if (data.new_password !== data.new_password_confirmation) {
      setDialog({
        type: "alert",
        title: t("common:dialog.error"),
        subTitle: t("settings:changePassword.title"),
        description: t("settings:changePassword.passwordsDoesNotMatch"),
        animation: "error",
        onClose: () => setDialog(undefined),
      });
      return;
    }

    changePassword.mutate(data, {
      onSuccess: (data) => {
        setToken(data.access_token);
        setDialog({
          type: "alert",
          title: t("common:dialog.allDone"),
          subTitle: t("settings:changePassword.title"),
          description: t("settings:changePassword.success"),
          animation: "success",
          onClose: () => setDialog(undefined),
        });
      },
      onError: (error: any) => {
        setDialog({
          type: "alert",
          title: t("common:dialog.error"),
          subTitle: t("settings:changePassword.title"),
          description: parseApiError(error, t),
          animation: "error",
          onClose: () => setDialog(undefined),
        });
      },
    });
  };

  return (
    <div className="ChangePassword">
      {dialog && <Dialog {...dialog} />}
      <Card title={t("settings:changePassword.title")}>
        <Form onSubmit={(data: any) => handleSubmit(data as PasswordChange)}>
          <Form.Input
            required
            type="password"
            name="old_password"
            label={t("settings:changePassword.oldPassword")}
          />
          <Form.Input
            required
            type="password"
            name="new_password"
            label={t("settings:changePassword.password")}
          />
          <Form.Input
            required
            type="password"
            name="new_password_confirmation"
            label={t("settings:changePassword.password2")}
          />
          <Form.Submit>
            <Button secondary text={t("settings:changePassword.submit")} />
          </Form.Submit>
        </Form>
      </Card>
    </div>
  );
};

export default ChangePassword;
