import { FC, useState } from "react";

import { useTranslation } from "react-i18next";

import { ApiError } from "@vapaus/generated";
import { parseApiError } from "@vapaus/utils";

import { Dialog, DialogProps, Text } from "../../../../ui";
import { useCurrentUser, useUpdateCurrentUser } from "../../../../user-utils";
import VerificationCard from "../../VerificationCard";
import { DetailType } from "../../types";

const FleetAccessCodeDetail: FC = () => {
  const { t } = useTranslation();

  const [dialog, setDialog] = useState<DialogProps | undefined>();

  const { data: user, refetch } = useCurrentUser();
  const updateUser = useUpdateCurrentUser();

  const handleRemoveFleetAccessCode = async (fleet_access_code: string) => {
    if (!fleet_access_code || !user || !user.fleet_access_codes) return;

    updateUser.mutate(
      {
        fleet_access_codes: user.fleet_access_codes.filter(
          (code) => code !== fleet_access_code,
        ),
      },
      {
        onSuccess: () => refetch(),
        onError: (error: any) =>
          setDialog({
            type: "alert",
            title: t("settings:fleetAccessCode.remove.error"),
            animation: "error",
            description: parseApiError(error as ApiError, t),
            onClose: () => setDialog(undefined),
          }),
      },
    );
  };

  return (
    <>
      {dialog && (
        <Dialog
          cancel={t("common:dialog.cancel")}
          close={t("common:dialog.close")}
          {...dialog}
        />
      )}
      {!user || !user.fleet_access_codes ? null : user.fleet_access_codes
          .length > 0 ? (
        user.fleet_access_codes.map((code) => (
          <VerificationCard
            key={code}
            type={DetailType.FleetAccessCode}
            title={code}
            canDelete
            onDelete={() => handleRemoveFleetAccessCode(code)}
          />
        ))
      ) : (
        <Text text={t("settings:fleetAccessCode.empty")} />
      )}
    </>
  );
};

export default FleetAccessCodeDetail;
