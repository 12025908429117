import React from "react";

import "./Flex.scss";

type FlexProps = {
  className?: string | false;
  direction?: "column" | "row";
  align?: "top" | "bottom" | "center" | "baseline" | "flex-end";
  noWrap?: boolean;
  style?: React.CSSProperties;
  onClick?: React.MouseEventHandler<HTMLElement>;
  children: React.ReactNode;
};

export const Flex = ({
  className,
  direction,
  align,
  noWrap,
  style,
  onClick,
  children,
}: FlexProps) => {
  let _className = "Flex";

  if (className) {
    _className += " " + className;
  }

  if (direction) {
    _className += " direction-" + direction;
  }

  if (align) {
    _className += " align-" + align;
  }

  if (noWrap) {
    _className += " nowrap";
  }

  return (
    <div style={style} onClick={onClick} className={_className}>
      {children}
    </div>
  );
};

type FlexColumnProps = {
  className?: string;
  size?: "min" | "max";
  onClick?: React.MouseEventHandler<HTMLElement>;
  children?: React.ReactNode;
  style?: React.CSSProperties;
};

Flex.Column = ({
  className,
  size,
  onClick,
  children,
  style,
}: FlexColumnProps) => {
  let _className = "FlexColumn";

  if (className) {
    _className += " " + className;
  }

  if (size) {
    _className += " size-" + size;
  }

  return (
    <div onClick={onClick} className={_className} style={style}>
      {children}
    </div>
  );
};
