import { marked } from "marked";
import styled from "styled-components";

import { fontSizes } from "../constants";

type MarkdownProps = {
  content: string;
};

export const Markdown = ({ content }: MarkdownProps) => {
  return <Content dangerouslySetInnerHTML={{ __html: marked(content) }} />;
};

const Content = styled.div`
  text-align: justify;
  strong {
    font-weight: 700;
  }
  del {
    text-decoration: line-through;
  }
  em {
    font-style: italic;
  }
  ul {
    list-style-type: disc;
    margin-left: 16px;
    margin-bottom: 16px;
  }
  h1 {
    font-size: ${fontSizes.heading1};
  }
  h2 {
    font-size: ${fontSizes.heading2};
  }
  h3 {
    font-size: ${fontSizes.heading3};
  }
  h4 {
    font-size: ${fontSizes.heading4};
  }
  h5 {
    font-size: ${fontSizes.heading5};
  }
  h6 {
    font-size: ${fontSizes.heading6};
  }
  p {
    font-size: ${fontSizes.body1};
    margin-bottom: 16px;
  }
`;
