import { useEffect, useState } from "react";

import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";

import { parseApiError } from "@vapaus/utils";

import { Dialog } from "../../ui";
import { PasswordInput } from "../../uikit";
import { useAuthContext } from "../context/authContext";
import { useResetPassword } from "../hooks/auth";
import {
  AuthLayout,
  AuthLayoutButton,
  AuthLayoutFooterLink,
} from "./AuthLayout";

export const ResetPasswordPage = () => {
  const [successToken, setSuccessToken] = useState<string | undefined>();
  const [error, setError] = useState<string | null>(null);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const resetPassword = useResetPassword();
  const { setToken } = useAuthContext();
  const token = searchParams.get("token");
  const email = searchParams.get("email");

  const {
    register,
    handleSubmit,
    formState: { isValid },
  } = useForm();

  useEffect(() => {
    if (!token || !email) navigate("/sign-in");
  }, [token, email]);

  const handleResetPassword = async ({ new_password }: any) => {
    if (!token || !email) return; // This shouldn't happen as we have a hook before to prevent that
    try {
      const res = await resetPassword.mutateAsync({
        email,
        token,
        new_password,
      });
      setToken(res.access_token);
      setSuccessToken(res.access_token);
    } catch (error: any) {
      setError(parseApiError(error, t));
    }
  };

  return (
    <AuthLayout
      title={t("auth:resetPassword.title")}
      description={t("auth:resetPassword.text")}
    >
      {successToken && (
        <Dialog
          type="alert"
          title={t("common:dialog.done")}
          subTitle={t("auth:resetPassword.title")}
          description={t("auth:resetPassword.success")}
          animation="success"
          onClose={() => setSuccessToken(undefined)}
        />
      )}
      {error && (
        <Dialog
          type="alert"
          title={t("common:dialog.error")}
          subTitle={t("auth:resetPassword.error")}
          description={error}
          animation="error"
          onClose={() => setError(null)}
        />
      )}

      <form onSubmit={handleSubmit(handleResetPassword)}>
        <PasswordInput
          autoFocus
          required
          label={t("auth:resetPassword.password")}
          {...register("new_password", { required: true })}
        />
        <AuthLayoutButton
          disabled={!isValid}
          type="submit"
          isLoading={resetPassword.isLoading}
        >
          {t("auth:resetPassword.submit")}
        </AuthLayoutButton>
      </form>

      <AuthLayoutFooterLink to="/sign-in">
        {t("auth:link.signIn")}
      </AuthLayoutFooterLink>
      <AuthLayoutFooterLink to="/forgot-password">
        {t("auth:link.forgotPassword")}
      </AuthLayoutFooterLink>
    </AuthLayout>
  );
};
