import { FC, useState } from "react";

import { useQueryClient } from "@tanstack/react-query";
import { DateTime } from "luxon";
import { useTranslation } from "react-i18next";

import { BookingRead, BookingStatus } from "@vapaus/generated";
import { parseApiError } from "@vapaus/utils";

import { Button, Dialog, DialogProps } from "../../../../packages/ui";
import { useCancelBooking } from "../../../hooks/booking";

type Props = {
  booking: BookingRead;
};

const CancelBookingButton: FC<Props> = ({ booking }) => {
  const { t } = useTranslation();

  const [dialog, setDialog] = useState<DialogProps | undefined>(undefined);

  const cancelBooking = useCancelBooking();
  const queryClient = useQueryClient();

  const confirmCancelation = () => {
    cancelBooking.mutate(booking.id, {
      onSuccess: () => {
        queryClient.invalidateQueries(["bookings"]);
        setDialog({
          type: "alert",
          animation: "success",
          title: t("fleet:booking.cancellation.cancelBookingSuccess"),
          description: t(
            "fleet:booking.cancellation.cancelBookingSuccessDescription",
          ),
          onClose: () => setDialog(undefined),
        });
      },
      onError: (error: any) => {
        setDialog({
          type: "alert",
          animation: "error",
          title: t("fleet:booking.cancellation.cancelBookingFailed"),
          description: parseApiError(error, t),
          onClose: () => setDialog(undefined),
        });
      },
    });
  };

  const showCancelDialogue = () => {
    setDialog({
      type: "confirm",
      title: t("fleet:booking.cancellation.cancelBooking"),
      description: t("fleet:booking.cancellation.cancelBookingDescription"),
      onClose: () => setDialog(undefined),
      onConfirm: () => {
        setDialog(undefined);
        confirmCancelation();
      },
    });
  };

  const canCancel =
    booking.status === BookingStatus.CONFIRMED &&
    DateTime.fromISO(booking.start_at).diffNow().milliseconds > 0;

  if (!canCancel) {
    return null;
  }

  return (
    <>
      {dialog && <Dialog {...dialog} />}
      <Button
        secondary
        text={t("fleet:booking.cancelBooking")}
        onClick={showCancelDialogue}
      />
    </>
  );
};

export default CancelBookingButton;
