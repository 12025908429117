/* generated using openapi-typescript-codegen -- do no edit */

/* istanbul ignore file */

/* tslint:disable */

/* eslint-disable */
import type { CancelablePromise } from "../core/CancelablePromise";
import { OpenAPI } from "../core/OpenAPI";
import { request as __request } from "../core/request";

export class EIdentService {
  /**
   * Post E Ident
   * @param ssn
   * @param redirectUriPath
   * @returns any Successful Response
   * @throws ApiError
   */
  public static postEIdent(
    ssn?: string,
    redirectUriPath?: string,
  ): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/api/eident",
      query: {
        ssn: ssn,
        redirect_uri_path: redirectUriPath,
      },
      errors: {
        400: `Bad Request`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * E Ident Return
   * @param code
   * @param state
   * @returns void
   * @throws ApiError
   */
  public static eIdentReturn(
    code: string,
    state: string,
  ): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/api/eident/return",
      query: {
        code: code,
        state: state,
      },
      errors: {
        307: `Successful Response`,
        400: `Bad Request`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }
}
