import { css } from "styled-components";

export const durations = {
  normal: "120ms",
};

export const easing = "cubic-bezier(0.4, 0, 0.2, 1)";

export const makeTransition = (property: string) => {
  return css`
    transition-property: ${property};
    transition-timing-function: ${easing};
    transition-duration: ${durations.normal};
  `;
};
