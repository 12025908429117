import { InputHTMLAttributes, forwardRef } from "react";

import styled from "styled-components";

import { colors } from "../constants";

export type BaseInputProps = InputHTMLAttributes<HTMLInputElement>;

export const BaseInput = forwardRef<HTMLInputElement, BaseInputProps>(
  (props, ref) => {
    return <Input {...props} ref={ref} />;
  },
);

const Input = styled.input`
  width: 100%;
  height: 48px;
  border: 1px solid ${colors.borderGray};
  border-radius: 4px;
  padding: 0 1em;
  font-size: 16px;
  font-family: inherit;
  color: ${colors.darkGray};
  &::placeholder {
    color: ${colors.gray};
  }
`;
