import { FC } from "react";

import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { Button, Card, Text, bunnyImg } from "../../../packages/ui";
import { VehicleItemType } from "../../hooks/vehicle";

type Props = {
  selectedVehicle: VehicleItemType;
  onClick: () => void;
  disableButton?: boolean;
};

const VehicleItem: FC<Props> = ({
  selectedVehicle,
  onClick,
  disableButton,
}) => {
  const { t } = useTranslation();
  const vehicle = selectedVehicle?.[selectedVehicle?.vehicle_type];

  return (
    <Wrapper>
      <img src={vehicle?.picture_url || bunnyImg} alt="picture" />
      <Text className="title" text={selectedVehicle.name} />
      <Text
        className="sub-title"
        text={vehicle ? `${vehicle.manufacturer}, ${vehicle.model}` : ""}
      />
      <Button
        secondary
        text={t("fleet:booking.bookNow")}
        onClick={onClick}
        disabled={disableButton}
      />
    </Wrapper>
  );
};

export default VehicleItem;

const Wrapper = styled(Card)`
  border-radius: 10px;

  .CardContent {
    img {
      width: 100px;
      height: 100px;
      border-radius: 10px;
      margin-bottom: 10px;
      background-color: #f2f2f2;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 10px;
      object-fit: contain;
      padding: 0.5em;
    }

    width: 100%;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center !important;

    .title {
      font-size: 16px;
      font-weight: bold;
      margin: 10px 0;
      text-align: center;
    }

    .sub-title {
      font-size: 14px;
      font-weight: 400;
      margin-bottom: 10px;
      text-align: center;
    }

    .Button {
      height: 40px;
    }
  }
`;
