import { FC, useEffect, useState } from "react";

import { useQueryClient } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { useLocation, useSearchParams } from "react-router-dom";

import { ApiError } from "@vapaus/generated";
import { parseApiError } from "@vapaus/utils";

import { Button, Dialog, DialogProps } from "../../../../ui";
import { useToast } from "../../../../uikit";
import { useCurrentUser, useEIdent } from "../../../../user-utils";

const validateSSN = (ssn: string) => {
  return /^(0[1-9]|[12]\d|3[01])(0[1-9]|1[0-2])([5-9]\d\+|\d\d[-UVWXY]|[012]\d[ABCDEF])\d{3}[\dA-Z]$/.test(
    ssn,
  );
};

type AddSSNProps = {
  skipConfirmation?: boolean;
};

export const AddSSN: FC<AddSSNProps> = ({ skipConfirmation }) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const [dialog, setDialog] = useState<DialogProps | undefined>();
  const { data: user } = useCurrentUser();
  const [searchParams, setSearchParams] = useSearchParams();
  const eIdent = useEIdent();
  const toast = useToast();
  const location = useLocation();

  useEffect(() => {
    if (searchParams.get("verifySuccess")) {
      toast.success(t("settings:verifyIdentity.success"));
      setSearchParams();
    }
  }, [searchParams, setSearchParams, toast]);

  const eIdentMutate = (ssn?: string) => {
    const redirectUriPath = location.pathname;
    eIdent.mutate(
      { ssn, redirectUriPath },
      {
        onSuccess: ({ request_url, success }) => {
          if (request_url) {
            window.location.replace(request_url);
          } else if (success) {
            // In this case we are not in production, we can refresh the user directly
            queryClient.invalidateQueries(["currentUser"]);
            toast.success(t("settings:verifyIdentity.success"));
          }
        },
        onError: (error: any) => {
          setDialog({
            type: "alert",
            title: t("settings:buttons.ssn.error"),
            animation: "error",
            description: parseApiError(error as ApiError, t),
            onClose: () => setDialog(undefined),
          });
        },
      },
    );
  };

  const handleSubmitSSN = async (ssn: string) => {
    if (!ssn || !validateSSN(ssn)) {
      setDialog({
        type: "alert",
        title: t("settings:buttons.ssn.error"),
        animation: "error",
        description: t("settings:buttons.ssn.invalidSSN"),
        onClose: handleAddSSNClick,
      });
      return;
    }

    eIdentMutate(ssn);
  };

  const handleAddSSNClick = () => {
    // If we are on prod or staging we call directly the endpoint that will redirect to E Ident strong auth service
    // Else we open a popup to let the user enter their SSN which will be saved in the backend directly, skipping the strong auth
    if (
      import.meta.env.VITE_ENV &&
      ["staging", "prod"].includes(import.meta.env.VITE_ENV)
    ) {
      if (skipConfirmation) {
        eIdentMutate();
      } else {
        setDialog({
          type: "confirm",
          title: t("settings:verifyIdentity.confirm.title"),
          description: t("settings:verifyIdentity.confirm.description"),
          onClose: () => setDialog(undefined),
          onConfirm: eIdentMutate,
          confirm: t("settings:verifyIdentity.confirm.submit"),
        });
      }
    } else {
      setDialog({
        type: "prompt-text",
        title: t("settings:buttons.ssn.newSSN"),
        mandatory: true,
        onClose: () => setDialog(undefined),
        onSubmit: handleSubmitSSN,
        submit: t("settings:buttons.ssn.submit"),
      });
    }
  };

  if (user?.ssn) return null;

  return (
    <>
      <Button
        secondary
        text={t("settings:verifyIdentity.start")}
        icon="verified_user"
        onClick={handleAddSSNClick}
      />
      {dialog && (
        <Dialog
          cancel={t("common:dialog.cancel")}
          close={t("common:dialog.close")}
          {...dialog}
        />
      )}
    </>
  );
};
