import React from "react";

import styled from "styled-components";

import { Icon } from "../Icon";
import { Heading4 } from "../Typography";
import { colors } from "../constants";

interface Props {
  icon: string;
  text: string;
  badgeCount?: number;
  onClick?: () => void;
}

export const TileButton: React.FC<Props> = ({
  icon,
  text,
  badgeCount,
  onClick,
}) => {
  return (
    <Button onClick={onClick}>
      <Left>
        <StyledIcon name={icon} />
        <Heading4 fontWeight="bold" color="textColor">
          {text}
        </Heading4>
      </Left>
      <BadgeCount count={badgeCount || 0}>{badgeCount}</BadgeCount>
    </Button>
  );
};

const Button = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 24px;
  border: none;
  border-radius: 10px;
  background-color: ${colors.white};
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
  &:hover {
    background-color: ${colors.borderGray};
  }
  width: 100%;
`;

const BadgeCount = styled.div`
  display: flex;
  visibility: ${(props: { count: number }) =>
    props.count > 0 ? "visible" : "hidden"};
  background-color: ${colors.primary};
  color: ${colors.white};
  font-weight: bold;
  border-radius: 12px;
  font-size: 16px;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
`;

const Left = styled.div`
  display: flex;
  align-items: center;
`;

const StyledIcon = styled(Icon)`
  margin-right: 16px;
  font-size: 27px;
  color: ${colors.darkGray};
`;
