import { FC } from "react";

import styled from "styled-components";

import { Heading1, Heading3 } from "../Typography";
import { colors } from "../constants";

interface CardProps {
  className?: string;
  id?: string;
  title?: string;
  subtitle?: string;
  children?: React.ReactNode;
  headerAction?: React.ReactNode;
}

export const Card: FC<CardProps> = ({
  className,
  title,
  subtitle,
  children,
  id,
  headerAction,
}) => {
  return (
    <Root className={className} id={id}>
      <Header>
        <CardTitle>
          {title && <Heading1 fontWeight="bold">{title}</Heading1>}
          {subtitle && <Heading3 color="textColor">{subtitle}</Heading3>}
        </CardTitle>
        {headerAction}
      </Header>
      {title || subtitle ? <Content>{children}</Content> : children}
    </Root>
  );
};

const Root = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${colors.white};
  border-radius: 10px;
  padding: 32px;
  width: 100%;
  margin-bottom: 32px;
`;

const Content = styled.div`
  margin-top: 16px;
`;

const CardTitle = styled.div``;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
