/* generated using openapi-typescript-codegen -- do no edit */

/* istanbul ignore file */

/* tslint:disable */

/* eslint-disable */
import type { CancelablePromise } from "../core/CancelablePromise";
import { OpenAPI } from "../core/OpenAPI";
import { request as __request } from "../core/request";
import type { UserTerms } from "../models/UserTerms";

export class UserTermsService {
  /**
   * Current User Terms
   * @returns UserTerms Successful Response
   * @throws ApiError
   */
  public static currentUserTerms(): CancelablePromise<UserTerms> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/api/user-terms/current",
      errors: {
        400: `Bad Request`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }
}
