import { useEffect, useState } from "react";

import { isEqual, isUndefined } from "lodash-es";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { parseApiError } from "@vapaus/utils";

import { Button, Card, Dialog, DialogProps, PageLoading } from "../../ui";
import { CheckboxInput, colors } from "../../uikit";
import { NotificationPreferencesProps } from "./types";

function NotificationsPreferences<NotificationCategory>({
  categories,
  selectedCategories: initialSelectedCategories,
  refetchSelectedCategories,
  saveOptedOutCategories,
}: NotificationPreferencesProps<NotificationCategory>) {
  const { t } = useTranslation();

  const [dialog, setDialog] = useState<DialogProps | undefined>();

  const handleSavePreferences = (data: any) => {
    if (isUndefined(selectedCategories)) return;

    const disabledCategories = categories
      .map((c) => c.id)
      .filter((c) => !selectedCategories.includes(c));

    saveOptedOutCategories(disabledCategories, {
      onSuccess: () => {
        refetchSelectedCategories();
        setDialog({
          type: "alert",
          title: t("common:dialog.allDone"),
          description: t("settings:notificationsPreferences.success"),
          animation: "success",
          onClose: () => setDialog(undefined),
        });
      },
      onError: (error: any) => {
        setDialog({
          type: "alert",
          title: t("common:dialog.error"),
          description: parseApiError(error, t),
          animation: "error",
          onClose: () => setDialog(undefined),
        });
      },
    });
  };

  const [selectedCategories, setSelectedCategories] =
    useState<NotificationCategory[]>();

  useEffect(() => {
    if (isUndefined(selectedCategories))
      setSelectedCategories(initialSelectedCategories);
  }, [setSelectedCategories, initialSelectedCategories]);

  if (isUndefined(selectedCategories)) return <PageLoading />;

  return (
    <Card
      title={t("settings:updateNotificationsPreferences.title")}
      subTitle={t("settings:notificationsPreferences.subtitle")}
      margin="normal"
    >
      {dialog && <Dialog {...dialog} />}
      {categories.map((entry) => (
        <CheckboxInput
          key={entry.id as string}
          checked={selectedCategories.includes(entry.id)}
          onChange={(e) =>
            selectedCategories.includes(entry.id)
              ? setSelectedCategories(
                  selectedCategories.filter((c) => c !== entry.id),
                )
              : setSelectedCategories([...selectedCategories, entry.id])
          }
          text={
            <>
              <OptionLabel>{entry.label}</OptionLabel>
              <OptionDescription>{entry.description}</OptionDescription>
            </>
          }
        />
      ))}
      <Button
        secondary
        text={t("settings:notificationsPreferences.submit")}
        disabled={isEqual(
          initialSelectedCategories?.sort(),
          selectedCategories.sort(),
        )}
        onClick={handleSavePreferences}
      />
    </Card>
  );
}

const OptionLabel = styled.span`
  margin-top: 6px;
  margin-bottom: 10px;
  display: block;
  width: max-content;
  font-weight: bold;
  color: ${colors.darkGray};
`;

const OptionDescription = styled.span`
  display: inline-block;
  width: max-content;
  color: ${colors.gray};
`;

export default NotificationsPreferences;
