import React, { FC } from "react";

import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import styled, { AnyStyledComponent } from "styled-components";

import { LanguageSelect } from "../../i18n";
import { logo } from "../../ui";
import { BaseButton, Heading1, Subtitle1, colors } from "../../uikit";

export const AuthLayout: FC<{
  children: React.ReactNode;
  title: string;
  description: string;
}> = ({ children, title, description }) => {
  const { t } = useTranslation();
  return (
    <Root>
      <Container>
        <Logo src={logo} alt={t("common:vapaus")} />
        <Heading1 color="primary" spacing="md" fontWeight="bold">
          {title}
        </Heading1>
        <Subtitle1 color="textColor" spacing="lg">
          {description}
        </Subtitle1>
        {children}
        <LanguageSelectContainer>
          <LanguageSelect />
        </LanguageSelectContainer>
      </Container>
    </Root>
  );
};

const Logo = styled.img`
  width: 190px;
  margin-bottom: 2em;
`;

const Container = styled.div`
  padding: 50px;
  border-radius: 8px;
  background: ${colors.white};
  width: 480px;
  text-align: center;

  @media (max-width: 768px) {
    width: 100%;
    padding: 50px 20px;
  }
`;

const Root = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 768px) {
    background: ${colors.white};
    align-items: flex-start;
  }

  @media (max-height: 650px) {
    align-items: flex-start;
  }
`;

const LanguageSelectContainer = styled.div`
  & > div:first-child {
    margin-top: 1em;
    font-size: 16px;
  }
`;

export const AuthLayoutFooterLink = styled(Link as AnyStyledComponent)`
  color: ${colors.primary};
  font-size: 16px !important;
  font-weight: 700;
`;

export const AuthLayoutButton = styled(BaseButton as AnyStyledComponent)`
  width: 100%;
  margin-bottom: 2em;
  margin-top: 1em;
  text-transform: uppercase;
  font-weight: 700;
`;
