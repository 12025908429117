export const colors = {
  primary: "#f9696b", // vapaus primary color = light red
  primaryDark: "#f93e41", // vapaus primary dark color = light red
  primaryLight: "#fd9d9f", // vapaus primary light color = light
  secondary: "#6c757d", // vapaus secondary color = dark gray
  success: "#28a745", // vapaus success color = light green
  danger: "#dc3545", // vapaus danger color = light red
  warning: "#ffc107", // vapaus warning color = light yellow
  info: "#17a2b8", // vapaus info color = light blue
  dark: "#343a40", // vapaus dark color = dark gray
  grayLight: "#f8f9fa", // vapaus light color = light gray
  light: "#f8f9fa", // vapaus light color = light gray
  lightGray: "#ecf0f2", // vapaus light color = light gray
  gray: "#adadaf",
  grayDark: "#76777A", // vapaus border color = dark gray
  darkGray: "#76777A", // vapaus border color = dark gray
  white: "#fff", // vapaus white color = white
  black: "#000", // vapaus black color = black
  textColor: "#76777A", // vapaus text color = gray
  borderGray: "#e4e4e4", // vapaus border color = light gray
  background: "#ecf0f2",
  backgroundSecondary: "#F7F7F7",
};

export const shadows = {
  normal: "4px 4px 30px rgba(0, 0, 0, 0.1)",
};
