import { HTMLAttributeAnchorTarget } from "react";

import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import styled from "styled-components";

import { Spacings, makeSpacing } from "../constants";

type ExternalLinkProps = {
  href: string;
  rel?: string;
  target?: HTMLAttributeAnchorTarget;
  children: string;
  spacing?: Spacings;
};

export const ExternalLink = ({
  href,
  rel = "noreferrer",
  target = "_blank",
  children,
  spacing,
}: ExternalLinkProps) => {
  return (
    <AStyled href={href} rel={rel} target={target} spacing={spacing}>
      <OpenInNewIcon />
      <span>{children}</span>
    </AStyled>
  );
};

const AStyled = styled.a<Omit<ExternalLinkProps, "children">>`
  display: flex;
  align-items: center;
  gap: 8px;
  ${(props) => makeSpacing(props.spacing)}
`;
