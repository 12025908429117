import { FC, useState } from "react";

import { DateTime } from "luxon";
import { Trans, useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import invariant from "tiny-invariant";

import {
  BookingStatus,
  BookingUseType,
  Currency,
  LocationRead,
  VehicleBike,
  VehicleCar,
  VehicleScooter,
} from "@vapaus/generated";
import { useCurrencyFormat, useDateFormat } from "@vapaus/i18n";
import { parseApiError, useEnumEntries } from "@vapaus/utils";

import {
  Button,
  ButtonGroup,
  Card,
  Dialog,
  DialogProps,
  Form,
  Modal,
  Pair,
  Text,
} from "../../../packages/ui";
import { useCurrentUser } from "../../../packages/user-utils";
import { useCreateBooking } from "../../hooks/booking";
import {
  useGetBookingPrice,
  useGetFleet,
  useGetFleetBookingTermsAndConditions,
  useGetFleetUsageSettings,
} from "../../hooks/fleet";

type Props = {
  rideDetails: {
    pickupLocation: LocationRead | undefined;
    pickupDate: string;
    pickupTime: string;
    returnDate: string;
    returnTime: string;
    useType: BookingUseType;
  };
  durationString: string;
  vehicle: VehicleBike | VehicleCar | VehicleScooter;
  onClose: () => void;
};

const BookingConfirmation: FC<Props> = ({
  rideDetails: {
    pickupLocation,
    pickupDate,
    pickupTime,
    returnDate,
    returnTime,
    useType,
  },
  durationString,
  vehicle,
  onClose,
}) => {
  const { t } = useTranslation();
  const { fleetId } = useParams<{ fleetId: string }>();
  const { data: user } = useCurrentUser();
  const { data: fleet } = useGetFleet(fleetId || "", {
    enabled: !!fleetId,
  });

  const { data: usageSettings } = useGetFleetUsageSettings(fleetId || "", {
    enabled: !!fleetId,
  });

  const { data: termsAndConditions } = useGetFleetBookingTermsAndConditions(
    fleetId || "",
    {
      enabled: !!fleetId,
    },
  );

  const { getEnumLabel } = useEnumEntries("BookingUseType");

  const startAt = DateTime.fromFormat(
    `${pickupDate} ${pickupTime}`,
    "yyyy-MM-dd HH:mm",
  ).toISO();

  const endAt = DateTime.fromFormat(
    `${returnDate} ${returnTime}`,
    "yyyy-MM-dd HH:mm",
  ).toISO();

  const { data: bookingCalculation, isLoading } = useGetBookingPrice(
    fleet?.id || "",
    useType,
    startAt || "",
    endAt || "",
    {
      enabled: !!vehicle?.id,
    },
  );

  const [dialog, setDialog] = useState<DialogProps>();

  const createBooking = useCreateBooking();

  const [acceptanceChecked, setAcceptanceChecked] = useState(false);

  const toCurrency = useCurrencyFormat(Currency.EUR);

  const formatDate = useDateFormat({
    year: "numeric",
    month: "numeric",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
  });

  const navigate = useNavigate();

  const confirmBooking = () => {
    invariant(startAt);
    invariant(endAt);
    const data = {
      vehicle_id: vehicle.id,
      user_id: user?.id || "",
      pickup_location_id: pickupLocation?.id || "",
      return_location_id: pickupLocation?.id || "",
      start_at: startAt,
      end_at: endAt,
      use_type: useType,
      status: BookingStatus.CONFIRMED,
      fleet_settings_version: usageSettings?.id || "",
    };

    createBooking.mutate(data, {
      onSuccess: () => {
        setDialog({
          type: "confirm",
          animation: "success",
          title: t("fleet:booking.confirmation.successTitle"),
          subTitle: t("fleet:booking.confirmation.successSubtitle", {
            name: vehicle.name,
          }),
          description: t("fleet:booking.confirmation.successDescription"),
          onClose: onClose,
          confirm: t("fleet:booking.confirmation.successButton"),
          onConfirm: () => {
            onClose();
            navigate("/my-bookings");
          },
        });
      },
      onError: (error: any) => {
        setDialog({
          type: "alert",
          animation: "error",
          title: t("fleet:booking.confirmation.errorTitle"),
          subTitle: parseApiError(error, t),
          onClose: () => setDialog(undefined),
        });
      },
    });
  };

  const formatTime = (date: string, time: string) => {
    const dateTime = DateTime.fromFormat(
      `${date} ${time}`,
      "yyyy-MM-dd HH:mm",
    ).toString();
    return formatDate(dateTime);
  };

  const openTermsAndConditions = () => {
    setDialog({
      type: "alert",
      title: t("fleet:booking.termsAndConditions"),
      description: <ReadTerms>{termsAndConditions?.terms}</ReadTerms>,
      onClose: () => setDialog(undefined),
    });
  };

  return (
    <Modal>
      {dialog && <Dialog {...dialog} />}
      <StyledCard
        title={t("fleet:booking.bookingConfirmation")}
        subTitle={t("fleet:booking.yourRideDetails")}
      >
        <StyledPair title={t("fleet:booking.vehicle")} text={vehicle.name} />
        <StyledPair
          title={t("fleet:booking.providedBy")}
          text={fleet?.organisation?.name || ""}
        />
        <hr />
        <StyledPair
          title={t("fleet:booking.useType")}
          text={getEnumLabel(useType)}
        />
        <StyledPair
          title={t("fleet:booking.pickupLocation")}
          text={pickupLocation?.name}
        />
        <StyledPair
          title={t("fleet:booking.pickupTime")}
          text={formatTime(pickupDate, pickupTime)}
        />
        <StyledPair
          title={t("fleet:booking.returnTime")}
          text={formatTime(returnDate, returnTime)}
        />
        <StyledPair
          title={t("fleet:booking.tripDuration")}
          text={durationString}
        />
        <hr />
        <StyledPair
          title={t("fleet:booking.totalPrice")}
          text={toCurrency(bookingCalculation?.price || 0)}
        />
        {!!bookingCalculation?.price && (
          <Text text={t("fleet:booking.redirectNotice")} margin="normal" />
        )}
        <Form.Input
          type="checkbox"
          label={
            <Trans i18nKey="fleet:booking.termsAndConditionsLabel">
              I accept the{" "}
              <a
                onClick={(e) => {
                  e.preventDefault();
                  openTermsAndConditions();
                }}
              >
                terms and conditions
              </a>
            </Trans>
          }
          onChange={() => setAcceptanceChecked(!acceptanceChecked)}
          value={String(acceptanceChecked)}
          on="true"
          off="false"
        />
        <ButtonGroup className="MaintenanceEvent__buttons">
          <Button onClick={onClose} icon="arrow_back" text={t("common:back")} />
          <Button
            secondary
            icon="check"
            text={
              isLoading
                ? t("fleet:booking.updatingPrice")
                : t("fleet:booking.confirmBooking")
            }
            onClick={confirmBooking}
            disabled={!acceptanceChecked || isLoading}
          />
        </ButtonGroup>
      </StyledCard>
    </Modal>
  );
};

export default BookingConfirmation;

const StyledPair = styled(Pair)`
  text-transform: capitalize;
  margin-bottom: 1rem;
  text-align: left;
  & .Text.type-title {
    font-size: 1rem;
  }
`;

const StyledCard = styled(Card)`
  & .CardTitle,
  .CardSubTitle {
    text-align: left !important;
  }

  & .MaintenanceEvent__buttons {
    margin: 0;
    .Button {
      flex: 1 !important;
    }
  }
`;

const ReadTerms = styled.p`
  text-align: left;
`;
