import styled from "styled-components";

import { colors, fontSizes } from "../constants";

const Spaces = {
  s: "8px",
  md: "16px",
  lg: "24px",
  xl: "32px",
};

const FontWeight = {
  thin: "100",
  extraLight: "200",
  light: "300",
  regular: "400",
  medium: "500",
  semiBold: "600",
  bold: "700",
  extraBold: "800",
  black: "900",
};

type TypoProps = {
  color?: keyof typeof colors;
  spacing?: keyof typeof Spaces;
  fontWeight?: keyof typeof FontWeight;
  className?: string;
  transform?: "none" | "capitalize" | "uppercase" | "lowercase" | "full-width";
  align?: "left" | "right" | "center" | "justify";
};

const BaseText = styled.p<TypoProps>`
  font-family: "AvenirLTPro-Roman";
  line-height: normal;
  color: ${(props) => (props.color ? colors[props.color] : colors.darkGray)};
  margin-bottom: ${(props) => props.spacing && Spaces[props.spacing]};
  font-weight: ${(props) => props.fontWeight && FontWeight[props.fontWeight]};
  text-transform: ${(props) => props.transform};
  text-align: ${(props) => props.align};
`;

export const Heading1 = styled(BaseText).attrs({
  as: "h1",
})`
  font-size: ${fontSizes.heading1};
`;

export const Heading2 = styled(BaseText).attrs({
  as: "h2",
})`
  font-size: ${fontSizes.heading2};
`;

export const Heading3 = styled(BaseText).attrs({
  as: "h3",
})`
  font-size: ${fontSizes.heading3};
`;

export const Heading4 = styled(BaseText).attrs({
  as: "h4",
})`
  font-size: ${fontSizes.heading4};
`;

export const Heading5 = styled(BaseText).attrs({
  as: "h5",
})`
  font-size: ${fontSizes.heading5};
`;

export const Heading6 = styled(BaseText).attrs({
  as: "h6",
})`
  font-size: ${fontSizes.heading6};
`;

export const Subtitle1 = styled(BaseText).attrs({
  as: "h6",
})`
  font-size: ${fontSizes.subtitle1};
`;

export const Subtitle2 = styled(BaseText).attrs({
  as: "h6",
})`
  font-size: ${fontSizes.subtitle2};
`;

export const Body1 = styled(BaseText).attrs({
  as: "p",
})`
  font-size: ${fontSizes.body1};
  b {
    font-weight: 700;
  }
`;

export const Body2 = styled(BaseText).attrs({
  as: "p",
})`
  font-size: ${fontSizes.body2};
`;
