import { FC } from "react";

import { useTranslation } from "react-i18next";

import { useDownload } from "@vapaus/utils";

import { useCurrentUser } from "../../user-utils";
import { Button } from "../Button";

export const DownloadTermsButton: FC<{
  termsIdKey:
    | "accepted_user_terms_id"
    | "accepted_shop_terms_id"
    | "accepted_provider_terms_id";
  requestFunction: (id: string) => Promise<Blob>;
}> = ({ requestFunction, termsIdKey }) => {
  const { t } = useTranslation();
  const { data } = useCurrentUser();
  const termId = data?.[termsIdKey] as string;
  const { download } = useDownload(() => requestFunction(termId));

  return (
    <Button
      secondary
      icon="link"
      text={t("common:downloadTermsAndConditions")}
      onClick={() => download(undefined, "", `application/pdf`)}
    />
  );
};
