import { FC, useState } from "react";

import { DateTime } from "luxon";
import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";
import styled from "styled-components";

import { BookingRead, BookingStatus, Currency } from "@vapaus/generated";
import { useCurrencyFormat, useDateFormat } from "@vapaus/i18n";
import { parseApiError, useEnumEntries } from "@vapaus/utils";

import {
  Button,
  Card,
  Dialog,
  DialogProps,
  Grid,
  PageLoading,
  Pair,
  Text,
  media,
} from "../../../packages/ui";
import { useGetBooking } from "../../hooks/booking";
import { useGetFleet } from "../../hooks/fleet";
import {
  useGetVehicle,
  useLockVehicle,
  useUnlockVehicle,
} from "../../hooks/vehicle";
import { getDurationString } from "../../utils/getDurationString";
import CancelBookingButton from "./components/CancelBookingButton";
import FleetInfo from "./components/FleetInfo";
import LocationName from "./components/LocationName";
import VehicleInfo from "./components/VehicleInfo";

const BookingPage: FC = () => {
  const { t } = useTranslation();
  const { bookingId } = useParams<{ bookingId: string }>();
  const [dialog, setDialog] = useState<DialogProps>();
  const { data: booking, isLoading } = useGetBooking(bookingId || "", {
    enabled: !!bookingId,
  });

  // TODO: Currently we have to get the vehicle before we can get the fleet
  // because the fleet is not included in the booking response. This should
  // be fixed in the backend. Then we can remove the vehicle query and use
  // the fleet id from the booking response.
  const { data: vehicle } = useGetVehicle(booking?.vehicle_id || "", {
    enabled: !!booking?.vehicle_id,
  });
  const { data: fleet } = useGetFleet(vehicle?.fleet_id || "", {
    enabled: !!vehicle?.fleet_id,
  });

  const lockMutation = useLockVehicle();
  const unlockMutation = useUnlockVehicle();

  const formatDate = useDateFormat({
    year: "numeric",
    month: "numeric",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
  });

  // TODO: Update this to currency from booking, when available
  const formatMoney = useCurrencyFormat(Currency.EUR);
  const { getEnumLabel: getBookingStatusLabel } =
    useEnumEntries("BookingStatus");
  const { getEnumLabel: getUsageTypeLabel } = useEnumEntries("BookingUseType");

  const duration = getDurationString(
    booking?.start_at || "",
    booking?.end_at || "",
    t,
  );

  function handleLockClick() {
    lockMutation.mutate(vehicle.id, {
      onSuccess: () => {
        setDialog({
          animation: "success",
          type: "alert",
          title: t("user_app:booking.lock.success"),
          subTitle: t("user_app:booking.lock.inProgress"),
          onClose: () => setDialog(undefined),
        });
      },
      onError: (error: any) => {
        setDialog({
          type: "alert",
          animation: "error",
          title: t("user_app:booking.lock.errorTitle"),
          subTitle: parseApiError(error, t),
          onClose: () => setDialog(undefined),
        });
      },
    });
  }

  function handleUnlockClick() {
    unlockMutation.mutate(vehicle.id, {
      onSuccess: () => {
        setDialog({
          animation: "success",
          type: "alert",
          title: t("user_app:booking.unlock.success"),
          subTitle: t("user_app:booking.unlock.inProgress"),
          onClose: () => setDialog(undefined),
        });
      },
      onError: (error: any) => {
        setDialog({
          type: "alert",
          animation: "error",
          title: t("user_app:booking.unlock.errorTitle"),
          subTitle: parseApiError(error, t),
          onClose: () => setDialog(undefined),
        });
      },
    });
  }

  const showLockControls =
    vehicle?.lock_type &&
    vehicle?.lock_id &&
    booking &&
    (booking.status === BookingStatus.CONFIRMED ||
      booking.status === BookingStatus.FINISHED ||
      booking.status === BookingStatus.ONGOING) &&
    DateTime.fromISO(booking.start_at) <= DateTime.now().plus({ minutes: 5 }) &&
    DateTime.fromISO(booking.end_at) >= DateTime.now().minus({ hours: 1 });

  if (isLoading) {
    return <PageLoading />;
  }

  return (
    <>
      {dialog && <Dialog {...dialog} />}
      <PageHeader>
        <Link to="/my-bookings">
          <Button icon="arrow_back" text="Back" />
        </Link>
        <Text type="subTitle" text={t("fleet:booking.bookingHistory")} />
      </PageHeader>
      <Content>
        <Grid>
          <Card title={t("fleet:booking.bookingDetails")}>
            <Pair
              margin="normal"
              title={t("user_app:booking.status")}
              text={getBookingStatusLabel(booking?.status)}
            />
            <Pair
              margin="normal"
              title={t("user_app:booking.useType")}
              text={getUsageTypeLabel(booking?.use_type)}
            />
            <Pair
              margin="normal"
              title={t("fleet:booking.pickupLocation")}
              text={
                <LocationName
                  locationId={booking?.pickup_location_id || ""}
                  vehicleId={booking?.vehicle_id || ""}
                />
              }
            />
            <Pair
              margin="normal"
              title={t("fleet:booking.returnLocation")}
              text={
                <LocationName
                  locationId={booking?.return_location_id || ""}
                  vehicleId={booking?.vehicle_id || ""}
                />
              }
            />
            <Pair
              margin="normal"
              title={t("user_app:booking.pickupTime")}
              text={formatDate(booking?.start_at || "")}
            />
            <Pair
              margin="normal"
              title={t("user_app:booking.returnTime")}
              text={formatDate(booking?.end_at || "")}
            />
            <Pair
              margin="normal"
              title={t("fleet:booking.tripDuration")}
              text={duration}
            />
            <hr />
            <Pair
              margin="normal"
              title={t("user_app:booking.totalPrice")}
              text={formatMoney(booking?.price || 0)}
            />
            <CancelBookingButton booking={booking as BookingRead} />
          </Card>
        </Grid>
        <Grid className="fleetInfo">
          {showLockControls && (
            <Card title={t("user_app:booking.lockUnlock")} margin="normal">
              <ButtonContainer>
                <Button
                  tertiary
                  text={t("user_app:booking.unlockAction")}
                  onClick={handleUnlockClick}
                />
                <Button
                  secondary
                  text={t("user_app:booking.lockAction")}
                  onClick={handleLockClick}
                />
              </ButtonContainer>
            </Card>
          )}
          <FleetInfo vehicleId={booking?.vehicle_id || ""} />
          <VehicleInfo vehicleId={booking?.vehicle_id || ""} />
        </Grid>
      </Content>
      {vehicle?.how_to_use && (
        <Card title={t("fleet:booking.usageInstructions")}>
          <Text type="subTitle" text={vehicle?.how_to_use} />
        </Card>
      )}
    </>
  );
};

export default BookingPage;

const Content = styled.div`
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: 1fr 350px;
  grid-row: 1 / 3;
  margin-bottom: 1rem;

  ${media.atMobile} {
    grid-template-columns: 1fr;
    grid-row: 1;
    .fleetInfo {
      grid-row: 2;
    }
  }
`;

const PageHeader = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  *:first-child {
    margin-right: 1rem;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: 1rem;
`;
