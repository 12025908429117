import styled from "styled-components";

import { colors } from "../constants";

export const Label = styled.label`
  font-size: 16px;
  font-weight: 700;
  text-align: left;
  width: 100%;
  display: block;
  margin-bottom: 0.5em;
  color: ${colors.textColor};
  font-family: inherit;
`;
