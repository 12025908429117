import React, { FC } from "react";

import styled from "styled-components";

type Props = {
  children: React.ReactNode;
  title?: string;
};

export const WidgetCard: FC<Props> = ({ children, title }) => {
  return (
    <StyledCard>
      <CardTitle>{title}</CardTitle>
      <CardBody>{children}</CardBody>
    </StyledCard>
  );
};

export const CardTitle = styled.h2`
  font-size: 2em;
  font-weight: 400;
  margin: 0;
  padding: 0;
  font-weight: 500;
  text-align: left;
  font-family: "AvenirLTPro-Black", sans-serif;
  margin-bottom: 0.5em;
`;

const CardBody = styled.div``;

const StyledCard = styled.div`
  width: 100%;
  background-color: #fff;
  padding: 1.5rem;
  border-radius: 0.25rem;
  box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.1);
  margin-bottom: 2rem;
`;
