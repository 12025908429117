import React from "react";

import "./ButtonGroup.scss";

type ButtonGroupProps = {
  className?: string;
  margin?: "normal" | "large";
  children: React.ReactNode;
};

export const ButtonGroup = ({
  className,
  margin,
  children,
}: ButtonGroupProps) => {
  let _className = "ButtonGroup";

  if (className) {
    _className += " " + className;
  }

  if (margin) {
    _className += ` margin-${margin}`;
  }

  return <div className={_className}>{children}</div>;
};
