import { FC, forwardRef, useEffect, useState } from "react";
import { createPortal } from "react-dom";

import { Modal as MuiModal } from "@mui/base";
import { keyframes, styled } from "@mui/system";
import clsx from "clsx";
import { useTranslation } from "react-i18next";

import { BaseButton } from "../Button";
import { Icon } from "../Icon";
import { Body1, Heading3 } from "../Typography";
import { colors } from "../constants";

const sizeMap = {
  sm: 360,
  md: 480,
  lg: 600,
};

const animationDuration = 500; // 0.5sec

interface ModalProps {
  title: string;
  content: string;
  onConfirm?: () => void;
  cancelText?: string;
  confirmText?: string;
  isConfirming?: boolean;
  size?: keyof typeof sizeMap;
  isOpen: boolean;
  onClose: () => void;
}

export interface AlertRef {
  open: () => void;
  close: () => void;
}

export const AlertModal: FC<ModalProps> = ({
  title,
  content,
  cancelText,
  confirmText,
  onConfirm,
  onClose,
  isConfirming,
  size = "sm",
  isOpen,
}) => {
  const { t } = useTranslation();
  const [show, setShow] = useState(false);
  const [isUnmounting, setIsUnmounting] = useState(false);

  const handleClose = () => {
    setIsUnmounting(true);
    setTimeout(() => {
      onClose();
      setIsUnmounting(false);
      setShow(false);
    }, animationDuration);
  };

  useEffect(() => {
    if (isOpen) setShow(isOpen);
    else handleClose();
  }, [isOpen]);

  return createPortal(
    <Modal
      open={show}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
      onClose={handleClose}
      slots={{ backdrop: Backdrop }}
    >
      <ModalWrapper open={show} isUnmounting={isUnmounting} modalSize={size}>
        <ModalHeader>
          <ModalCloseButton onClick={handleClose}>
            <Icon name="close" />
          </ModalCloseButton>
        </ModalHeader>
        <ModalContent>
          <Heading3 fontWeight="bold" spacing="md" color="darkGray">
            {title}
          </Heading3>
          <Body1 color="textColor">{content}</Body1>
        </ModalContent>
        <ModalFooter>
          <BaseButton onClick={handleClose} variant="outlined">
            {cancelText || t("common:dialog.cancel")}
          </BaseButton>

          {onConfirm && (
            <BaseButton onClick={onConfirm} isLoading={isConfirming}>
              {confirmText || t("common:dialog.confirm")}
            </BaseButton>
          )}
        </ModalFooter>
      </ModalWrapper>
    </Modal>,
    document.body,
  );
};

const flyInOut = keyframes`
  0% {
    transform: translateY(100%);
    opacity: 0;
    top: 100%;
    height: 0;
  }
  100% {
    transform: translateY(0%);
    opacity: 1;
    height: auto;
    top: 50%;
  }
`;

const flyOut = keyframes`
  0% {
    transform: translateY(0%);
    opacity: 1;
    height: auto;
    top: 50%;
  }
  100% {
    transform: translateY(100%);
    opacity: 0;
    top: 100%;
    height: 0;
  }
`;

const Modal = styled(MuiModal)`
  position: fixed;
  z-index: 1300;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

interface ModalBoxProps {
  modalSize: keyof typeof sizeMap;
  isUnmounting: boolean;
  open: boolean;
}

const ModalWrapper = styled("div")`
  width: ${({ modalSize }: ModalBoxProps) =>
    sizeMap[modalSize] || sizeMap.sm}px;
  position: absolute;
  height: 0;
  left: 50%;
  overflow: hidden;
  animation: ${({ open, isUnmounting }) =>
      isUnmounting ? flyOut : open ? flyInOut : ""}
    ${animationDuration / 1000}s ease-in-out forwards;
  background-color: ${colors.white};
  border-radius: 10px;
  margin-top: -${({ modalSize }: ModalBoxProps) => (sizeMap[modalSize] || sizeMap.sm) / 2}px;
  margin-left: -${({ modalSize }: ModalBoxProps) => (sizeMap[modalSize] || sizeMap.sm) / 2}px;
`;

const BackdropUnstyled = forwardRef<
  HTMLDivElement,
  { open?: boolean; className: string }
>((props, ref) => {
  const { open, className, ...other } = props;
  return (
    <div
      className={clsx({ "MuiBackdrop-open": open }, className)}
      ref={ref}
      {...other}
    />
  );
});

const Backdrop = styled(BackdropUnstyled)`
  z-index: -1;
  position: fixed;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-tap-highlight-color: transparent;
`;

const ModalHeader = styled("div")`
  display: flex;
  justify-content: flex-end;
  padding: 1em;
`;

const ModalCloseButton = styled("button")`
  border: none;
  background: ${colors.lightGray};
  font-size: 2em;
  cursor: pointer;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  font-size: 14px;
  color: ${colors.darkGray};
`;

const ModalContent = styled("div")`
  padding: 1em 2em;
  text-align: center;
`;

const ModalFooter = styled("div")`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1em;
  padding: 1em;
  border-top: 1px solid ${colors.borderGray};
`;
