import React from "react";

import clsx from "clsx";

import { Flex } from "../Flex";
import { Icon } from "../Icon";
import "./Button.scss";

type ButtonProps = {
  icon?: string;
  text: string;
  color?: "grey" | "transparent";
  secondary?: boolean;
  tertiary?: boolean;
  wait?: boolean;
  className?: string;
  danger?: boolean;
  disabled?: boolean;
  onClick?: React.MouseEventHandler<HTMLElement>;
};

export const Button = ({
  icon,
  text,
  color,
  secondary,
  tertiary,
  wait,
  className,
  danger,
  disabled,
  onClick,
}: ButtonProps) => {
  let classNames = "Button";

  if (className) {
    classNames += " " + className;
  }

  let _icon = icon && (
    <Flex.Column size="min" className="icon">
      <Icon name={icon} />
    </Flex.Column>
  );
  const _text = text && (
    <Flex.Column size="max" className="text">
      {text}
    </Flex.Column>
  );

  if (secondary) {
    classNames += " secondary";
  }

  if (tertiary) {
    classNames += " tertiary";
  }

  if (danger) {
    classNames += " danger";
  }

  if (color) {
    classNames += " color-" + color;
  }

  if (!_text) {
    classNames += " type-icon";
  }

  if (wait) {
    classNames += " mode-wait";
    _icon = (
      <Flex.Column size="min" className="icon">
        <Icon name="hourglass_empty" />
      </Flex.Column>
    );
  }

  return (
    <Flex
      align="center"
      noWrap={true}
      className={clsx(classNames, { disabled })}
      onClick={onClick}
    >
      {_icon}
      {_text}
    </Flex>
  );
};
