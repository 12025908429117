import { TFunction } from "i18next";
import { DateTime } from "luxon";

export const getDurationString = (
  startDate: string,
  endDate: string,
  t: TFunction,
): string => {
  const { hours, minutes } = DateTime.fromISO(endDate).diff(
    DateTime.fromISO(startDate),
    ["hours", "minutes"],
  );
  const tc_minute = t("fleet:booking.minutes");
  const tc_hour = t("fleet:booking.hours");
  if (hours > 0 && minutes > 0)
    return `${hours} ${tc_hour} ${minutes} ${tc_minute}`;
  if (hours > 0) return `${hours} ${tc_hour}`;
  if (minutes > 0) return `${minutes} ${tc_minute}`;
  return "";
};
