import React, { Component } from "react";

import clsx from "clsx";

import { Flex } from "../Flex";
import { Icon } from "../Icon";
import "./Form.scss";

type SubmitType = {
  children: React.ReactNode;
  disabled?: boolean;
};

const Submit = ({ children, disabled }: SubmitType) => {
  return (
    <button
      className={clsx("FormSubmit", { disabled })}
      type="submit"
      disabled={disabled}
    >
      {children}
    </button>
  );
};

export type InputProps = {
  onChange?: (value: any) => void;
  onKeyDown?: (key: string, value: string) => void;
  onFocus?: (value: string) => void;
  onBlur?: (value: string) => void;
  help?: string;
  value?: string;
  autoFocus?: boolean;
  onClickIcon?: (element: any) => void;
  on?: string | boolean;
  off?: string | boolean;
  type:
    | "hidden"
    | "number"
    | "text"
    | "email"
    | "password"
    | "textarea"
    | "checkbox"
    | "date"
    | "time"
    | "select";
  icon?: string;
  name?: string;
  required?: boolean;
  readOnly?: boolean;
  placeholder?: string;
  min?: number | string;
  max?: number | string;
  step?: number;
  label?: string | React.ReactNode;
  choose?: string;
  data?: { [key: string]: any }[];
  valueKey?: string;
  nameKey?: string;
  forceOnChange?: boolean;
  disabled?: boolean;
  rootRef?: any;
  inputProps?: Record<string, any>;
};

type InputState = {
  changed: boolean;
  value: string | boolean;
};

class Input extends Component<InputProps, InputState> {
  inputRef = React.createRef<any>();

  change = (e: any) => {
    const value = e.target.value;
    this.setState({
      changed: true,
      value,
    });
    this.props.onChange?.(value);
  };

  keydown = (e: any) => {
    this.props.onKeyDown?.(e.key, e.target.value);
  };

  focus = (e: any) => {
    this.props.onFocus?.(e.target.value);
  };

  blur = (e: any) => {
    this.props.onBlur?.(e.target.value);
  };

  help = (e: React.MouseEvent) => {
    e.stopPropagation();
    alert(this.props.help);
  };

  constructor(props: InputProps) {
    super(props);

    this.state = {
      changed: false,
      value: props.value ? props.value : "",
    };
  }

  componentDidMount() {
    if (this.props.autoFocus && this.inputRef?.current) {
      setTimeout(() => {
        this.inputRef.current?.focus();
      }, 100);
    }
  }

  onClickIcon = () => {
    this.props.onClickIcon?.(this.inputRef.current);
  };

  componentDidUpdate(prevProps: any) {
    if (
      (this.state.changed === false || this.props.forceOnChange === true) &&
      this.props.value !== prevProps.value
    ) {
      this.setState((prevState) => ({
        ...prevState,
        changed: true,
        value: this.props.value || "",
      }));
    }
  }

  toggle = () => {
    if (this.props.on === undefined || this.props.off === undefined) {
      throw "Can't use toggle without on / off values";
    }
    if (this.props.disabled) return;
    this.setState(
      (prevState) => ({
        changed: true,
        value:
          prevState.value === this.props.on ? this.props.off! : this.props.on!,
      }),
      () => this.props.onChange?.(this.state.value),
    );
  };

  render() {
    const { props } = this;

    let classnames = "FormInput type-" + props.type;

    if (props.icon) {
      classnames += " with-icon";
    }

    let label;
    let input;
    const help = props.help && (
      <Icon className="FormHelp" name="help_outline" onClick={this.help} />
    );

    switch (props.type) {
      case "hidden":
        input = (
          <input
            type="hidden"
            name={props.name}
            value={this.state.value as string}
            onChange={this.change}
            disabled={props.disabled}
          />
        );
        break;
      case "date":
        input = (
          <input
            type="date"
            ref={this.inputRef}
            className={classnames}
            required={props.required}
            readOnly={props.readOnly}
            autoFocus={props.autoFocus}
            placeholder={props.placeholder}
            name={props.name}
            value={this.state.value as string}
            onChange={this.change}
            onKeyDown={this.keydown}
            onFocus={this.focus}
            onBlur={this.blur}
            disabled={props.disabled}
            max={props.max}
            min={props.min}
          />
        );
        break;

      case "number":
        input = (
          <input
            ref={this.inputRef}
            className={classnames}
            required={props.required}
            readOnly={props.readOnly}
            autoFocus={props.autoFocus}
            placeholder={props.placeholder}
            type="number"
            pattern="\d*"
            min={props.min}
            max={props.max}
            step={props.step}
            name={props.name}
            value={this.state.value as string}
            onChange={this.change}
            onKeyDown={this.keydown}
            onFocus={this.focus}
            onBlur={this.blur}
            disabled={props.disabled}
          />
        );
        break;

      case "text":
      case "email":
      case "time":
        input = (
          <input
            ref={this.inputRef}
            required={props.required}
            readOnly={props.readOnly}
            autoFocus={props.autoFocus}
            placeholder={props.placeholder}
            type={props.type}
            name={props.name}
            value={this.state.value as string}
            onChange={this.change}
            onKeyDown={this.keydown}
            onFocus={this.focus}
            onBlur={this.blur}
            disabled={props.disabled}
            {...this.props.inputProps}
            className={classnames}
          />
        );
        break;

      case "password":
        input = (
          <input
            ref={this.inputRef}
            className={classnames}
            required={props.required}
            readOnly={props.readOnly}
            autoFocus={props.autoFocus}
            placeholder={props.placeholder}
            type="password"
            name={props.name}
            value={this.state.value as string}
            onChange={this.change}
            onKeyDown={this.keydown}
            onFocus={this.focus}
            onBlur={this.blur}
            disabled={props.disabled}
          />
        );
        break;

      case "textarea":
        input = (
          <textarea
            ref={this.inputRef}
            className={classnames}
            required={props.required}
            readOnly={props.readOnly}
            autoFocus={props.autoFocus}
            name={props.name}
            value={this.state.value as string}
            placeholder={props.placeholder}
            onChange={this.change}
            disabled={props.disabled}
          />
        );
        break;

      case "checkbox":
        let value = props.off;
        if (this.state.value === props.on) {
          value = props.on;
        }

        let icon = <Icon className="checkbox" name="check_box_outline_blank" />;
        if (value === props.on) {
          icon = <Icon className="checkbox checked" name="check_box" />;
        }

        input = (
          <div className={classnames} onClick={this.toggle}>
            <input
              type="hidden"
              name={props.name}
              value={this.state.value as string}
              disabled={props.disabled}
            />

            <Flex noWrap align="center">
              <Flex.Column size="min">{icon}</Flex.Column>
              <Flex.Column size="max">
                <label className="FormLabel">
                  {props.label} {help}
                </label>
              </Flex.Column>
            </Flex>
          </div>
        );
        break;

      case "select":
        const options = [];

        if (!props.data) {
          throw "Can't use select without data prop";
        }

        if (props.choose) {
          options.push(
            <option key="choose" value="">
              {props.choose}
            </option>,
          );
        }

        props.data.map((item) => {
          const value = item[this.props.valueKey || "id"];
          const name = item[this.props.nameKey || "name"];

          options.push(
            <option key={value} value={value}>
              {name}
            </option>,
          );
          return true;
        });

        input = (
          <select
            ref={this.inputRef}
            className={classnames}
            required={props.required}
            autoFocus={props.autoFocus}
            name={props.name}
            value={this.state.value as string}
            placeholder={props.placeholder}
            onChange={this.change}
            disabled={props.disabled}
          >
            {options}
          </select>
        );
        break;

      default:
    }

    if (props.type !== "checkbox") {
      if (props.icon) {
        let iconClassName = "FormInputWrapperIcon";
        if (props.onClickIcon) {
          iconClassName += " with-click";
        }

        input = (
          <div className="FormInputWrapper">
            <Icon
              onClick={this.onClickIcon}
              className={iconClassName}
              name={props.icon}
            />
            {input}
          </div>
        );
      }

      label = props.label && (
        <label className="FormLabel">
          {props.label}
          {props.required && <span>*</span>} {help}
        </label>
      );
    }

    if (props.type === "hidden") {
      return input;
    }

    return (
      <fieldset ref={this.props.rootRef} className="FormFieldset">
        {label}
        {input}
      </fieldset>
    );
  }
}

type FormProps = {
  onChange?: (
    target: HTMLElement,
    value: string,
    values: { [key: string]: Array<string> | string },
  ) => void;
  onSubmit?: (values: { [key: string]: Array<string> | string }) => void;
  submitOnEnter?: boolean;
  onKeyDownEnter?: (target: HTMLElement, value: string) => void;
  onKeyDown?: (target: HTMLElement, key: string, value: string) => void;
  id?: string;
  children: React.ReactNode;
};

export class Form extends Component<FormProps> {
  static Input = Input;
  static Submit = Submit;

  change = (e: any) => {
    this.props.onChange?.(e.target, e.target.value, this.values(e.target.form));
  };

  submit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    this.props.onSubmit?.(this.values(e));
  };

  values = (e: any) => {
    const elements = e.elements ? e.elements : e.target.elements;
    const data = {} as { [key: string]: Array<string> };

    for (let i = 0; i < elements.length; i++) {
      let elementName = elements[i].name;

      if (elementName) {
        if (elementName.endsWith("[]")) {
          elementName = elementName.replace("[]", "");

          if (!data[elementName]) {
            data[elementName] = [];
          }
          data[elementName].push(elements[i].value);
        } else {
          data[elementName] = elements[i].value;
        }
      }
    }

    return data;
  };

  keydown = (e: any) => {
    if (
      e.key === "Enter" &&
      e.target.type !== "textarea" &&
      this.props.submitOnEnter !== true
    ) {
      e.preventDefault();
    }

    if (e.key === "Enter" && this.props.onKeyDownEnter) {
      this.props.onKeyDownEnter(e.target, e.target.value);
    }

    this.props.onKeyDown?.(e.target, e.key, e.target.value);
  };

  render() {
    return (
      <form
        id={this.props.id}
        onChange={this.change}
        onSubmit={this.submit}
        onKeyDown={this.keydown}
      >
        {this.props.children}
      </form>
    );
  }
}
