import React from "react";

import { Card } from "../Card";
import { Flex } from "../Flex";
import { Icon } from "../Icon";
import "./InfoCard.scss";

type InfoCardProps = {
  className?: string;
  type?: "info" | "alert";
  margin?: "normal" | "small";
  icon?: string;
  children: React.ReactNode;
  onClick?: React.MouseEventHandler<HTMLElement>;
};

export const InfoCard = ({
  className,
  type,
  icon,
  children,
  onClick,
  margin,
}: InfoCardProps) => {
  let _className = "InfoCard";

  if (className) {
    _className += " " + className;
  }

  if (type) {
    _className += " type-" + type;
  }

  if (margin) {
    _className += ` margin-${margin}`;
  }

  const _icon = icon ? icon : "info";

  return (
    <Card className={_className} onClick={onClick}>
      <Flex align="center">
        <Flex.Column className="icon" size="min">
          <Icon name={_icon} />
        </Flex.Column>
        <Flex.Column>{children}</Flex.Column>
      </Flex>
    </Card>
  );
};
