import { FC, useState } from "react";

import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
import styled, { AnyStyledComponent } from "styled-components";

import {
  Dialog,
  Icon,
  colors,
  logo,
  logoSmall,
  media,
} from "../../../packages/ui";
import { MenuType } from "./types";
import { useCurrentView } from "./useCurrentView";
import { useLayoutContext } from "./useLayoutContext";

export const MobileNav = () => {
  const { pathname } = useLocation();
  const menus = useCurrentView();
  return (
    <Wrapper className="mobile">
      <MenuWrapper>
        {menus.map((menu, i) => (
          <NavItemIcon
            key={`app-mobile-menu-${i}`}
            active={pathname.includes(menu.pathname).toString()}
            to={menu.pathname}
          >
            <Icon name={menu.icon} />
          </NavItemIcon>
        ))}
      </MenuWrapper>
    </Wrapper>
  );
};

export const TopNav: FC<{
  pageTitle?: string;
}> = ({ pageTitle }) => {
  const { t } = useTranslation();
  const [showDialog, setShowDialog] = useState(false);
  const { pathname } = useLocation();
  const { currentView } = useLayoutContext();
  const menus = useCurrentView();

  return (
    <Wrapper>
      {showDialog ? (
        <Dialog
          type="alert"
          title={t("fleet:toggleUiMode.title")}
          subTitle={
            currentView === MenuType.fleet
              ? t("fleet:toggleUiMode.subTitle.sharedBikes")
              : t("fleet:toggleUiMode.subTitle.benefitBikes")
          }
          description={
            currentView === MenuType.fleet
              ? t("fleet:toggleUiMode.description.sharedBikes")
              : t("fleet:toggleUiMode.description.benefitBikes")
          }
          animation="success"
          onClose={() => setShowDialog(false)}
        />
      ) : null}
      <LogoWrapper to="/">
        <img src={logo} className="logo-large" />
        <img src={logoSmall} className="logo-small" />
      </LogoWrapper>

      <PageTitle>
        <h1>{pageTitle}</h1>
      </PageTitle>
      <Menus>
        <MenuWrapper className="app-top-menu">
          {menus.map((menu, i) => (
            <MenuItem
              key={`app-main-menu-${i}`}
              active={pathname.includes(menu.pathname).toString()}
              to={menu.pathname}
              {...menu.linksProps}
            >
              {menu.title}
            </MenuItem>
          ))}
        </MenuWrapper>
      </Menus>
    </Wrapper>
  );
};

const MenuWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const PageTitle = styled.div`
  display: none;
  h1 {
    font-size: 16px;
    font-weight: 400;
  }
`;

const Menus = styled.div`
  display: flex;
  ${MenuWrapper} {
    margin-left: 1em;
  }
`;

const LogoWrapper = styled(Link as AnyStyledComponent)`
  margin-top: 0 !important;

  img {
    float: left;
  }

  img.logo-large {
    height: 2em;
  }

  img.logo-small {
    display: none;
  }

  ${media.atMobile} {
    img.logo-small {
      display: unset;
      width: 4em;
    }

    img.logo-large {
      display: none;
    }
  }
`;

const MenuItem = styled(Link as AnyStyledComponent)<{ active: string }>`
  padding: 15px;
  cursor: pointer;
  border-top: 4px solid transparent;
  border-bottom: 4px solid transparent;
  color: ${colors.primaryColor80} !important;
  font-size: 1.1em !important;
  margin-top: 0 !important;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  ${({ active }) =>
    active === "true" &&
    `
    color: ${colors.secondaryColor} !important;
    border-bottom-color:${colors.secondaryColor};
    & > i{
      color: ${colors.secondaryColor} !important;
    }
  `}

  &:hover {
    opacity: 0.5;
    color: ${colors.secondaryColor};
    border-bottom-color: ${colors.secondaryColor};
  }
`;

const NavItemIcon = styled(MenuItem)`
  border-width: 0 !important;
  color: ${colors.primaryColor20} !important;
  i {
    font-size: 1.5em;
  }
`;

const Wrapper = styled.div`
  background-color: #fff;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 0 0 10px 10px;
  z-index: 2;
  box-shadow: 0 0 1em hsl(225deg 2% 47% / 20%);
  padding: 0 10px;

  &.mobile {
    top: unset;
    bottom: 0;
    left: 0;
    right: 0;
    border-radius: 10px 10px 0 0;
    ${MenuWrapper} {
      display: none;
      align-items: center;
      justify-content: space-evenly;
      flex: 1;
      ${media.atMobile} {
        display: flex;
      }
    }
    ${NavItemIcon} {
      color: unset !important;
    }
  }

  .app-top-menu {
    ${media.atMobile} {
      display: none;
    }
  }

  ${PageTitle} {
    ${media.atMobile} {
      display: unset;
    }
  }
`;
