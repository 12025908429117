import { FC, useEffect, useState } from "react";
import { createPortal } from "react-dom";

import { Snackbar } from "@mui/base";
import { SnackbarCloseReason } from "@mui/base/useSnackbar";
import { keyframes, styled } from "@mui/system";

import { Body1 } from "../Typography";
import { colors } from "../constants";

export type ToastType = "success" | "error" | "warning" | "info";

type ToastProps = {
  message: string;
  type?: ToastType;
  duration?: number;
  onClearMessage: () => void;
};

const animationDuration = 750; //0.75sec

export const ToastMessage: FC<ToastProps> = ({
  onClearMessage,
  message,
  type = "info",
  duration = 3000,
}) => {
  const [show, setShow] = useState(false);
  const [isUnmounting, setIsUnmounting] = useState(false);

  const handleClose = (_: any, reason: SnackbarCloseReason) => {
    if (reason === "clickaway") {
      return;
    }
    setIsUnmounting(true);
    setTimeout(() => {
      onClearMessage();
      setShow(false);
      setIsUnmounting(false);
    }, animationDuration);
  };

  useEffect(() => {
    if (message) setShow(true);
    else handleClose(undefined, "timeout");
  }, [message]);

  return createPortal(
    <StyledSnackbar
      open={show}
      type={type}
      isUnmounting={isUnmounting}
      autoHideDuration={duration}
      onClose={handleClose}
    >
      <Body1 color="white">{message}</Body1>
    </StyledSnackbar>,
    document.body,
  );
};

const bounceIn = keyframes`
  0%, 20%, 40%, 60%, 80%, 100% {
    transform: translateY(100%);
  }

  10%, 30%, 50%, 70%, 90% {
    transform: translateY(84%);
  }
`;

const bounceOut = keyframes`
    0%, 20%, 40%, 60%, 80%, 100% {
    transform: translateY(84%);
  }

  10%, 30%, 50%, 70%, 90% {
    transform: translateY(100%);
  }
`;

const StyledSnackbar = styled(Snackbar)<{
  type: ToastType;
  open: boolean;
  isUnmounting: boolean;
}>`
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ type }) => {
    switch (type) {
      case "success":
        return colors.success;
      case "error":
        return colors.danger;
      case "warning":
        return colors.warning;
      default:
        return colors.info;
    }
  }};
  bottom: 5rem;
  left: 0;
  right: 0;
  width: max-content;
  margin: auto;
  border-radius: 5px;
  padding: 1rem;
  animation-name: ${({ open, isUnmounting }) =>
    isUnmounting ? bounceOut : open ? bounceIn : ""};
  animation-duration: ${animationDuration / 1000}s;
  animation-timing-function: ${({ open }) =>
    open
      ? "cubic-bezier(0.215, 0.610, 0.355, 1.000)"
      : "cubic-bezier(0.165, 0.840, 0.440, 1.000)"};
  animation-fill-mode: both;
  z-index: 9999;
`;
