/* generated using openapi-typescript-codegen -- do no edit */

/* istanbul ignore file */

/* tslint:disable */

/* eslint-disable */
import type { CancelablePromise } from "../core/CancelablePromise";
import { OpenAPI } from "../core/OpenAPI";
import { request as __request } from "../core/request";
import type { BenefitDefinitionTermsRead } from "../models/BenefitDefinitionTermsRead";

export class BenefitDefinitionTermsService {
  /**
   * Get Benefit Definition Terms
   * @param benefitDefinitionTermsId
   * @returns BenefitDefinitionTermsRead Successful Response
   * @throws ApiError
   */
  public static getBenefitDefinitionTerms(
    benefitDefinitionTermsId: string,
  ): CancelablePromise<BenefitDefinitionTermsRead> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/api/benefit-definition-terms/{benefit_definition_terms_id}",
      path: {
        benefit_definition_terms_id: benefitDefinitionTermsId,
      },
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Accept Benefit Definition Terms
   * @param benefitDefinitionTermsId
   * @param orderOrContractId
   * @returns void
   * @throws ApiError
   */
  public static acceptBenefitDefinitionTerms(
    benefitDefinitionTermsId: string,
    orderOrContractId: string,
  ): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/api/benefit-definition-terms/{benefit_definition_terms_id}/accept",
      path: {
        benefit_definition_terms_id: benefitDefinitionTermsId,
      },
      query: {
        order_or_contract_id: orderOrContractId,
      },
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }
}
