import React, { FC } from "react";

import { useTranslation } from "react-i18next";

import { Button, Card } from "../../../packages/ui";
import "./SupportPage.scss";

const SupportPage: FC = () => {
  const { t } = useTranslation();

  return (
    <div className="Support">
      <Card
        title={t("fleet:support.feedback.title")}
        subTitle={t("fleet:support.feedback.subTitle")}
      >
        <p>{t("fleet:support.feedback.knowledgeBase")}</p>
        <br />
        <a
          className="order"
          rel="noreferrer"
          href={t("fleet:support.feedback.knowledgeBaseUrl")}
          target="_blank"
        >
          <Button
            secondary
            text={t("fleet:support.feedback.knowledgeBaseButton")}
          />
        </a>
        <br />
        <br />
        <p>{t("fleet:support.feedback.contactUs")}</p>
        <br />
        <a
          className="order"
          rel="noreferrer"
          href={t("fleet:support.feedback.contactUrl")}
          target="_blank"
        >
          <Button secondary text={t("fleet:support.feedback.contactButton")} />
        </a>
      </Card>
    </div>
  );
};

export default SupportPage;
