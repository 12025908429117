/* generated using openapi-typescript-codegen -- do no edit */

/* istanbul ignore file */

/* tslint:disable */

/* eslint-disable */
import type { CancelablePromise } from "../core/CancelablePromise";
import { OpenAPI } from "../core/OpenAPI";
import { request as __request } from "../core/request";
import type { OrderDirection } from "../models/OrderDirection";
import type { PaginatedLocationOut } from "../models/PaginatedLocationOut";

export class LocationsService {
  /**
   * Search Location
   * @param fleetId
   * @param q
   * @param ids
   * @param skip
   * @param limit
   * @param sortField
   * @param sortDirection
   * @param xFilterIds
   * @returns PaginatedLocationOut Successful Response
   * @throws ApiError
   */
  public static searchLocation(
    fleetId: string,
    q?: string,
    ids?: Array<string>,
    skip?: number,
    limit: number = 20,
    sortField?: string,
    sortDirection?: OrderDirection,
    xFilterIds?: string,
  ): CancelablePromise<PaginatedLocationOut> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/api/locations",
      headers: {
        "X-Filter-Ids": xFilterIds,
      },
      query: {
        fleet_id: fleetId,
        q: q,
        ids: ids,
        skip: skip,
        limit: limit,
        sort_field: sortField,
        sort_direction: sortDirection,
      },
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }
}
