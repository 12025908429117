import React from "react";
import ReactDOM from "react-dom/client";

import {
  ThemeProvider as MUIThemeProvider,
  createTheme as createMUITheme,
} from "@mui/material";
import { init } from "@sentry/react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { BrowserRouter } from "react-router-dom";
import { ThemeProvider } from "styled-components";

import { OpenAPI } from "@vapaus/generated";
import { CssBaseline, Fonts, createTheme } from "@vapaus/ui-v2";

import App from "./core/pages/App";
import "./i18n";
import { allowedAnonymousRoutes } from "./packages/auth";
import "./packages/ui/fonts.css";
import "./packages/ui/reset.css";

if (
  import.meta.env.VITE_ENV &&
  ["prod", "staging", "feature"].includes(import.meta.env.VITE_ENV)
) {
  init({
    dsn: "https://55777ebf6066c133003aa7734ecd959e@o1371810.ingest.us.sentry.io/4507650654076928",
    integrations: [],
  });
}

OpenAPI.BASE = import.meta.env.VITE_API_BASE_URL;
OpenAPI.WITH_CREDENTIALS = true;

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 60,
      onError: (error: any) => {
        if (
          [401, 403].includes(error.status) &&
          !allowedAnonymousRoutes.includes(window.location.pathname)
        ) {
          OpenAPI.TOKEN = undefined;
          localStorage.removeItem("token");
          window.location.href = "/sign-in";
        }
      },
    },
  },
});

const MUITheme = createMUITheme();
const theme = createTheme();

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <ThemeProvider theme={theme}>
          <MUIThemeProvider theme={MUITheme}>
            <CssBaseline />
            <Fonts />
            <App />
          </MUIThemeProvider>
        </ThemeProvider>
      </BrowserRouter>
    </QueryClientProvider>
  </React.StrictMode>,
);
