import React, { FC, useState } from "react";

import { useQueryClient } from "@tanstack/react-query";
import { DateTime } from "luxon";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { BookingRead, BookingStatus } from "@vapaus/generated";
import { parseApiError, useEnumEntries } from "@vapaus/utils";

import { Dialog, DialogProps, Icon } from "../../../../packages/ui";
import { useCancelBooking } from "../../../hooks/booking";

type Props = {
  booking: BookingRead;
};

const GridBookingStatus: FC<Props> = ({ booking }) => {
  const { t } = useTranslation();

  const [dialog, setDialog] = useState<DialogProps | undefined>(undefined);

  const { getEnumLabel } = useEnumEntries("BookingStatus");

  const cancelBooking = useCancelBooking();
  const queryClient = useQueryClient();

  const confirmCancelation = () => {
    cancelBooking.mutate(booking.id, {
      onSuccess: () => {
        queryClient.invalidateQueries(["bookings"]);
        setDialog({
          type: "alert",
          animation: "success",
          title: t("fleet:booking.cancellation.cancelBookingSuccess"),
          description: t(
            "fleet:booking.cancellation.cancelBookingSuccessDescription",
          ),
          onClose: () => setDialog(undefined),
        });
      },
      onError: (error: any) => {
        setDialog({
          type: "alert",
          animation: "error",
          title: t("fleet:booking.cancellation.cancelBookingFailed"),
          description: parseApiError(error, t),
          onClose: () => setDialog(undefined),
        });
      },
    });
  };

  const showCancelDialogue = () => {
    setDialog({
      type: "confirm",
      title: t("fleet:booking.cancellation.cancelBooking"),
      description: t("fleet:booking.cancellation.cancelBookingDescription"),
      onClose: () => setDialog(undefined),
      onConfirm: () => {
        setDialog(undefined);
        confirmCancelation();
      },
    });
  };

  const canCancel =
    booking.status === BookingStatus.CONFIRMED &&
    DateTime.fromISO(booking.start_at).diffNow().milliseconds > 0;

  return (
    <Wrapper onClick={(e) => e.stopPropagation()}>
      {dialog && <Dialog {...dialog} />}
      <span>{getEnumLabel(booking.status)}</span>
      {canCancel && <Icon name="cancel" onClick={showCancelDialogue} />}
    </Wrapper>
  );
};

export default GridBookingStatus;

const Wrapper = styled.div`
  display: flex;
  text-transform: capitalize;
  align-items: center;
  span {
    margin-right: 10px;
  }
  i {
    cursor: pointer;
    color: #f9696b;
  }
`;
