import { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";

import { parseApiError } from "@vapaus/utils";

import { Dialog, DialogProps, PageLoading } from "../../ui";
import { Body1 } from "../../uikit";
import {
  useGetUserActiveOrCurrentEmail,
  useSendVerificationToken,
} from "../../user-utils";
import { useAuthContext } from "../context/authContext";
import { AuthLayout, AuthLayoutButton } from "./AuthLayout";

const VerifyEmailNoticePage = () => {
  const { t } = useTranslation();
  const verificationToken = useSendVerificationToken();
  const { token, setToken } = useAuthContext();
  const { email: activeOrPrimaryEmail, isLoading } =
    useGetUserActiveOrCurrentEmail({
      enabled: !!token,
    });
  const [dialog, setDialog] = useState<DialogProps | undefined>(undefined);

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (!isLoading && activeOrPrimaryEmail?.email_verified) {
      navigate("/");
    }
  }, [navigate, location.pathname, activeOrPrimaryEmail]);

  const sendVerificationToken = () => {
    if (!activeOrPrimaryEmail) return;
    verificationToken.mutate(activeOrPrimaryEmail.id, {
      onSuccess: () => {
        setDialog({
          title: t("auth:VerifyEmailNoticePage.resentSuccessTitle"),
          subTitle: t("auth:VerifyEmailNoticePage.resentSuccessText", {
            email: activeOrPrimaryEmail?.email,
          }),
          type: "alert",
          onClose: () => setDialog(undefined),
          animation: "success",
        });
      },
      onError: (error: any) => {
        setDialog({
          title: t("auth:VerifyEmailNoticePage.resentFailedTitle"),
          subTitle: parseApiError(error, t),
          type: "alert",
          onClose: () => setDialog(undefined),
          animation: "error",
        });
      },
    });
  };

  const handleBackToSignUp = () => {
    setToken("");
    navigate("/sign-up");
  };

  if (isLoading) return <PageLoading />;

  return (
    <AuthLayout
      title={t("auth:VerifyEmailNoticePage.title")}
      description={t("auth:VerifyEmailNoticePage.text", {
        email: activeOrPrimaryEmail?.email,
      })}
    >
      {dialog && <Dialog {...dialog} />}
      <Body1>
        {t("auth:VerifyEmailNoticePage.didNotReceive")}{" "}
        <a href="#" onClick={sendVerificationToken}>
          {verificationToken?.isLoading
            ? t("auth:VerifyEmailNoticePage.resending")
            : t("auth:VerifyEmailNoticePage.sendAgain")}
        </a>
      </Body1>
      <Button icon="arrow_back" onClick={handleBackToSignUp}>
        {t("auth:VerifyEmailNoticePage.backToSignUp")}
      </Button>
    </AuthLayout>
  );
};

export default VerifyEmailNoticePage;

const Button = styled(AuthLayoutButton)`
  margin: auto;
  margin-top: 2em;
  width: auto;
`;
