/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum BookingStatus {
  CONFIRMED = "confirmed",
  CANCELLED = "cancelled",
  FINISHED = "finished",
  ONGOING = "ongoing",
  PENDING_PAYMENT = "pending_payment",
}
