import { FC } from "react";

import styled from "styled-components";

import { Body1, Heading5 } from "../Typography";

const Spaces = {
  s: "8px",
  md: "16px",
  lg: "24px",
  xl: "32px",
};
interface Props {
  title: string;
  subtitle: string;
  spacing?: keyof typeof Spaces;
}

export const PairTitle: FC<Props> = ({ title, subtitle }) => {
  return (
    <Root>
      <Heading5 fontWeight="bold" spacing="s">
        {title}
      </Heading5>
      <Body1>{subtitle}</Body1>
    </Root>
  );
};

const Root = styled.div<{
  spacing?: keyof typeof Spaces;
}>`
  display: flex;
  flex-direction: column;
  margin-bottom: ${({ spacing }) => {
    return spacing ? Spaces[spacing] : Spaces.md;
  }};
`;
