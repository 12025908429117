import React from "react";

import "./CircularProgress.scss";

type CircularProgressProps = {
  radius: number;
  stroke: number;
  progress: number;
};

export const CircularProgress = ({
  radius,
  stroke,
  progress,
}: CircularProgressProps) => {
  const normalizedRadius = radius - stroke * 2;
  const circumference = normalizedRadius * 2 * Math.PI;

  const strokeDashoffset = circumference - (progress / 100) * circumference;

  return (
    <svg className="CircularProgress" height={radius * 2} width={radius * 2}>
      <circle
        fill="transparent"
        strokeWidth={stroke}
        strokeDasharray={circumference + " " + circumference}
        style={{ strokeDashoffset }}
        r={normalizedRadius}
        cx={radius}
        cy={radius}
      />
    </svg>
  );
};
