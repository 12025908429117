import { useMutation, useQuery } from "@tanstack/react-query";
import type { UseQueryOptions } from "@tanstack/react-query";

import {
  EIdentService,
  PaginatedUserEmailOut,
  UserRead,
  UserService,
  UserTermsService,
  UserUpdate,
} from "@vapaus/generated";
import type { ApiError, CurrentUserRead, UserTerms } from "@vapaus/generated";

export const useCurrentUser = (
  options?: Omit<
    UseQueryOptions<CurrentUserRead, ApiError>,
    "queryKey" | "queryFn"
  >,
) =>
  useQuery<CurrentUserRead, ApiError>(
    ["currentUser"],
    UserService.readCurrentUser,
    options,
  );

export const useCurrentUserTerms = (
  options?: Omit<UseQueryOptions<UserTerms, ApiError>, "queryKey" | "queryFn">,
) =>
  useQuery<UserTerms, ApiError>(
    ["currentUserTerms"],
    UserTermsService.currentUserTerms,
    options,
  );

export const useAcceptUserTerms = () =>
  useMutation(UserService.acceptUserTerms);

export const useChangeUserPassword = () =>
  useMutation(UserService.changePassword);

export const useCurrentUserEmails = (
  options?: Omit<
    UseQueryOptions<PaginatedUserEmailOut, ApiError>,
    "queryKey" | "queryFn"
  >,
) =>
  useQuery<PaginatedUserEmailOut, ApiError>(
    ["currentUserEmails"],
    () => UserService.searchUserEmails(),
    options,
  );

export const useAddUserEmail = () => useMutation(UserService.createUserEmail);

export const useDeleteUserEmail = () =>
  useMutation(UserService.deleteUserEmail);

export const useSendVerificationToken = () =>
  useMutation(UserService.sendEmailVerificationToken);

export const useVerifyUserEmail = () => useMutation(UserService.verifyEmail);

export const useMarkUserEmailAsPrimary = () =>
  useMutation(UserService.makeUserEmailPrimary);

export const useUpdateCurrentUser = () =>
  useMutation<UserRead, ApiError, UserUpdate>(UserService.updateCurrentUser);

export const useVerifyPhoneNumber = () =>
  useMutation(UserService.verifyPhoneNumber);

export const useSendPhoneNumberVerificationCode = () =>
  useMutation(UserService.sendPhoneVerificationCode);

export const useEIdent = () =>
  useMutation((params: { ssn?: string; redirectUriPath?: string }) =>
    EIdentService.postEIdent(params.ssn, params.redirectUriPath),
  );

export const useGetUserActiveOrCurrentEmail = (
  options?: Omit<
    UseQueryOptions<PaginatedUserEmailOut, ApiError>,
    "queryKey" | "queryFn"
  >,
) => {
  const { data: emails, isLoading } = useCurrentUserEmails(options);
  const email =
    (emails?.items || []).find(
      (email) => email.email_verified || email.primary,
    ) ?? null;
  return { email, isLoading };
};
