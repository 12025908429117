import {
  UseMutationOptions,
  UseQueryOptions,
  useMutation,
  useQuery,
} from "@tanstack/react-query";

import {
  ApiError,
  Msg,
  PaginatedPaymentConsentOut,
  PaymentsService,
} from "@vapaus/generated";

type UseVismaPayCallbackParams = {
  returnCode: string;
  orderNumber: string;
  settled: string;
  incidentId: string;
  authcode: string;
};

export const useVismaPayCallback = (
  paymentId: string,
  options?: Omit<
    UseMutationOptions<Msg, ApiError, UseVismaPayCallbackParams>,
    "mutationKey" | "mutationFn"
  >,
) =>
  useMutation<Msg, ApiError, UseVismaPayCallbackParams>(
    ["paymentServices", paymentId, "vismaPayCallback"],
    ({ returnCode, orderNumber, settled, incidentId, authcode }) =>
      PaymentsService.vismaPayCallback(
        returnCode,
        orderNumber,
        settled,
        incidentId,
        authcode,
      ),
    options,
  );

export const usePaymentConsentList = (
  params?: any,
  options?: Omit<
    UseQueryOptions<PaginatedPaymentConsentOut, ApiError>,
    "queryKey" | "queryFn"
  >,
) =>
  useQuery<PaginatedPaymentConsentOut, ApiError>(
    ["paymentConsentList", params],
    () =>
      PaymentsService.searchPaymentConsents(
        params?.status,
        params.q,
        params.ids,
        params.skip,
        params.limit,
      ),
    options,
  );

export const useCreatePaymentConsent = () =>
  useMutation(PaymentsService.createPaymentConsent);

export const useCancelPaymentConsent = () =>
  useMutation(PaymentsService.cancelPaymentConsent);
