import { createContext, useState } from "react";

import { MenuType } from "./types";

export const LayoutContext = createContext<{
  currentView: MenuType;
}>({
  currentView: MenuType.benefit,
});

export const LayoutContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [currentView, setCurrentView] = useState(MenuType.fleet);

  return (
    <LayoutContext.Provider value={{ currentView }}>
      {children}
    </LayoutContext.Provider>
  );
};
