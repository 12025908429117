import { forwardRef, useId } from "react";

import styled from "styled-components";

import { BaseInput, BaseInputProps, Label } from "../Form";

interface Props extends BaseInputProps {
  label?: string;
}

export const TextInput = forwardRef<HTMLInputElement, Props>((props, ref) => {
  const uniqueId = useId();
  const id = props.id || uniqueId;
  return (
    <Root>
      {props.label && <Label htmlFor={id}>{props.label}</Label>}
      <BaseInput {...props} id={id} ref={ref} />
    </Root>
  );
});

const Root = styled.div`
  width: 100%;
  margin-bottom: 1em;
`;
