import { ReactNode, forwardRef, useId } from "react";

import styled from "styled-components";

import { BaseInputProps, Label } from "../Form";
import { Icon } from "../Icon";
import { colors } from "../constants";

interface Props extends BaseInputProps {
  label?: string;
  text?: ReactNode;
}

export const CheckboxInput = forwardRef<HTMLInputElement, Props>(
  (props, ref) => {
    const uniqueId = useId();
    const id = props.id || uniqueId;
    return (
      <Root>
        {props.label && <Label>{props.label}</Label>}
        <InputContainer>
          <CheckBoxLabel htmlFor={id}>
            <Checkbox {...props} id={id} ref={ref} type="checkbox" />
            <StyledIcon name="check_box_outline_blank" />
            <StyledIcon name="check_box" />
            {props.text && <Content>{props.text}</Content>}
          </CheckBoxLabel>
        </InputContainer>
      </Root>
    );
  },
);

const Root = styled.div`
  width: 100%;
  margin-bottom: 1em;
`;

const StyledIcon = styled(Icon)`
  font-size: 24px !important;
  display: none;
`;

const Checkbox = styled.input`
  display: none;

  &:not(:checked) + i:nth-child(2) {
    display: block !important;
  }

  &:checked + i:nth-child(2) + i:nth-child(3) {
    display: block !important;
    color: ${colors.primary};
  }
`;

const CheckBoxLabel = styled.label`
  cursor: pointer;
  display: flex;
`;

const Content = styled.span`
  flex-grow: 1;
  margin-left: 10px;
`;

const InputContainer = styled.div`
  position: relative;
`;
