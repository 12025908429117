import React from "react";

import "./Text.scss";

type TextType = "title" | "subTitle" | "text";

type TextProps = {
  className?: string;
  type?: TextType;
  small?: boolean;
  verySmall?: boolean;
  secondary?: boolean;
  tertiary?: boolean;
  margin?: "normal" | "small" | "before";
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
  text: string | JSX.Element | number;
};

type elementMappingType = {
  [key in TextType]: React.ElementType;
};

const elementMapping: elementMappingType = {
  title: "h1",
  subTitle: "h2",
  text: "p",
};

export const Text = ({
  className,
  type = "text",
  small,
  verySmall,
  secondary,
  tertiary,
  margin,
  onClick,
  text,
}: TextProps) => {
  let classNames = "Text";

  if (className) {
    classNames += " " + className;
  }

  if (type) {
    classNames += " type-" + type;
  }

  if (small) {
    classNames += " small";
  }

  if (verySmall) {
    classNames += " verySmall";
  }

  if (secondary) {
    classNames += " secondary";
  }

  if (tertiary) {
    classNames += " tertiary";
  }

  if (margin) {
    classNames += ` margin-${margin}`;
  }

  const Element = elementMapping[type] || "span";

  return (
    <Element className={classNames} onClick={onClick}>
      {text}
    </Element>
  );
};
