import { FC } from "react";

import styled from "styled-components";

import { Card, Text, bunnyImg } from "../../../../packages/ui";
import { useGetFleet } from "../../../hooks/fleet";
import { useGetVehicle } from "../../../hooks/vehicle";

type Props = {
  vehicleId: string;
};
const FleetInfo: FC<Props> = ({ vehicleId }) => {
  const { data: vehicleData } = useGetVehicle(vehicleId);
  const { data } = useGetFleet(vehicleData?.fleet_id || "", {
    enabled: !!vehicleData?.fleet_id,
  });
  return (
    <Card margin="normal">
      <Wrapper>
        <img src={data?.logo_url || bunnyImg} alt="logo" />
        <div>
          <Text type="text" text={data?.organisation?.name || ""} />
          <Text type="subTitle" text={data?.name || ""} />
        </div>
      </Wrapper>
    </Card>
  );
};

export default FleetInfo;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  img {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    margin-right: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    object-fit: contain;
  }
`;
