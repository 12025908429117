import { ButtonHTMLAttributes, forwardRef } from "react";

import styled, { css } from "styled-components";

import {
  Spacings,
  colors,
  devices,
  makeSpacing,
  makeTransition,
} from "../constants";

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  icon?: JSX.Element;
  children?: JSX.Element | string;
  iconPosition?: "before" | "after";
  color?: "primary" | "white" | "gray";
  variant?: "outlined";
  spacing?: Spacings;
}

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  ({ children, icon, ...restProps }, ref) => {
    return (
      <StyledButton {...restProps} ref={ref}>
        {Boolean(icon) && icon}
        <span>{children}</span>
      </StyledButton>
    );
  },
);

const makeColor = (
  bg: string,
  bgDisabled: string,
  bgHover: string,
  font: string,
) => {
  return css`
    background-color: ${bg};
    border-color: ${bg};
    color: ${font};
    &:hover {
      background-color: ${bgHover};
      border-color: ${bgHover};
    }
    &:disabled {
      background-color: ${bgDisabled};
      border-color: ${bgDisabled};
    }
  `;
};

const makeOutlinedColor = (
  bg: string,
  borderColor: string,
  borderDisabled: string,
  borderHover: string,
  font: string,
) => {
  return css`
    background-color: ${bg};
    border-color: ${borderColor};
    color: ${font};
    &:hover {
      background-color: ${borderHover};
      border-color: ${borderHover};
    }
    &:disabled {
      background-color: ${borderDisabled};
      border-color: ${borderDisabled};
    }
  `;
};

type StyledButtonProps = Omit<ButtonProps, "icon" | "children">;

const StyledButton = styled.button<StyledButtonProps>`
  height: 56px;
  border: 1px solid;
  border-radius: 5px;
  padding: 0 16px;
  font-size: 1rem;
  font-family: inherit;
  font-weight: 700;
  cursor: pointer;
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: center;
  position: relative;
  ${makeTransition("all")}

  & > span {
    font-weight: inherit;
    font-size: inherit;
    font-family: inherit;
    color: inherit;
  }

  &:hover {
    border: 1px solid;
  }
  &:disabled {
    border: 1px solid;
  }

  @media ${devices.mobileDown} {
    height: 40px;
  }

  ${(props) => makeSpacing(props.spacing)}

  ${(props) =>
    props.iconPosition === "after" &&
    css`
      flex-direction: row-reverse;
    `}

  ${(props) => {
    switch (props.color) {
      case "white":
        switch (props.variant) {
          case "outlined":
            return makeOutlinedColor(
              colors.white,
              colors.borderGray,
              colors.grayLight,
              colors.grayLight,
              colors.grayDark,
            );
          default:
            return makeColor(
              colors.white,
              colors.grayLight,
              colors.grayLight,
              colors.grayDark,
            );
        }
      case "gray":
        return makeColor(
          colors.grayDark,
          colors.gray,
          colors.gray,
          colors.white,
        );
      case "primary":
      default:
        return makeColor(
          colors.primary,
          colors.primaryLight,
          colors.primaryDark,
          colors.white,
        );
    }
  }}
`;
