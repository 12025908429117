import { FC } from "react";

import { Trans, useTranslation } from "react-i18next";
import styled from "styled-components";

import { InfoCard, PageLoading, Text, media } from "../../../packages/ui";
import { useCurrentUser } from "../../../packages/user-utils";
import { useGetFleets } from "../../hooks/fleet";
import EmptyFleetList from "./EmptyFleetList";
import FleetItem from "./FleetItem";

const RideBooking: FC = () => {
  const { t } = useTranslation();
  const { data, isLoading } = useGetFleets({ isActive: true });
  const { data: user } = useCurrentUser();
  const fleets = data?.items || [];

  if (isLoading) return <PageLoading />;

  return (
    <>
      <Text type="title" text={t("fleet:fleet.serviceProviders")} />
      <Wrapper>
        {fleets.length ? (
          <ItemWrapper>
            {fleets.map((fleet) => (
              <FleetItem fleet={fleet} key={`fleet-${fleet.id}`} />
            ))}
          </ItemWrapper>
        ) : (
          <EmptyFleetList />
        )}

        {user && !user.is_anonymous && (
          <InfoCard type="info" icon="info">
            <Trans i18nKey="fleet:fleet.accessInfo">
              Don't see the fleet you need?
              <br />
              You can add access codes or verify your personal information in
              your settings
            </Trans>
          </InfoCard>
        )}
      </Wrapper>
    </>
  );
};

export default RideBooking;

const ItemWrapper = styled.div`
  margin-top: 20px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 20px;
  margin-bottom: 20px;
  ${media.atTablet} {
    grid-template-columns: repeat(2, 1fr);
  }
  ${media.atMobile} {
    grid-template-columns: repeat(2, 1fr);
  }
`;

const Wrapper = styled.div`
  margin-top: 20px;
`;
