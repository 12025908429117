import { useTranslation } from "react-i18next";

interface Menu {
  title: string;
  pathname: string;
  icon: string;
  linksProps?: { target: string };
}

export const useCurrentView = () => {
  const { t } = useTranslation();

  const menus: Array<Menu> = [
    {
      title: t("fleet:menu.myBookings"),
      pathname: "/my-bookings",
      icon: "list",
    },
    {
      title: t("fleet:menu.bookARide"),
      pathname: "/book-a-ride",
      icon: "date_range",
    },
    {
      title: t("fleet:menu.settings"),
      pathname: "/settings",
      icon: "settings",
    },
    {
      title: t("fleet:menu.support"),
      pathname: "/support",
      icon: "mail",
    },
    {
      title: t("fleet:menu.benefit"),
      pathname: import.meta.env.VITE_USER_APP_URL,
      icon: "pedal_bike",
    },
  ];

  return menus;
};
