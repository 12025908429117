import { useState } from "react";

import { decodeJwt } from "jose";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { parseApiError } from "@vapaus/utils";

import { Dialog } from "../../ui";
import { PasswordInput, TextInput } from "../../uikit";
import { AvailableScopes } from "../constants";
import { useAuthContext } from "../context/authContext";
import { useSignIn } from "../hooks/auth";
import {
  AuthLayout,
  AuthLayoutButton,
  AuthLayoutFooterLink,
} from "./AuthLayout";

type SignInPageProps = {
  allowSignUp?: boolean;
  scope?: AvailableScopes;
};

export const SignInPage = ({ allowSignUp, scope }: SignInPageProps) => {
  const { t } = useTranslation();
  const [error, setError] = useState<null | string>(null);
  const mutation = useSignIn();
  const { setToken } = useAuthContext();

  const {
    register,
    handleSubmit,
    formState: { isValid },
  } = useForm();

  const handleLogin = async (data: any) => {
    try {
      if (scope) {
        data = { ...data, scope };
      }
      const res = await mutation.mutateAsync(data);
      const claims = decodeJwt(res.access_token);
      const availableScope = claims.available_scopes as Array<string>;
      if (scope && !availableScope?.includes(scope)) {
        throw t("auth:signIn.forbidden");
      }

      setToken(res.access_token);
    } catch (error: any) {
      setError(typeof error === "string" ? error : parseApiError(error, t));
    }
  };

  return (
    <AuthLayout
      title={t("auth:signIn.title")}
      description={t("auth:signIn.text")}
    >
      {error && (
        <Dialog
          type="alert"
          title={t("common:dialog.error")}
          subTitle={t("auth:signIn.error")}
          description={error}
          animation="error"
          onClose={() => setError(null)}
        />
      )}
      <form onSubmit={handleSubmit(handleLogin)}>
        <TextInput
          autoFocus
          required
          type="email"
          label={t("auth:signIn.email")}
          placeholder={t("auth:signUp.emailPlaceholder")}
          {...register("username", { required: true })}
        />
        <PasswordInput
          required
          label={t("auth:signUp.password")}
          placeholder={t("auth:signUp.password")}
          {...register("password", { required: true })}
        />
        <AuthLayoutButton
          isLoading={mutation.isLoading}
          disabled={!isValid}
          type="submit"
          icon="lock"
        >
          {t("auth:signIn.submit")}
        </AuthLayoutButton>
      </form>
      {allowSignUp && (
        <AuthLayoutFooterLink to="/sign-up">
          {t("auth:link.signUp")}
        </AuthLayoutFooterLink>
      )}
      <AuthLayoutFooterLink to="/forgot-password">
        {t("auth:link.forgotPassword")}
      </AuthLayoutFooterLink>
    </AuthLayout>
  );
};
