import { FC, useState } from "react";

import { useTranslation } from "react-i18next";

import { UserEmailRead } from "@vapaus/generated";
import { parseApiError } from "@vapaus/utils";

import { Dialog, DialogProps } from "../../../../ui";
import {
  useCurrentUserEmails,
  useDeleteUserEmail,
  useMarkUserEmailAsPrimary,
  useSendVerificationToken,
} from "../../../../user-utils";
import VerificationCard from "../../VerificationCard";
import { DetailType } from "../../types";

const EmailListDetail: FC = () => {
  const { t } = useTranslation();

  const [dialog, setDialog] = useState<DialogProps | undefined>();

  const { data: emailsOut, refetch } = useCurrentUserEmails();

  const deleteUserEmail = useDeleteUserEmail();

  const verificationToken = useSendVerificationToken();

  const markEmailAsPrimary = useMarkUserEmailAsPrimary();

  const handleDeleteEmail = (email: UserEmailRead) => {
    deleteUserEmail.mutate(email.id as string, {
      onSuccess: () => refetch(),
      onError: (error: any) =>
        setDialog({
          type: "alert",
          title: t("settings:cardDetails.email.errorDeleting"),
          animation: "error",
          description: parseApiError(error, t),
          onClose: () => setDialog(undefined),
        }),
    });
  };

  const handleSendVerification = (email: UserEmailRead) => {
    verificationToken.mutate(email.id, {
      onSuccess: () =>
        setDialog({
          type: "alert",
          title: t("settings:cardDetails.email.verifyEmail"),
          subTitle: t("settings:cardDetails.email.verificationSent"),
          animation: "success",
          description: t(
            "settings:cardDetails.email.verificationSentDescription",
          ),
          onClose: () => setDialog(undefined),
        }),
      onError: (error: any) =>
        setDialog({
          type: "alert",
          title: t("settings:cardDetails.email.errorSendingVerification"),
          animation: "error",
          description: parseApiError(error, t),
          onClose: () => setDialog(undefined),
        }),
    });
  };

  const showVerifyEmailDialog = (email: UserEmailRead) => {
    setDialog({
      type: "confirm",
      title: t("settings:cardDetails.email.verifyEmail"),
      subTitle: t("settings:cardDetails.email.verificationSent"),
      animation: "warning",
      confirm: t("settings:cardDetails.email.sendAgain"),
      description: t("settings:cardDetails.email.verificationSentDescription2"),
      onClose: () => setDialog(undefined),
      onConfirm: () => handleSendVerification(email),
    });
  };

  const handleMarkEmailAsPrimary = (email: UserEmailRead) => {
    setDialog({
      type: "confirm",
      title: t("settings:cardDetails.email.markAsPrimary"),
      description: t("settings:cardDetails.email.markAsPrimaryQuestion"),
      animation: "warning",
      onConfirm: () => {
        markEmailAsPrimary.mutate(email.id, {
          onSuccess: () => {
            refetch();
            setDialog({
              type: "alert",
              title: t("settings:cardDetails.email.markAsPrimarySuccess"),
              description: t("settings:cardDetails.email.markAsPrimarySuccess"),
              onClose: () => setDialog(undefined),
              animation: "success",
            });
          },
          onError: (error: any) =>
            setDialog({
              type: "alert",
              title: t("settings:cardDetails.email.errorSendingVerification"),
              animation: "error",
              description: parseApiError(error, t),
              onClose: () => setDialog(undefined),
            }),
        });
      },
      onClose: () => setDialog(undefined),
    });
  };

  return (
    <>
      {dialog && (
        <Dialog
          cancel={t("common:dialog.cancel")}
          close={t("common:dialog.close")}
          {...dialog}
        />
      )}
      {(emailsOut?.items || []).map((email) => (
        <VerificationCard
          key={email.id}
          type={DetailType.Email}
          title={email.email}
          description={
            email.email_verified
              ? t("settings:verificationCard.verifiedEmail")
              : t("settings:verificationCard.unverifiedEmail")
          }
          isVerified={email.email_verified}
          canDelete={email.primary === false}
          onVerify={() => showVerifyEmailDialog(email)}
          onDelete={() => handleDeleteEmail(email)}
          onMarkAsPrimary={
            email.primary ? undefined : () => handleMarkEmailAsPrimary(email)
          }
        />
      ))}
    </>
  );
};

export default EmailListDetail;
