/* generated using openapi-typescript-codegen -- do no edit */

/* istanbul ignore file */

/* tslint:disable */

/* eslint-disable */
import type { CancelablePromise } from "../core/CancelablePromise";
import { OpenAPI } from "../core/OpenAPI";
import { request as __request } from "../core/request";
import type { Body_auth_sign_in } from "../models/Body_auth_sign_in";
import type { Msg } from "../models/Msg";
import type { PasswordResetToken } from "../models/PasswordResetToken";
import type { Token } from "../models/Token";
import type { UserCreate } from "../models/UserCreate";

export class AuthService {
  /**
   * Sign Up
   * @param requestBody
   * @returns Token Successful Response
   * @throws ApiError
   */
  public static signUp(requestBody: UserCreate): CancelablePromise<Token> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/api/auth/sign-up",
      body: requestBody,
      mediaType: "application/json",
      errors: {
        400: `Bad Request`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Sign In
   * @param formData
   * @returns Token Successful Response
   * @throws ApiError
   */
  public static signIn(formData: Body_auth_sign_in): CancelablePromise<Token> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/api/auth/sign-in",
      formData: formData,
      mediaType: "application/x-www-form-urlencoded",
      errors: {
        400: `Bad Request`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Recover Password
   * We always send a successful response even if the email is not found for security reason.
   * @param email
   * @returns Msg Successful Response
   * @throws ApiError
   */
  public static recoverPassword(email: string): CancelablePromise<Msg> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/api/auth/password-recovery/{email}",
      path: {
        email: email,
      },
      errors: {
        400: `Bad Request`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Reset Password
   * @param email
   * @param requestBody
   * @returns Token Successful Response
   * @throws ApiError
   */
  public static resetPassword(
    email: string,
    requestBody: PasswordResetToken,
  ): CancelablePromise<Token> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/api/auth/reset-password/{email}",
      path: {
        email: email,
      },
      body: requestBody,
      mediaType: "application/json",
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Check Reset Password Token
   * @param email
   * @param token
   * @returns Msg Successful Response
   * @throws ApiError
   */
  public static checkResetPasswordToken(
    email: string,
    token: string,
  ): CancelablePromise<Msg> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/api/auth/reset-password/verify-token",
      query: {
        email: email,
        token: token,
      },
      errors: {
        400: `Bad Request`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }
}
