import { useMutation } from "@tanstack/react-query";

import type {
  ApiError,
  Body_auth_sign_in,
  Token,
  UserCreate,
} from "@vapaus/generated";
import { AuthService, Msg, PasswordResetToken } from "@vapaus/generated";

export type PasswordResetInput = PasswordResetToken & { email: string };

export const useSignIn = () =>
  useMutation<Token, ApiError, Body_auth_sign_in>(AuthService.signIn);

export const useSignUp = () =>
  useMutation<Token, ApiError, UserCreate>(AuthService.signUp);

export const useRecoverPassword = () =>
  useMutation<Msg, ApiError, string>(AuthService.recoverPassword);

export const useResetPassword = () =>
  useMutation<Token, ApiError, PasswordResetInput>(({ email, ...body }) =>
    AuthService.resetPassword(email, body),
  );
