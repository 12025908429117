import ChangePassword from "./ChangePassword";
import NotificationsPreferences from "./NotificationsPreferences";
import UpdatePersonalInfo from "./UpdatePersonalInfo";
import UserDetails from "./UserDetails/UserDetails";
import { LayoutProps } from "./types";

export const SettingsLayout = <NotificationCategory,>({
  children,
  personalDetails,
  personalInfoForm,
  changePassword = true,
  title,
  notificationPreferences,
}: LayoutProps<NotificationCategory>) => (
  <div className="Settings">
    {children}
    <UpdatePersonalInfo cardTitle={title} personalInfoForm={personalInfoForm} />
    {notificationPreferences && (
      <NotificationsPreferences {...notificationPreferences} />
    )}
    <UserDetails details={personalDetails} />
    {changePassword && <ChangePassword />}
  </div>
);
