import { SelectHTMLAttributes, forwardRef, useId } from "react";

import styled from "styled-components";

import { Label } from "../Form";
import { colors } from "../constants";

interface Item {
  value: any;
  label: string;
}

interface Props extends SelectHTMLAttributes<HTMLSelectElement> {
  label?: string;
  options: Array<Item>;
}

export const SelectInput = forwardRef<HTMLSelectElement, Props>(
  (props, ref) => {
    const { label, options, className, placeholder, ...selectProps } = props;
    const uniqueId = useId();
    const id = props.id || uniqueId;
    return (
      <Root className={className}>
        {label && <Label htmlFor={id}>{label}</Label>}
        <Select {...selectProps} id={id} ref={ref}>
          <SelectOption value="">{placeholder}</SelectOption>
          {options.map((option, index) => (
            <SelectOption key={`option-${id}-${index}`} value={option.value}>
              {option.label}
            </SelectOption>
          ))}
        </Select>
      </Root>
    );
  },
);

const Root = styled.div`
  width: 100%;
  margin-bottom: 1em;
`;

const Select = styled.select`
  width: 100%;
  height: 48px;
  border: 1px solid ${colors.borderGray};
  background: transparent;
  border-radius: 4px;
  padding: 0 1em;
  font-size: 16px;
  font-family: inherit;
  color: ${colors.darkGray};
  &::placeholder {
    color: ${colors.gray};
  }
`;

const SelectOption = styled.option``;
