import React from "react";

import "./Grid.scss";

type GridProps = {
  className?: string;
  style?: React.CSSProperties;
  onClick?: React.MouseEventHandler<HTMLElement>;
  children: React.ReactNode;
};

export const Grid = ({ className, style, onClick, children }: GridProps) => {
  let _className = "Grid";

  if (className) {
    _className += " " + className;
  }

  return (
    <div style={style} onClick={onClick} className={_className}>
      {children}
    </div>
  );
};

type GridItemProps = {
  className?: string;
  onClick?: React.MouseEventHandler<HTMLElement>;
  children: React.ReactNode;
};

Grid.Item = ({ className, onClick, children }: GridItemProps) => {
  let _className = "GridItem";

  if (className) {
    _className += " " + className;
  }

  return (
    <div onClick={onClick} className={_className}>
      {children}
    </div>
  );
};
