import { ReactNode } from "react";

import { Menu, MenuItem, menuItemClasses } from "@mui/base";
import { Dropdown as BaseDropdown } from "@mui/base/Dropdown";
import { MenuButton as BaseMenuButton } from "@mui/base/MenuButton";
import CheckIcon from "@mui/icons-material/Check";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { isEqual } from "lodash-es";
import styled, { AnyStyledComponent, css } from "styled-components";

import {
  Spacings,
  colors,
  devices,
  fontFamily,
  makeTransition,
  shadows,
} from "../constants";
import { Button, ButtonProps } from "./Button";

type DropdownItem<ValueType> = {
  label: string;
  value: Extract<ValueType, string | number | string[]>;
  icon?: ReactNode;
};

type DropdownProps<ValueType> = {
  label?: JSX.Element | string;
  itemHeader?: string;
  itemHeaderIcon?: JSX.Element;
  items: Array<DropdownItem<ValueType>>;
  value: ValueType;
  onItemClick: (itemValue: ValueType) => void;
  fullWidth?: boolean;
  variant?: "outlined";
  spacing?: Spacings;
};

export function Dropdown<V>({
  label,
  items,
  itemHeader,
  itemHeaderIcon,
  value: valueProp,
  onItemClick,
  fullWidth,
  variant,
  spacing,
}: DropdownProps<V>) {
  const createHandleMenuClick = (itemValue: V) => () => {
    onItemClick(itemValue);
  };

  return (
    <div>
      <BaseDropdown>
        <BaseMenuButton
          slots={{ root: StyledButton }}
          slotProps={{
            root: {
              type: "button",
              color: "white",
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              icon: <KeyboardArrowDownIcon />,
              iconPosition: "after",
              fullWidth: fullWidth,
              variant: variant,
              spacing: spacing,
            },
          }}
        >
          <LabelContainer>
            {label && <Label>{label}</Label>}
            <ItemLabel>
              {items.find((i) => isEqual(i.value, valueProp))?.label || ""}
            </ItemLabel>
          </LabelContainer>
        </BaseMenuButton>

        <Menu slots={{ root: MenuRoot, listbox: StyledListbox }}>
          {itemHeader && (
            <StyledMenuItem disabled>
              {itemHeaderIcon}
              <StyledMenuItemHeader>{itemHeader}</StyledMenuItemHeader>
            </StyledMenuItem>
          )}
          {items.map(({ label, value, icon }) => (
            <StyledMenuItem onClick={createHandleMenuClick(value)} key={label}>
              {icon && <IconContainer>{icon}</IconContainer>}
              <StyledMenuItemText active={isEqual(value, valueProp)}>
                {label}
              </StyledMenuItemText>
              {isEqual(value, valueProp) && (
                <CheckIcon sx={{ color: colors.primary }} />
              )}
            </StyledMenuItem>
          ))}
        </Menu>
      </BaseDropdown>
    </div>
  );
}

const LabelContainer = styled.span`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  color: inherit;
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
`;

const Label = styled.span`
  color: ${colors.gray};
  font-family: inherit;
  font-size: 0.875rem;
  font-weight: inherit;
  margin-bottom: 4px;

  @media ${devices.mobileDown} {
    margin-bottom: 0px;
  }
`;

const ItemLabel = styled.span`
  color: inherit;
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
`;

const StyledListbox = styled.ul`
  font-family: ${fontFamily};
  font-size: 0.875rem;
  box-sizing: border-box;
  margin: 12px 0;
  min-width: 360px;
  max-width: 360px;
  box-shadow: ${shadows.normal};
  border-radius: 10px;
  overflow: auto;
  outline: 0px;
  background: ${colors.white};
  max-height: 80vh;
  overflow-y: auto;
`;

type StyledMenuItemTextProps = {
  active?: boolean;
};

const StyledMenuItemText = styled.span<StyledMenuItemTextProps>`
  flex-grow: 1;
  ${(props) =>
    props.active &&
    css`
      font-weight: 700;
    `}
`;

const IconContainer = styled.div`
  width: 32px;
  height: 32px;
`;

const StyledMenuItem = styled(MenuItem)`
  list-style: none;
  padding: 24px;
  cursor: pointer;
  user-select: none;
  border-bottom: 1px solid ${colors.borderGray};
  color: ${colors.grayDark};
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 16px;
  gap: 16px;
  height: 76px;

  &:last-of-type {
    border-bottom: none;
  }

  &.${menuItemClasses.focusVisible} {
    background-color: ${colors.grayLight};
  }

  &.${menuItemClasses.disabled} {
    cursor: default;
  }

  &:hover:not(.${menuItemClasses.disabled}) {
    background-color: ${colors.grayLight};
  }
`;

const StyledMenuItemHeader = styled(StyledMenuItemText)`
  font-weight: 700;
`;

const MenuRoot = styled.div`
  z-index: 1;
`;

type StyledButtonProps = ButtonProps & {
  iconRotate: boolean;
  fullWidth?: boolean;
};

const StyledButton = styled(Button as AnyStyledComponent)<StyledButtonProps>`
  ${({ fullWidth }) =>
    fullWidth &&
    css`
      width: 100%;
      justify-content: space-between;
    `}

  & > svg {
    ${makeTransition("rotate")}

    ${(props) =>
      props.iconRotate &&
      css`
        rotate: -180deg;
      `}
  }
`;
