import { UseQueryOptions, useQuery } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";

import type { ApiError, Enum, EnumEntry } from "@vapaus/generated";
import { ConstantsService } from "@vapaus/generated";

type Enums = {
  [key: string]: EnumEntry[];
};

export const useEnum = (
  options?: Omit<
    UseQueryOptions<Enum[], ApiError, Enums>,
    "queryKey" | "queryFn"
  >,
) => {
  const { i18n } = useTranslation();
  return useQuery<Enum[], ApiError, Enums>(
    ["enum", i18n.language],
    ConstantsService.getEnums,
    {
      staleTime: 60 * 60 * 24, // 24 hours
      select: (enums) =>
        enums.reduce((acc: Enums, v) => {
          acc[v.identifier] = v.entries;
          return acc;
        }, {}),
      ...options,
    },
  );
};

export const useEnumEntries = (enumIdentifier: string) => {
  const { data } = useEnum();
  const entries = data?.[enumIdentifier] ?? [];
  const getEnumLabel = (value?: string) =>
    entries.find((e) => e.value === value)?.label ?? value; // If the label is not found, return the value as fallback
  return { getEnumLabel, entries };
};
