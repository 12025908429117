/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum SaleInvoiceStatus {
  OPEN = "open",
  PAID = "paid",
  DUE = "due",
  OVERDUE = "overdue",
}
