import { FC } from "react";

import { useGetVehicle } from "../../../hooks/vehicle";

type Props = {
  vehicleId: string;
};

const VehicleName: FC<Props> = ({ vehicleId }) => {
  const { data } = useGetVehicle(vehicleId);
  return <>{data?.name || "-"}</>;
};

export default VehicleName;
