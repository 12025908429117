import { useState } from "react";

import { useQueries } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import invariant from "tiny-invariant";

import {
  BenefitDefinitionTermsRead,
  BenefitDefinitionsService,
  BikeBenefitContractRead,
  BikeBenefitOrderRead,
  BikeBenefitOrderState,
} from "@vapaus/generated";

import { Dialog } from "../../packages/ui";
import { Button, Markdown, colors } from "../../packages/uikit";
import {
  useAcceptBenefitTerms,
  useBenefitOrders,
  useSearchBenefitContracts,
} from "../hooks/benefit";

export const BenefitTermsChecker = () => {
  const { data: orders } = useBenefitOrders();
  const { data: contracts } = useSearchBenefitContracts();
  const { t } = useTranslation();
  const [updatedTerms, setUpdatedTerms] = useState<
    BenefitDefinitionTermsRead | undefined
  >();
  const [contractOrOrder, setContractOrOrder] = useState<
    BikeBenefitOrderRead | BikeBenefitContractRead | undefined
  >();

  const acceptedOrders =
    orders?.items.filter((order) =>
      [
        BikeBenefitOrderState.READY_FOR_DELIVERY,
        BikeBenefitOrderState.WAITING_FOR_PAYMENT,
      ].includes(order.state),
    ) || [];
  const activeContracts =
    contracts?.items.filter((contract) => contract.state === "active") || [];
  const contractsAndOrders = [...activeContracts, ...acceptedOrders];
  const acceptTerms = useAcceptBenefitTerms();

  useQueries({
    queries: contractsAndOrders.map((contractOrOrder) => ({
      queryKey: [
        "benefitDefinitionTerms",
        contractOrOrder.benefit_definition_id,
      ],
      queryFn: () =>
        BenefitDefinitionsService.getBenefitDefinitionCurrentTerms(
          contractOrOrder.benefit_definition_id,
        ),
      onSuccess(terms: BenefitDefinitionTermsRead) {
        if (
          contractOrOrder?.benefit_definition_terms_id !== terms.id &&
          !updatedTerms
        ) {
          setUpdatedTerms(terms);
          setContractOrOrder(contractOrOrder);
        }
      },
    })),
  });

  const handleAcceptTerms = () => {
    invariant(updatedTerms);
    invariant(contractOrOrder);
    acceptTerms.mutate({
      benefitDefinitionTermsId: updatedTerms.id,
      orderOrContractId: contractOrOrder.id,
    });
  };

  const handleClose = () => {
    setUpdatedTerms(undefined);
    setContractOrOrder(undefined);
  };

  return (
    <>
      {updatedTerms && (
        <Dialog
          type="confirm"
          title={t("fleet:updatedBenefitTermsAndCondition.title")}
          subTitle={t("fleet:updatedBenefitTermsAndCondition.subTitle")}
          description={
            updatedTerms?.content ? (
              <Markdown content={updatedTerms.content} />
            ) : updatedTerms.pdf_object?.public_url ? (
              <DownloadBox>
                <Button
                  icon="download"
                  label={t("common:downloadTermsAndConditions")}
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    window.open(
                      updatedTerms.pdf_object?.public_url,
                      "_blank",
                      "noopener,noreferrer",
                    );
                  }}
                  variant="outlined"
                />
              </DownloadBox>
            ) : undefined
          }
          onClose={handleClose}
          onConfirm={handleAcceptTerms}
          confirm={t("fleet:updatedBenefitTermsAndCondition.accept")}
          align="justify"
          noCancel
        />
      )}
    </>
  );
};

const DownloadBox = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  width: 100%;
  justify-content: center;
  padding: 50px;
  border-radius: 5px;
  background-color: ${colors.backgroundSecondary};
`;
