import { useState } from "react";

import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { parseApiError } from "@vapaus/utils";

import { Dialog } from "../../ui";
import { TextInput } from "../../uikit";
import { useRecoverPassword } from "../hooks/auth";
import {
  AuthLayout,
  AuthLayoutButton,
  AuthLayoutFooterLink,
} from "./AuthLayout";

type ForgotPasswordPageProps = {
  allowSignUp?: boolean;
};

export const ForgotPasswordPage = ({
  allowSignUp,
}: ForgotPasswordPageProps) => {
  const [error, setError] = useState<null | string>(null);
  const [success, setSuccess] = useState<boolean>(false);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const recoverPassword = useRecoverPassword();

  const {
    register,
    handleSubmit,
    formState: { isValid },
  } = useForm();

  const handleForgotPassword = async (data: any) => {
    try {
      await recoverPassword.mutateAsync(data.email);
      setSuccess(true);
    } catch (error: any) {
      setError(parseApiError(error, t));
    }
  };

  return (
    <AuthLayout
      title={t("auth:forgotPassword.title")}
      description={t("auth:forgotPassword.text")}
    >
      {error && (
        <Dialog
          type="alert"
          title={t("common:dialog.error")}
          subTitle={t("auth:forgotPassword.error")}
          description={error}
          animation="error"
          onClose={() => setError(null)}
        />
      )}
      {success && (
        <Dialog
          type="alert"
          title={t("common:dialog.done")}
          subTitle={t("auth:forgotPassword.title")}
          description={t("auth:forgotPassword.success")}
          animation="success"
          onClose={() => navigate("/sign-in")}
        />
      )}
      <form onSubmit={handleSubmit(handleForgotPassword)}>
        <TextInput
          autoFocus
          required
          type="email"
          label={t("auth:forgotPassword.email")}
          {...register("email", { required: true })}
        />
        <AuthLayoutButton
          disabled={!isValid}
          type="submit"
          icon="mail"
          isLoading={recoverPassword.isLoading}
        >
          {t("auth:forgotPassword.submit")}
        </AuthLayoutButton>
      </form>
      <AuthLayoutFooterLink to="/sign-in">
        {t("auth:link.signIn")}
      </AuthLayoutFooterLink>
      {allowSignUp && (
        <AuthLayoutFooterLink to="/sign-up">
          {t("auth:link.signUp")}
        </AuthLayoutFooterLink>
      )}
    </AuthLayout>
  );
};
