import { FC } from "react";

import { Route, Routes } from "react-router-dom";
import { ThemeProvider } from "styled-components";

import { useCurrentUser } from "../../../packages/user-utils";
import PageLayout from "../../components/PageLayout";
import theme from "../../utils/theme";
import Bookings from "../Bookings";
import BookingPage from "../Bookings/BookingPage";
import RideBooking from "../RideBooking";
import FleetList from "../RideBooking/FleetList";
import SettingsPage from "../SettingsPage";
import SupportPage from "../SupportPage";

const LoggedIn: FC = () => {
  const { data: user } = useCurrentUser();

  if (!user) return null;

  return (
    <ThemeProvider theme={theme}>
      {user.is_anonymous ? (
        <Routes>
          <Route path="*" element={<RideBooking />} />
          <Route path="/my-bookings" element={<Bookings />} />
          <Route path="/my-bookings/:bookingId" element={<BookingPage />} />
          <Route path="/book-a-ride" element={<RideBooking />} />
          <Route path="/book-a-ride/:fleetId" element={<FleetList />} />
        </Routes>
      ) : (
        <PageLayout pageTitle="Vapaus">
          <Routes>
            <Route path="*" element={<Bookings />} />
            <Route path="/my-bookings" element={<Bookings />} />
            <Route path="/my-bookings/:bookingId" element={<BookingPage />} />
            <Route path="/book-a-ride" element={<RideBooking />} />
            <Route path="/book-a-ride/:fleetId" element={<FleetList />} />
            <Route path="/settings" element={<SettingsPage />} />
            <Route path="/support" element={<SupportPage />} />
          </Routes>
        </PageLayout>
      )}
    </ThemeProvider>
  );
};

export default LoggedIn;
