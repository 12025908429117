import { FC } from "react";

import { useTranslation } from "react-i18next";

import { useCurrentUser } from "../../../../user-utils";
import VerificationCard from "../../VerificationCard";
import { DetailType } from "../../types";

const SSNDetail: FC = () => {
  const { t } = useTranslation();

  const { data: user } = useCurrentUser();
  if (!user?.ssn) return null;

  return (
    <VerificationCard
      type={DetailType.SSN}
      description={t("settings:verifyIdentity.verified")}
      isVerified={true}
    />
  );
};

export default SSNDetail;
