export const white = "#fff";
export const backgroundColor = "#ecf0f2";
export const black = "#333";
export const primaryColor = "#76777a";
export const primaryColor80 = "#919295";
export const primaryColor60 = "#adadaf";
export const primaryColor20 = "#e4e4e4";
export const secondaryColor = "#f9696b";
export const secondaryColor80 = "#fa8789";
export const secondaryColor60 = "#fba5a6";
export const secondaryColor20 = "#fee1e1";
export const tertiaryColor = "#7cb342";
export const tertiaryColor80 = "#a1bf6d";
export const tertiaryColor60 = "#b5cd8d";
export const tertiaryColor20 = "#dee6d2";
export const primaryColorRGB = "rgba(118, 119, 122, 1)";
export const primaryColor80RGB = "rgba(118, 119, 122, 0.8)";
export const primaryColor60RGB = "rgba(118, 119, 122, 0.6)";
export const primaryColor20RGB = "rgba(118, 119, 122, 0.2)";
export const secondaryColorRGB = "rgba(249, 105, 107, 1)";
export const secondaryColor80RGB = "rgba(249, 105, 107, 0.8)";
export const secondaryColor60RGB = "rgba(249, 105, 107, 0.6)";
export const secondaryColor20RGB = "rgba(249, 105, 107, 0.2)";
export const tertiaryColorRGB = "rgba(124, 179, 66, 1)";
export const tertiaryColor80RGB = "rgba(124, 179, 66, 0.8)";
export const tertiaryColor60RGB = "rgba(124, 179, 66, 0.6)";
export const tertiaryColor20RGB = "rgba(124, 179, 66, 0.2)";
export const faderColor = "rgba(0, 0, 0, 0.35)";
export const panelPromptClickColor = "rgba(0, 0, 0, 0.025)";
export const success = "#7cb342";
export const danger = "#f9696b";
