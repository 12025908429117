import { OpenAPI } from "@vapaus/generated";

const getFileWithName = async (res: Response): Promise<File> => {
  const contentDispositionHeader = res.headers.get("Content-Disposition") || "";
  const matches = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/.exec(
    contentDispositionHeader,
  );
  const filename = matches?.[1]?.replace?.(/['"]/g, "") as string;
  const blob = await res.blob();
  const file = new File([blob], filename, { type: "application/pdf" });
  return file;
};

export const downloadUserTermRequest = async (id: string): Promise<Blob> => {
  const res = await fetch(`${OpenAPI.BASE}/api/user-terms/${id}/download`, {
    method: "GET",
    headers: {
      "Content-Type": "application/pdf",
      Authorization: `Bearer ${OpenAPI.TOKEN}`,
    },
  });
  return await getFileWithName(res);
};

export const downloadShopTermRequest = async (id: string): Promise<Blob> => {
  const res = await fetch(
    `${OpenAPI.BASE}/shop-api/shop-terms/${id}/download`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/pdf",
        Authorization: `Bearer ${OpenAPI.TOKEN}`,
      },
    },
  );
  return await getFileWithName(res);
};

export const downloadProviderTermRequest = async (
  id: string,
): Promise<Blob> => {
  const res = await fetch(
    `${OpenAPI.BASE}/provider-api/provider-terms/${id}/download`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/pdf",
        Authorization: `Bearer ${OpenAPI.TOKEN}`,
      },
    },
  );
  return await getFileWithName(res);
};
