import { FC } from "react";

import { useTranslation } from "react-i18next";

import {
  PaymentConsentStatus,
  PaymentServiceProvider,
} from "@vapaus/generated";
import { CountryCurrencies, parseApiError } from "@vapaus/utils";

import { Button } from "../../../../ui";
import { useToast } from "../../../../uikit";
import {
  useCreatePaymentConsent,
  useCurrentUser,
  usePaymentConsentList,
} from "../../../../user-utils";

export const AddPaymentMethod: FC = () => {
  const { t } = useTranslation();
  const { data: user } = useCurrentUser();
  const createPaymentConsent = useCreatePaymentConsent();
  const {
    isSuccess,
    refetch,
    data: consents,
  } = usePaymentConsentList({
    status: [
      PaymentConsentStatus.CONFIRMED,
      PaymentConsentStatus.PENDING,
      PaymentConsentStatus.FAILED,
    ],
  });
  const toast = useToast();

  const redirectToPaymentServiceProvider = () => {
    if (!user) return;
    if (!user.country) {
      toast.error(t("settings:buttons.paymentConsent.useCountryNotSelected"));
      return;
    }
    createPaymentConsent.mutate(
      {
        payment_service_provider: PaymentServiceProvider.VISMA_PAY,
        currency: CountryCurrencies[user.country],
        return_url: window.location.origin + window.location.pathname,
      },
      {
        onSuccess: async ({ link }) => {
          await refetch();
          if (!link) return;
          window.location.replace(link);
        },
        onError: (error: any) => toast.error(parseApiError(error, t)),
      },
    );
  };

  if (!isSuccess || consents.total > 1) return null;

  return (
    <Button
      secondary
      text={t("settings:buttons.paymentConsent.addPaymentCard")}
      icon="payment"
      onClick={redirectToPaymentServiceProvider}
      disabled={createPaymentConsent.isLoading}
    />
  );
};
