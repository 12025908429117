import { FC } from "react";

import { DataGrid, GridColDef, GridPaginationModel } from "@mui/x-data-grid";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import styled from "styled-components";

import { BookingRead, OrderDirection } from "@vapaus/generated";
import { useDateFormat } from "@vapaus/i18n";

import { Card } from "../../../packages/ui";
import { useSearchBookings } from "../../hooks/booking";
import BookingStatus from "./components/BookingStatus";
import LocationName from "./components/LocationName";
import ProviderName from "./components/ProviderName";
import VehicleName from "./components/VehicleName";

const ROW_PER_PAGE_OPTIONS = [10];

const Bookings: FC = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const formatDate = useDateFormat({
    year: "numeric",
    month: "numeric",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
  });

  const [searchParams, setSearchParams] = useSearchParams({ page: "0" });

  const page = parseInt(searchParams.get("page") || "0");
  const limit = ROW_PER_PAGE_OPTIONS[0];

  const { data, isLoading } = useSearchBookings({
    limit,
    skip: page * limit,
    sortField: "start_at",
    sortDirection: OrderDirection.DESC,
  });

  const columns: GridColDef<BookingRead>[] = [
    {
      headerName: t("fleet:booking.pickupTime"),
      field: "start_at",
      flex: 1,
      sortable: false,
      valueGetter: ({ value }) => formatDate(value),
      minWidth: 100,
    },
    {
      headerName: t("fleet:booking.returnTime"),
      field: "end_at",
      flex: 1,
      sortable: false,
      valueGetter: ({ value }) => formatDate(value),
      minWidth: 100,
    },
    {
      headerName: t("fleet:booking.pickupLocation"),
      field: "pickup_location_id",
      flex: 1,
      sortable: false,
      renderCell: ({ value, row }) => (
        <LocationName vehicleId={row.vehicle_id} locationId={value} />
      ),
      minWidth: 150,
    },
    {
      headerName: t("fleet:booking.returnLocation"),
      field: "return_location_id",
      flex: 1,
      sortable: false,
      renderCell: ({ value, row }) => (
        <LocationName vehicleId={row.vehicle_id} locationId={value} />
      ),
      minWidth: 150,
    },
    {
      headerName: t("fleet:booking.provider"),
      field: "state",
      flex: 1,
      sortable: false,
      renderCell: ({ row }) => <ProviderName vehicleId={row.vehicle_id} />,
      minWidth: 150,
    },
    {
      headerName: t("fleet:booking.vehicle"),
      field: "vehicle_id",
      flex: 1,
      sortable: false,
      renderCell: ({ value }) => <VehicleName vehicleId={value} />,
      minWidth: 150,
    },
    {
      headerName: t("fleet:booking.status"),
      field: "status",
      flex: 1,
      sortable: false,
      renderCell: ({ row }) => <BookingStatus booking={row} />,
      minWidth: 100,
    },
  ];

  const handlePaginationModelChange = ({ page }: GridPaginationModel) => {
    setSearchParams({ page: page.toString() });
  };

  return (
    <Card title={t("fleet:booking.bookingHistory")}>
      <Wrapper>
        <DataGrid
          columns={columns}
          rows={data?.items || []}
          rowCount={data?.total || 0}
          loading={isLoading}
          pagination
          paginationModel={{ page, pageSize: limit }}
          pageSizeOptions={[10, 20, 50]}
          paginationMode="server"
          onPaginationModelChange={handlePaginationModelChange}
          disableColumnMenu
          disableRowSelectionOnClick
          autoHeight
          onRowClick={({ row }) => navigate(`/my-bookings/${row.id}`)}
        />
      </Wrapper>
    </Card>
  );
};

export default Bookings;

// This css override was needed to fix the issue with
// modal not showing up when clicking on the cancel icon
// because the icon is inside the virtual scroller of the grid
const Wrapper = styled.div`
  .MuiDataGrid-virtualScrollerRenderZone {
    transform: none !important;
  }
`;
