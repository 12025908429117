import { FC } from "react";

import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { Card, Loading, Text, bunnyImg } from "../../../../packages/ui";
import { useGetVehicle } from "../../../hooks/vehicle";

type Props = {
  vehicleId: string;
};

const VehicleInfo: FC<Props> = ({ vehicleId }) => {
  const { t } = useTranslation();
  const { data, isLoading } = useGetVehicle(vehicleId);

  const vehicle = data?.[data?.vehicle_type];

  if (isLoading) {
    return <Loading />;
  }

  return (
    <Wrapper>
      <img src={vehicle?.picture_url || bunnyImg} alt="picture" />
      <Text className="title" text={data?.name || ""} />
      <Text
        className="sub-title"
        text={vehicle ? `${vehicle.manufacturer}, ${vehicle.model}` : ""}
      />
    </Wrapper>
  );
};

export default VehicleInfo;

const Wrapper = styled(Card)`
  flex: 1;
  .CardContent {
    img {
      width: 100%;
      height: 280px;
      border-radius: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 10px;
      object-fit: scale-down;
      border: 1px solid #e0e0e042;
    }

    width: 100%;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center !important;

    .title {
      font-size: 16px;
      font-weight: bold;
      margin: 10px 0;
      text-align: center;
    }

    .sub-title {
      font-size: 14px;
      font-weight: 400;
      margin-bottom: 10px;
      text-align: center;
    }
  }
`;
