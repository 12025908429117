import React from "react";

import { Text } from "../Text";
import "./Card.scss";

type CardProps = {
  title?: string;
  subTitle?: string;
  className?: string;
  margin?: "normal";
  description?: React.ReactNode;
  children?: React.ReactNode;
  footer?: React.ReactNode;
  onClick?: React.MouseEventHandler<HTMLElement>;
};

export const Card = ({
  title,
  subTitle,
  className,
  margin,
  description,
  children,
  footer,
  onClick,
}: CardProps) => {
  let _className = "Card";
  if (className) {
    _className += " " + className;
  }

  if (margin) {
    _className += ` margin-${margin}`;
  }

  const _content = (
    <div className="CardContent">
      {description}
      {children}
    </div>
  );
  const _footer = footer && <div className="CardFooter">{footer}</div>;

  const _titles = (title || subTitle) && (
    <div className="titles">
      {title && <Text type="title" className="CardTitle" text={title} />}
      {subTitle && (
        <Text type="subTitle" className="CardSubTitle" text={subTitle} />
      )}
    </div>
  );

  return (
    <div className={_className} onClick={onClick}>
      {_titles}
      {_content}
      {_footer}
    </div>
  );
};
