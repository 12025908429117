import styled from "styled-components";

import { Loading } from ".";

export const PageLoading = () => {
  return (
    <Wrapper className="LoggedIn">
      <Loading />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
`;
