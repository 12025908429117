import {
  ComponentType,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";

import { useQueryClient } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import {
  Route,
  Routes,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";

import { OpenAPI } from "@vapaus/generated";

import { Content, PageLoading } from "../../../ui";
import {
  useCurrentUser,
  useGetUserActiveOrCurrentEmail,
} from "../../../user-utils";
import { AvailableScopes } from "../../constants";
import { AuthContext } from "../../context/authContext";
import { ForgotPasswordPage } from "../ForgotPasswordPage";
import { ResetPasswordPage } from "../ResetPasswordPage";
import { SetPasswordPage } from "../SetPasswordPage";
import { SignInPage } from "../SignInPage";
import { SignUpPage } from "../SignUpPage";
import TermsOfServicePage from "../TermsOfServicePage";
import VerifyEmailNoticePage from "../VerifyEmailNoticePage";
import VerifyEmailPage from "../VerifyEmailPage";
import "./Auth.scss";

type AuthProps = {
  children: ReactNode;
  termsPage: ComponentType;
  acceptedTermsKey:
    | "accepted_user_terms_id"
    | "accepted_shop_terms_id"
    | "accepted_provider_terms_id";
  currentTermsKey:
    | "current_user_terms_id"
    | "current_shop_terms_id"
    | "current_provider_terms_id";
  allowSignUp?: boolean;
  scope?: AvailableScopes;
};

export const allowedAnonymousRoutes = [
  "/forgot-password",
  "/sign-up",
  "/sign-in",
  "/reset-password",
  "/set-password",
  "/terms-of-service",
];

const specialRoutes = [...allowedAnonymousRoutes, "/terms"];

const useApiTokenState = (): [
  string | null,
  (token: string | null) => void,
] => {
  const [searchParams] = useSearchParams();

  const queryClient = useQueryClient();

  const [token, setToken] = useState<string | null>(() => {
    const searchToken = searchParams.get("access_token");
    if (searchToken) localStorage.setItem("token", searchToken);
    const token = localStorage.getItem("token");
    OpenAPI.TOKEN = token || undefined;
    return token;
  });

  const _setToken = useCallback(
    (token: string | null) => {
      setToken(token);
      if (token) {
        localStorage.setItem("token", token);
      } else {
        localStorage.removeItem("token");
        queryClient.clear();
      }
      OpenAPI.TOKEN = token || undefined;
    },
    [setToken, queryClient],
  );

  return [token, _setToken];
};

export const Auth = ({
  children,
  termsPage: TermsPage,
  acceptedTermsKey,
  currentTermsKey,
  allowSignUp,
  scope,
}: AuthProps) => {
  const [token, setToken] = useApiTokenState();
  const navigate = useNavigate();
  const location = useLocation();
  const { i18n } = useTranslation();

  const {
    data: user,
    error,
    isLoading,
  } = useCurrentUser({
    enabled: !!token,
    retry: false,
    refetchOnWindowFocus: false,
  });

  // if there is an error when we try to get the current user, se remove the token to log out.
  useEffect(() => {
    if (error) setToken(null);
  }, [error, setToken]);

  const { email: primaryEmail, isLoading: isLoadingActiveOrPrimaryEmail } =
    useGetUserActiveOrCurrentEmail({
      enabled: !!token,
    });

  const currentTerms = user?.[currentTermsKey];
  const acceptedTerms = user?.[acceptedTermsKey];

  const shouldNavigateToSignIn =
    !token && !allowedAnonymousRoutes.includes(location.pathname);

  const shouldNavigateToEmailNotVerified =
    token &&
    primaryEmail &&
    !primaryEmail.email_verified &&
    location.pathname !== "/verify-email" &&
    location.pathname !== "/email-not-verified";

  const shouldNavigateToTerms =
    token &&
    currentTerms &&
    currentTerms !== acceptedTerms &&
    location.pathname !== "/verify-email" &&
    location.pathname !== "/email-not-verified";

  const shouldNavigateToRoot =
    token &&
    currentTerms &&
    currentTerms === acceptedTerms &&
    specialRoutes.includes(location.pathname);

  useEffect(() => {
    if (user?.language) i18n.changeLanguage(user.language.toLowerCase());
  }, [user?.language, i18n]);

  useEffect(() => {
    if (shouldNavigateToSignIn) {
      navigate("/sign-in");
    } else if (shouldNavigateToEmailNotVerified) {
      navigate("/email-not-verified");
    } else if (shouldNavigateToTerms) {
      navigate("/terms");
    } else if (shouldNavigateToRoot) {
      // TODO: handle the case where a user is redirected to the sign-in or the terms route, we should then redirect to their
      // previous route
      navigate("/");
    }
  }, [
    navigate,
    shouldNavigateToSignIn,
    shouldNavigateToEmailNotVerified,
    shouldNavigateToTerms,
    shouldNavigateToRoot,
  ]);

  const authContext = useMemo(
    () => ({ token, setToken, isLoggedIn: Boolean(user && token) }),
    [token, setToken, user],
  );

  if (!!token && (isLoading || isLoadingActiveOrPrimaryEmail))
    return <PageLoading />;

  const isAuthPage = allowedAnonymousRoutes.includes(location.pathname);

  return (
    <AuthContext.Provider value={authContext}>
      <Content className={`${isAuthPage ? "Auth" : ""} on`}>
        <Routes>
          <Route
            path="/forgot-password"
            element={<ForgotPasswordPage allowSignUp={allowSignUp} />}
          />
          <Route path="/reset-password" element={<ResetPasswordPage />} />
          <Route path="/set-password" element={<SetPasswordPage />} />
          {allowSignUp && <Route path="/sign-up" element={<SignUpPage />} />}
          <Route
            path="/sign-in"
            element={<SignInPage allowSignUp={allowSignUp} scope={scope} />}
          />
          <Route
            path="/email-not-verified"
            element={<VerifyEmailNoticePage />}
          />
          <Route path="/terms" element={<TermsPage />} />
          <Route path="/terms-of-service" element={<TermsOfServicePage />} />
          <Route path="/verify-email" element={<VerifyEmailPage />} />
          {children}
        </Routes>
      </Content>
    </AuthContext.Provider>
  );
};
