import React, { FC } from "react";

import { useGetFleet } from "../../../hooks/fleet";
import { useGetVehicle } from "../../../hooks/vehicle";

type Props = {
  vehicleId: string;
};

const ProviderName: FC<Props> = ({ vehicleId }) => {
  const { data: vehicleData } = useGetVehicle(vehicleId);
  const { data } = useGetFleet(vehicleData?.fleet_id || "", {
    enabled: !!vehicleData?.fleet_id,
  });
  return <>{data?.organisation?.name || "-"}</>;
};

export default ProviderName;
