import React from "react";
import { createPortal } from "react-dom";

const portalTo =
  <T extends object>(domId?: string) =>
  (Comp: React.ElementType) => {
    return class extends React.Component<T> {
      root: HTMLElement;
      container: HTMLElement;

      constructor(props: T) {
        super(props);

        this.root = document.body;
        this.container = document.createElement("div");
      }

      componentDidMount() {
        this.root =
          (domId ? document.getElementById(domId) : document.body) ||
          document.body;
        this.root.appendChild(this.container);
      }

      componentWillUnmount() {
        this.root.removeChild(this.container);
      }

      render() {
        return createPortal(<Comp {...this.props} />, this.container);
      }
    };
  };

type PortalProps = {
  target?: string;
  children: JSX.Element;
};

class Portal extends React.Component<PortalProps> {
  root: HTMLElement;
  container: HTMLElement;

  constructor(props: PortalProps) {
    super(props);

    this.root = document.body;
    this.container = document.createElement("div");
  }

  componentDidMount() {
    this.root =
      (this.props.target
        ? document.getElementById(this.props.target)
        : document.body) || document.body;
    this.root.appendChild(this.container);
  }

  componentWillUnmount() {
    this.root.removeChild(this.container);
  }

  render() {
    return createPortal(this.props.children, this.container);
  }
}

export { Portal, portalTo };
