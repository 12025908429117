import styled from "styled-components";

const Spaces = {
  s: "8px",
  md: "16px",
  lg: "24px",
  xl: "32px",
};

type Props = {
  height?: keyof typeof Spaces;
};

export const Spacing = styled.div<Props>`
  height: ${({ height }) => (height ? Spaces[height] : Spaces.xl)};
`;
