import React from "react";

import "./Modal.scss";

type ModalProps = {
  children: React.ReactNode;
};

export const Modal = ({ children }: ModalProps) => {
  return (
    <Modal.Fader>
      <div className="Modal">{children}</div>
    </Modal.Fader>
  );
};

type ModalFaderProps = {
  children: React.ReactNode;
};

Modal.Fader = ({ children }: ModalFaderProps) => {
  return <div className="ModalFader">{children}</div>;
};
