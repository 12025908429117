/* generated using openapi-typescript-codegen -- do no edit */

/* istanbul ignore file */

/* tslint:disable */

/* eslint-disable */
import type { CancelablePromise } from "../core/CancelablePromise";
import { OpenAPI } from "../core/OpenAPI";
import { request as __request } from "../core/request";
import type { Enum } from "../models/Enum";

export class ConstantsService {
  /**
   * Get Enums
   * @returns Enum Successful Response
   * @throws ApiError
   */
  public static getEnums(): CancelablePromise<Array<Enum>> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/api/enums",
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }
}
