import { FC, useRef } from "react";

import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { ButtonGroup, Text, media } from "../../../ui";
import { DetailType } from "../types";
import AddEmailAddress from "./AddButtons/AddEmailAddress";
import AddFleetAccessCode from "./AddButtons/AddFleetAccessCode";
import { AddPaymentMethod } from "./AddButtons/AddPaymentMethod";
import AddPhoneNumber from "./AddButtons/AddPhoneNumber";
import { AddSSN } from "./AddButtons/AddSSN";
import "./AddStripeCustomerId.scss";
import EmailListDetail from "./ItemCards/EmailListDetail";
import FleetAccessCodeDetail from "./ItemCards/FleetAccessCodeDetail";
import { PaymentConsentListDetail } from "./ItemCards/PaymentConsentListDetail";
import PhoneDetail from "./ItemCards/PhoneDetail";
import SSNDetail from "./ItemCards/SSNDetail";
import "./UserDetails.scss";

type Props = {
  details: DetailType[];
};

const UserDetails: FC<Props> = ({ details = [] }) => {
  const { t } = useTranslation();
  const showPhone = details.includes(DetailType.Phone);
  const showSsn = details.includes(DetailType.SSN);
  const showFleetAccessCode = details.includes(DetailType.FleetAccessCode);
  const showPaymentConsents = details.includes(DetailType.Payment);
  const ref = useRef<HTMLDivElement>(null);
  return (
    <div ref={ref}>
      <Wrapper>
        <ButtonGroup>
          <AddEmailAddress />
          {showPhone && <AddPhoneNumber />}
          {showSsn && <AddSSN />}
          {showPaymentConsents && <AddPaymentMethod />}
        </ButtonGroup>
      </Wrapper>
      <Wrapper>
        <GroupGrid>
          <EmailListDetail />
          {showPhone && <PhoneDetail />}
          {showSsn && <SSNDetail />}
          {showPaymentConsents && <PaymentConsentListDetail sectionRef={ref} />}
        </GroupGrid>
      </Wrapper>
      {showFleetAccessCode && (
        <Wrapper>
          <ButtonGroup margin="normal">
            <AddFleetAccessCode />
          </ButtonGroup>
          <Text
            type="subTitle"
            text={t("settings:fleetAccessCode.yourAccessCodes")}
            margin="normal"
          />
          <GroupGrid>
            <FleetAccessCodeDetail />
          </GroupGrid>
        </Wrapper>
      )}
    </div>
  );
};

export default UserDetails;

const Wrapper = styled.div`
  margin-top: 2rem;
  margin-bottom: 2rem;
`;

const GroupGrid = styled.div`
  display: grid;
  grid-column-gap: 1rem;
  grid-row-gap: 1rem;
  grid-template-columns: repeat(3, 1fr);

  ${media.atTablet} {
    grid-template-columns: repeat(2, 1fr);
  }

  ${media.atMobile} {
    grid-template-columns: repeat(1, 1fr);
  }
`;
