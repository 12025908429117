import { FC, useState } from "react";

import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { Button, Dialog, Icon, colors, media } from "../../../ui";
import { UserDetailItem } from "../types";

const VerificationCard: FC<UserDetailItem> = ({
  title,
  description,
  body,
  isVerified,
  verifyButtonLabel,
  verifyButtonDisabled,
  canDelete,
  onVerify,
  onDelete,
  onMarkAsPrimary,
}) => {
  const { t } = useTranslation();
  const [isDeleting, setIsDeleting] = useState(false);
  return (
    <>
      <StyledCard isVerified={isVerified}>
        {canDelete && (
          <RemoveBtn onClick={() => setIsDeleting(true)}>
            <Icon name="do_not_disturb_on" />
          </RemoveBtn>
        )}
        {title && <CardTitle>{title}</CardTitle>}
        {description && <CardSubTitle>{description}</CardSubTitle>}
        <CardBody>
          {body}
          {isVerified === undefined ? null : isVerified ? (
            (onMarkAsPrimary !== undefined && (
              <Button
                tertiary
                icon="check_circle"
                text={t("settings:verificationCard.markAsPrimary")}
                onClick={onMarkAsPrimary}
              />
            )) || <Icon className="verified-icon" name="verified_user" />
          ) : (
            <Button
              secondary
              icon="verified_user"
              text={
                verifyButtonLabel || t("settings:verificationCard.verifyNow")
              }
              onClick={onVerify}
              disabled={verifyButtonDisabled}
            />
          )}
        </CardBody>
      </StyledCard>
      {isDeleting && (
        <Dialog
          type="confirm"
          title={t("common:dialog.areYouSure")}
          subTitle={t("settings:verificationCard.remove.title")}
          description={t("settings:verificationCard.remove.description")}
          onConfirm={onDelete}
          onClose={() => setIsDeleting(false)}
          confirm={t("common:dialog.confirm")}
          cancel={t("common:dialog.cancel")}
        />
      )}
    </>
  );
};

export default VerificationCard;

export const CardTitle = styled.h2`
  font-size: 1em;
  font-weight: 400;
  margin: 0;
  padding: 0;
  font-weight: 500;
  text-align: left;
  font-family: "AvenirLTPro-Black", sans-serif;
  margin-bottom: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  margin-bottom: 0.25em;
  max-width: calc(100% - 1em);
  color: #76777a;
`;

export const CardSubTitle = styled.h4`
  font-size: 1em;
  font-weight: 400;
  margin: 0;
  padding: 0;
  font-weight: 500;
  text-align: left;
  font-family: "AvenirLTPro-Black", sans-serif;
  margin-bottom: 1em;
`;

const CardBody = styled.div``;

const StyledCard = styled.div<{ isVerified?: boolean }>`
  width: 100%;
  background-color: #fff;
  padding: 1.5rem;
  border-radius: 0.25rem;
  box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.1);
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  ${CardSubTitle}, i.verified-icon {
    color: ${({ isVerified }) =>
      isVerified === undefined
        ? null
        : isVerified
        ? colors.success
        : colors.danger};
  }
  & i.verified-icon {
    font-size: 3.25em;
  }
  .Button {
    float: left;
    ${media.atMobile} {
      width: 100%;
    }
  }
  position: relative;
`;

const RemoveBtn = styled.div`
  position: absolute;
  top: 1em;
  right: 1em;
  cursor: pointer;
  color: ${colors.primaryColor20};
  &:hover {
    color: ${colors.danger};
  }
`;
